import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled, { withTheme } from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexCol,
  FlexRow,
  IconImg,
  NoItemsWrapper,
  TextP,
  DsiFavoriteCategory as FavoriteCategory,
  DsiSvgIcon as SvgIcon,
  DsiInfoText as InfoText,
  DsiInfoType as InfoType,
  Picture,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { prop } from '../../utilities';
import SliderComponent from '../_helpers/Slider/Slider';
import { LoaderWrapper } from 'eshop-defaults/lib/components/Zemplin/General/LoaderWrapper';
import { Link } from 'react-router';
import { getImagePath } from '../../utilities/product';
import { FavoriteBrands } from './FavoriteBrands';
import { getProductDefaultInfo } from 'eshop-defaults/lib/utilities/selectors';
import { useState } from 'react';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { AddModal } from '../Product/Product';
import { AddTo } from '../Product/AddTo';
import { StyledOutsideClick } from '../Category/Category';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Loader } from '../_helpers/Loader/Loader';
import {
  loadBlogArticles,
  loadReviews,
  loadThemes,
} from '../../containers/Home/actions';

interface Props {
  dispatch: any;
  phone?: string;
  theme: any;
  categories: ThenArg<typeof API.loadCategories>;
  productCategoriesIsFetching: boolean;
  addToCart: any;
  addToRequest: any;
  productList1: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  productList2: {
    productsData: ThenArg<typeof API.loadProductList>;
    isFetching: false;
  };
  adBanner: any;
  brands: any;
  reviews: any;
  themes: any;
  user: any;
  articlesIsFetching: boolean;
  lang: string;
  blogArticles: any;
  title: string;
}

function Home(props: Props) {
  const [addToCartOpen, setAddToCartOpen] = useState(false);
  const [addedProduct, setAddedProduct] = useState({
    name: '',
    plu: '',
    picture: '',
    price: '',
    currency: '',
  });

  React.useEffect(() => {
    props.dispatch(loadBlogArticles());
  }, []);

  const closeModals = () => {
    setAddToCartOpen(false);
  };

  const changeAddToCartOpen = () => {
    setAddToCartOpen(!addToCartOpen);
  };

  const renderBanner = () => {
    if (!props.adBanner || !props.adBanner.length) {
      return null;
    }

    return (
      <BannerWrapper>
        <BannerContainer className={'container container--wide'}>
          <BannerSliderWrapper>
            <Slider slides={props.adBanner} type={'photogallery'} />
          </BannerSliderWrapper>
        </BannerContainer>
      </BannerWrapper>
    );
  };

  const renderProductList = productList => {
    const {
      addToCart,
      // addToRequest,
      categories,
      productCategoriesIsFetching,
    } = props;
    const isFetching = prop(productList, 'isFetching');

    const handleAddToCart = (product: any, count: number) => {
      const { name, plu, price, currency, picture } = getProductDefaultInfo(
        product,
      );
      setAddedProduct({
        name: name,
        plu: plu,
        price: price,
        currency: currency,
        picture: picture,
      });
      setAddToCartOpen(true);
      addToCart(product, count);
    };

    if (
      productCategoriesIsFetching ||
      (!productCategoriesIsFetching &&
        !isFetching &&
        !productList.productsData &&
        !categories)
    ) {
      return <LoaderWrapper height={270} />;
    }

    const productsData = prop(productList, 'productsData.1', {}) || {};
    const products: ThenArg<
      typeof API.searchProducts
    >['products'] = productsData ? prop(productsData, 'products', []) : [];
    if (productsData && !isFetching && products && products.length) {
      return (
        <ContentWrapper>
          <ProductTitleWrapper>
            <Title>{__(productsData.list_name)}</Title>
          </ProductTitleWrapper>
          <ProductCategoryWrapper>
            <ProductWrapper>
              {isFetching ? (
                <LoaderWrapper height={150} />
              ) : products && products.length > 0 ? (
                <Slider
                  type={'ebook'}
                  slides={products}
                  addToCart={handleAddToCart}
                />
              ) : null}
            </ProductWrapper>
            <MobileProductWrapper>
              <SliderComponent type="ebook" slides={products} />
            </MobileProductWrapper>
          </ProductCategoryWrapper>
        </ContentWrapper>
      );
    }
    return null;
  };

  const renderAddToCartModal = () => {
    return (
      addToCartOpen && (
        <>
          <Dimmer height={100} zIndex={99} />
          <StyledOutsideClick handleFunction={closeModals}>
            <AddModal>
              <AddTo
                type={'cart'}
                addToFavoriteOpen={changeAddToCartOpen}
                productName={
                  prop(addedProduct, 'publish.0.product_name') ||
                  addedProduct.name
                }
                picture={addedProduct.picture}
                price={addedProduct.price}
                currency={addedProduct.currency}
              />
            </AddModal>
          </StyledOutsideClick>
        </>
      )
    );
  };

  const renderCMSArticles = () => {
    if (props.articlesIsFetching) {
      return <LoaderWrapper height={200} />;
    }

    // if (props.lang === 'cz') {
    //   return null;
    // }

    const hasArticles =
      props.blogArticles &&
      props.blogArticles.articles &&
      props.blogArticles.articles.length > 0;

    return (
      <ContentWrapperFull>
        <ProductTitleWrapper>
          <Link to={'/blog'}>
            <Title>{__('Blog')}</Title>
          </Link>
        </ProductTitleWrapper>
        <StyledFlexRow>
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles.slice(0, 6).map(article => (
                <StyledLink to={`/${article.url}`}>
                  <CMSContent>
                    <CMSImage
                      src={
                        article.image
                          ? getImagePath(
                              article.image,
                              { width: 100, height: 100 },
                              false,
                              true,
                              true,
                            )
                          : '/images/icons/placeholder.svg'
                      }
                      alt={`${__('Náhľadový obrázok článku ')} ${article.name}`}
                    />
                    <CMSName>{article.name}</CMSName>
                  </CMSContent>
                </StyledLink>
              ))}
            </StyledFlexCol>
          )}
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles.slice(6, 12).map(article => (
                <StyledLink to={`/${article.url}`}>
                  <CMSContent>
                    <CMSImage
                      src={
                        article.image
                          ? getImagePath(
                              article.image,
                              { width: 100, height: 100 },
                              false,
                              true,
                              true,
                            )
                          : '/images/icons/placeholder.svg'
                      }
                      alt={`${__('Náhľadový obrázok článku ')} ${article.name}`}
                    />
                    <CMSName>{article.name}</CMSName>
                  </CMSContent>
                </StyledLink>
              ))}
            </StyledFlexCol>
          )}
          {hasArticles && (
            <StyledFlexCol>
              {props.blogArticles.articles.slice(12).map(article => (
                <StyledLink to={`/${article.url}`}>
                  <CMSContent>
                    <CMSImage
                      src={
                        article.image
                          ? getImagePath(
                              article.image,
                              { width: 100, height: 100 },
                              false,
                              true,
                              true,
                            )
                          : '/images/icons/placeholder.svg'
                      }
                      alt={`${__('Náhľadový obrázok článku ')} ${article.name}`}
                    />
                    <CMSName>{article.name}</CMSName>
                  </CMSContent>
                </StyledLink>
              ))}
            </StyledFlexCol>
          )}
        </StyledFlexRow>
      </ContentWrapperFull>
    );
  };

  const { user, brands, categories, productList1, productList2, title } = props;
  return (
    <MainWrapper>
      <MetaTags
        tags={{ title: title }}
        concatDefaultTitle={title ? false : true}
        hrefLangs={[
          { lang_id: 'sk', url: '' },
          { lang_id: 'cz', url: '' },
        ]}
      />
      {prop(user, 'b2b') ? null : renderBanner()}
      {/* {renderAddToCartModal()} */}
      <Wrapper className={'container container--wide'}>
        {user && (
          <InfoWrapper>
            <InfoText
              type={InfoType.SUCCESS}
              info={
                <>
                  <span>
                    {`${__('Ste prihlásený ako')} ${user.name ||
                      user.email}. ${__(
                      'Vaše objednávky, faktúry, ako aj ďalšie informácie nájdete v sekcii ',
                    )}
                    `}
                  </span>{' '}
                  <MyAccountLink
                    to={__r('routes:moj-ucet', '/moj-ucet')}
                  >{`${__('môj účet')}.`}</MyAccountLink>
                </>
              }
            />
          </InfoWrapper>
        )}
        <FavoriteCategory categories={categories} marginLeft={340} />
        <FavoriteBrands brands={brands} marginLeft={340} />
        {renderProductList(productList1)}
        {renderProductList(productList2)}
        {renderCMSArticles()}
      </Wrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-left: ${rem(340)};
  padding-top: ${rem(32)};

  ${({ theme }) => theme.mediab.l1150`
     padding-left: 0;
  `}
`;

const MyAccountLink = styled(Link)`
  color: white;
  text-decoration: underline;
  font-weight: 500;
`;

const ContentWrapper = styled.div`
  margin-left: ${rem(340)};
  margin-top: ${rem(28)};
  margin-bottom: ${rem(28)};

  ${({ theme }) => theme.mediab.l1150`
     margin-left: 0;
  `}
`;

const ContentWrapperFull = styled.div`
  margin-top: ${rem(28)};
  margin-bottom: ${rem(28)};

  ${({ theme }) => theme.mediab.l1150`
     margin-left: 0;
  `}
`;

const StyledFlexRow = styled(FlexRow)`
  ${({ theme }) => theme.mediab.m810`
     flex-direction: column;
  `}
`;

const StyledFlexCol = styled(FlexCol)`
  max-width: 33%;
  ${({ theme }) => theme.mediab.m810`
     max-width: 100%;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CMSContent = styled(FlexRow)`
  margin: ${rem(8)} ${rem(80)} ${rem(16)} 0;
  ${({ theme }) => theme.mediab.m580`
     margin-right: 0;
  `}
`;

const CMSImage = styled(IconImg)`
  width: ${rem(80)};
  height: ${rem(80)};
  margin-right: ${rem(16)};
  flex-shrink: 0;
  cursor: pointer;
`;

const CMSName = styled(TextP)`
  width: 100%;
  font-weight: 500;
  margin: auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(14)};
  `}
`;

const BannerWrapper = styled.div`
  width: 100%;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;

  @media only screen and (max-width: 600px) {
    background-color: transparent;
    padding: 0;
    /* height: ${rem(400)}; */
  }
`;

const BannerContainer = styled.div`
  width: 100%;
`;

const BannerSliderWrapper = styled.div`
  width: 100%;
  padding-left: ${rem(340)};

  ${({ theme }) => theme.mediab.l1150`
     padding-left: 0;
  `}

  @media only screen and (max-width: 600px) {
    margin-left: -${rem(16)};
    margin-right: -${rem(16)};
    width: calc(100% + 32px);
    overflow: hidden;
  }
`;

export const Slider = styled(SliderComponent)``;

const ProductWrapper = styled(FlexRowCenterVertical)`
  border-left: 0;
  border-radius: 0 ${rem(4)} ${rem(4)} 0;
  width: 100%;
  justify-content: center;

  ${({ theme }) => theme.mediab.l925`
   display: none;
  `}
`;

const MobileProductWrapper = styled.div`
  display: none;
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
   display: block;
  `}
`;

export const ProductTitleWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.l1050`
     margin-bottom: ${rem(32)};
  `}
`;

export const Title = styled(TextP)`
  text-decoration: none;
  font-size: ${rem(30)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  line-height: ${rem(38)};
  font-weight: 400;
  margin: 0;
`;

const TitleEmptyWithMargin = styled(Title)`
  height: ${rem(38)};
`;

const ProductCategoryWrapper = styled(FlexRow)`
  ${({ theme }) => theme.mediab.l1050`
     flex-flow: column;
     margin-right: -${rem(16)};
  `}
`;

export default withTheme(Home);
