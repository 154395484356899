import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiPurchase as Purchase,
  PaginationSimple,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { StyledTitle } from './MyDashboard';
import { prop } from '../../utilities';
import * as cookie from 'react-cookies';
import { DSI_VAT_VIEW_COOKIE } from '../../containers/App/constants';
import { LoadAllButton } from './MyInvoices';

interface Props {
  orders: ThenArg<typeof API.getOrders>;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  handleAddToCart: any;
  brands: any;
  categories: any;
  refetchData: any;
  handleOffsetChange: any;
  user: any;
}

function MyPurchase({
  currentId,
  orders,
  handleSelectChange,
  handleOffsetChange,
  options,
  isFetchingOrders,
  handleAddToCart,
  brands,
  categories,
  refetchData,
  user,
}: Props) {
  const [vatView, setVatView] = React.useState(
    cookie.load(DSI_VAT_VIEW_COOKIE) || 'withVat',
  );

  const cookieProductVatView = cookie.load(DSI_VAT_VIEW_COOKIE);
  React.useEffect(() => {
    if (cookieProductVatView) {
      setVatView(cookieProductVatView);
    }
  }, [cookieProductVatView]);

  const loadAllData = () => {
    refetchData({
      limit: prop(orders, 'pagination.total'),
      fromDate: prop(orders, 'activeFilters.filter_from'),
      toDate: prop(orders, 'activeFilters.filter_to'),
      userOuterId: prop(orders, 'activeFilters.user_outer_id'),
      categId: prop(orders, 'activeFilters.category_id'),
      brandId: prop(orders, 'activeFilters.brand_id'),
    });
  };

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Moje nákupy')} (${prop(orders, 'pagination.total') || 0})`}
      </StyledTitle>
      {options && options.length > 1 && (
        <>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <br />
        </>
      )}
      <Purchase
        user={user}
        isFetching={isFetchingOrders}
        orderedProducts={prop(orders, 'products', []).filter(a =>
          prop(a, 'product_detail'),
        )}
        handleAddToCart={handleAddToCart}
        brands={
          brands && prop(orders, 'brands')
            ? brands.filter(b => prop(orders, 'brands').includes(b.brand_id))
            : []
        }
        categories={
          categories && prop(orders, 'categories')
            ? categories.filter(c =>
                prop(orders, 'categories').includes(c.category_id),
              )
            : []
        }
        refetchData={refetchData}
        showWithVat={user && !user.b2b ? true : vatView === 'withVat'}
        isB2C={user && !user.b2b}
        isB2B={user && user.b2b}
      />
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <PaginationSimple
          totalItems={prop(orders, 'pagination.total')}
          limit={prop(orders, 'pagination.limit')}
          offset={prop(orders, 'pagination.offset')}
          handleOffsetChange={handleOffsetChange}
        />
      )}
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <LoadAllButton onClick={loadAllData}>
          {__('Zobraziť všetky')}
        </LoadAllButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyPurchase;
