import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  searchResultsSelector,
  searchResultsIsFetchingSelector,
  searchResultsArticlesSelector,
  searchResultsIsFetchingArticlesSelector,
  hostnameSelector,
  currencySelector,
  brandDetailSelector,
  brandCategoriesSelector,
} from '../App/selectors';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  loadBrandCategories,
  loadBrandDetail,
  loadSearchTermArticlesResults,
  loadSearchTermResults,
} from '../App/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';
import { SearchResults } from '../../components/Search/SearchResults';
import { addItemToCart, setAddToCartModalVisibility } from '../Cart/cartSlice';
import {
  extractRustPart,
  resolveBaseUrlByLang,
  resolveDomainByHostname,
} from '../../configureTrans';
import { prop } from '../../utilities';
import {
  categoryFilterDataSelector,
  urlAttribsToObject,
} from '../Category/categorySlice';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { defaultSortOption } from '../../components/Category/Category';
import { categoryEntitiesSelector } from '../Header/selectors';

interface Props {
  user: any;
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
      sort?: string;
      sortDir?: string;
    };
    pathname: string;
  };
  searchedProducts: any;
  searchIsFetching: boolean | true;
  dispatch: (action: any) => void;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  hostname: string;
  params: {
    brand_id: string;
    brand_name: string;
    brandUrl?: string;
  };
  brandParams: {
    brand_id: number;
    brand_name: string;
  };
  isBrandPage: boolean;
  brandName: string;
  currency: string;
  filterData: {
    minPrice: number;
    maxPrice: number;
    attribs: any;
  };
  brandDetail: ThenArg<typeof API.loadBrandDetail>;
  brandCategories: any;
  byId: any;
}

const Wrapper = styled(FlexCol)`
  width: 100%;
`;

const Header = styled(FlexCol)`
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class SearchResultsContainer extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string => {
    return props.location.query.q;
  };

  /*
  public static getBrandId = (props: Props):any => {
    if(props.brandParams && props.brandParams.brand_id){
      return props.brandParams.brand_id;
    }
    return props.params.brand_id;
  };

  public static getBrandName = (props: Props): string => {
    if(props.brandParams && props.brandParams.brand_name){
      return props.brandParams.brand_name;
    }
     
    return props.params.brand_name;
  };
  */

  public static async getInitialProps(props: Props) {
    try {
      const { dispatch, location, params } = props;
      const {
        query: { offset, sort, sortDir },
        query,
      } = location;

      const { brandUrl } = params;

      let brandId: any = null;
      let brandName: any = null;

      //const rustPart = extractRustPart(props.hostname);

      /*
      if (rustPart) {
        const brandsResponse = await API.loadBrands({ name: rustPart });
        if (
          brandsResponse &&
          brandsResponse.brands &&
          brandsResponse.brands.length
        ) {
          const brand = brandsResponse.brands[0];
          brandId = brand.brand_id;
          brandName = brand.brand_name;
        }
      }
      */

      let isBrandPage = false;

      if (brandId) {
        isBrandPage = true;
        const searchTerm = SearchResultsContainer.getSearchQuery(props);
        await Promise.all([
          // await dispatch(loadSearchTermArticlesResults(searchTerm, 4)),
          await dispatch(loadSearchTermResults('', 20, offset, brandId)),
          await dispatch(loadBrandDetail(brandId, true)),
          await dispatch(loadBrandCategories(brandId)),
        ]);
        dispatch(
          setBreadCrumbPath(BreadCrumbType.BRAND, {
            name: brandName,
            url: '',
          }),
        );
      } else if (brandUrl) {
        isBrandPage = true;
        const brandsResponse = await API.loadBrands({ url: brandUrl });
        if (brandsResponse?.brands?.length) {
          const brand = brandsResponse.brands[0];
          brandId = brand.brand_id + '';
          brandName = brand.brand_name;
          const [defaultSort, defaultSortDir] = defaultSortOption.split('-');
          await Promise.all([
            // await dispatch(loadSearchTermArticlesResults(searchTerm, 4)),
            await dispatch(
              loadSearchTermResults(
                '',
                20,
                offset,
                brandId,
                { sort: defaultSort },
                undefined,
                urlAttribsToObject(location.query),
              ),
            ),
            await dispatch(loadBrandDetail(brandId, true)),
            await dispatch(loadBrandCategories(brandId)),
          ]);
          dispatch(
            setBreadCrumbPath(BreadCrumbType.BRAND, {
              name: brandName,
              url: '',
            }),
          );
        }
      } else {
        const searchTerm = SearchResultsContainer.getSearchQuery(props);
        await Promise.all([
          // await dispatch(loadSearchTermArticlesResults(searchTerm, 4)),
          await dispatch(
            loadSearchTermResults(
              searchTerm,
              20,
              offset,
              undefined,
              undefined,
              undefined,
              urlAttribsToObject(query),
            ),
          ),
        ]);
      }

      return {
        isBrandPage: isBrandPage,
        brandName: brandName,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
      };
    }
  }

  public render() {
    const { lang, location, hostname, isBrandPage, brandName } = this.props;
    const q = SearchResultsContainer.getSearchQuery(this.props);

    return (
      <>
        <Wrapper className="container container--wide">
          {!isBrandPage ? (
            <MetaTags
              tags={{ title: `${__('Vyhľadávanie')}: ${q}` }}
              // canonicalUrl={`${resolveBaseUrlByLang(lang)}${location.pathname}`}
            />
          ) : (
            <MetaTags
              tags={{ title: `${brandName}` }}
              // canonicalUrl={`${resolveBaseUrlByLang(lang)}${location.pathname}`}
            />
          )}
          {!isBrandPage && this.renderHeader()}
          {this.renderResults()}
        </Wrapper>
      </>
    );
  }

  public renderHeader() {
    const {
      searchedProducts,
      isBrandPage,
      brandName,
      searchIsFetching,
    } = this.props;

    const searchTerm = SearchResultsContainer.getSearchQuery(this.props);

    if (searchIsFetching) {
      return null;
    }

    return (
      <Header>
        <h1>
          <SearchedTermWrapper>
            {isBrandPage ? __('Značka') : searchTerm ? __('Hľadaný výraz') : ''}{' '}
            <HighlitedSearchTerm>
              "{isBrandPage ? brandName : searchTerm}"
            </HighlitedSearchTerm>
          </SearchedTermWrapper>
        </h1>
      </Header>
    );
  }

  public renderResults() {
    const {
      searchedProducts: products,
      searchIsFetching,
      dispatch,
      user,
      router,
      location,
      articles,
      isFetchingArticles,
      lang,
      currency,
      filterData,
      hostname,
      isBrandPage,
      brandDetail,
      brandCategories,
      byId,
    } = this.props;

    const baseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;
    const searchTerm = SearchResultsContainer.getSearchQuery(this.props);

    const subcategoryFilterAttribsArray: string[] = [];
    if (
      brandDetail &&
      brandDetail.filter_attribs &&
      brandDetail.filter_attribs.length
    ) {
      for (const filterAttrib of brandDetail.filter_attribs) {
        subcategoryFilterAttribsArray.push(
          `${filterAttrib.attrib_id}|${filterAttrib.attrib_value_id}`,
        );
      }
    }
    const subcategoryFilterAttribs = subcategoryFilterAttribsArray.join(',');

    return (
      // <FlexCol>
      //   <ProductList
      //     addToFavorite={add}
      //     apiUrl={baseApiUrl}
      //     isFetching={searchIsFetching}
      //     products={products.products}
      //     addToCart={this.addToCart}
      //     addToRequest={this.addToRequest}
      //   />
      //   <Pagination
      //     query={this.getPaginationQuery()}
      //     totalItems={(products && products.total) || 0}
      //     limit={(products && products.limit) || 24}
      //     offset={(products && products.offset) || 0}
      //     onOffsetChange={this.handleOffsetChange}
      //   />
      // </FlexCol>

      <SearchResults
        user={user}
        apiUrl={baseApiUrl}
        query={this.getPaginationQuery()}
        dispatch={dispatch}
        isFetching={searchIsFetching}
        products={products}
        addToCart={this.addToCart}
        router={router}
        searchTerm={searchTerm}
        location={location}
        articles={articles}
        isFetchingArticles={isFetchingArticles}
        lang={lang}
        categoryAttribs={products.attribs}
        currency={currency}
        filterData={filterData}
        domainId={+resolveDomainByHostname(hostname)}
        title={isBrandPage && brandDetail ? brandDetail.brand_name : undefined}
        description={
          isBrandPage && brandDetail ? brandDetail.brand_descr : undefined
        }
        titleImage={
          isBrandPage && brandDetail ? brandDetail.brand_image : undefined
        }
        titleLink={
          isBrandPage && brandDetail ? brandDetail.brand_provider : undefined
        }
        categories={
          isBrandPage && brandCategories && brandCategories.length
            ? brandCategories
            : undefined
        }
        subcategoryFilterAttribs={subcategoryFilterAttribs}
        isBrandPage={isBrandPage}
        byId={byId}
      />
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?q=${this.props.location.query.q}`;
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    if (count > 0) {
      await dispatch(addItemToCart(product, count, false, true));
    } else {
      await dispatch(setAddToCartModalVisibility(false, product));
    }
  };

  private addToRequest = () => {
    // console.log('add to request');
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  searchIsFetching: searchResultsIsFetchingSelector(state),
  searchedProducts: searchResultsSelector(state),
  lang: langSelector(state),
  articles: searchResultsArticlesSelector(state),
  isFetchingArticles: searchResultsIsFetchingArticlesSelector(state),
  hostname: hostnameSelector(state),
  currency: currencySelector(state),
  filterData: categoryFilterDataSelector(state),
  brandDetail: brandDetailSelector(state),
  brandCategories: brandCategoriesSelector(state),
  byId: categoryEntitiesSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SearchResultsContainer' })(
    withRouter(SearchResultsContainer),
  ),
);
