import React from 'react';
import { TextP, DsiSvgIcon as SvgIcon } from 'eshop-defaults';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import { __ } from 'react-i18n';
import { Link } from 'react-router';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { __r } from 'react-i18n/lib';

function HeaderFavourite() {
  return (
    <Wrapper to={__r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')}>
      {/* <SvgIcon
        icon={IconType.favourites}
        alt="Prejsť na obľúbené"
        cursor={'pointer'}
        fill={'#1E398D'}
      />
      <Favourite>{__('Obľúbené')}</Favourite> */}
    </Wrapper>
  );
}

const Favourite = styled(TextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.primary};
  margin-left: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-right: ${rem(32)};
  height: ${rem(48)};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.2s ease;
  /* width: ${rem(150)}; */

  &:hover p {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.mediab.l1150`
    margin-right:${rem(24)};
  `}
`;

export { HeaderFavourite };
