import React, { useState } from 'react';
import { IconImg, DsiSvgIcon as SvgIcon, OutsideClick } from 'eshop-defaults';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { setLanguageWasChanged } from '../../containers/App/actions';
import { __ } from 'react-i18n';

interface Props {
  dispatch: any;
  lang: string;
}

function HeaderLangButton({ dispatch, lang }) {
  const countrysk = 'slovakia.svg';
  const countrycz = 'czech-republic.svg';
  const countryFlags = {
    sk: 'slovakia.svg',
    cz: 'czech-republic.svg',
  };
  const altText = {
    sk: __('Slovenská verzia'),
    cz: __('Česká verzia'),
  };
  const [isOpen, setIsOpen] = useState(false);

  const closeNav = e => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const toggleNav = () => {
    if (!isOpen) {
      resetState();
    }
    setIsOpen(!isOpen);
  };

  const resetState = () => {
    setIsOpen(!isOpen);
  };

  const handleLangChange = e => {
    const newLang = e.target.id;

    if (lang !== newLang) {
      // setLangCookie(newLang);
      // setActiveLang(newLang);
      closeNav(e);
      // dispatch(setLanguage(newLang));

      // const newCurrency = getCurrencyFromLang(newLang);
      // setCurrencyCookie(newCurrency);
      // dispatch(setCurrency(newCurrency));

      dispatch(setLanguageWasChanged());
    }
  };

  const imgSrc = `/images/countries/${countryFlags[lang]}`;
  return (
    <Wrapper>
      <LangButton onClick={toggleNav}>
        <StyledIconImg title={altText[lang]} alt={altText[lang]} src={imgSrc} />
        {isOpen ? (
          <UpArrowIcon icon={IconType.upArrow} width={50}></UpArrowIcon>
        ) : (
          <DownArrowIcon icon={IconType.downArrow} width={50}></DownArrowIcon>
        )}
      </LangButton>
      <OutsideClick handleFunction={closeNav}>
        <DropWrapper isOpen={isOpen}>
          {lang !== 'sk' && (
            <DroppedLangButton id="sk" onClick={handleLangChange}>
              <StyledIconImg
                title={__('Prejsť na slovenskú verziu')}
                alt={__('Prejsť na slovenskú verziu')}
                id="sk"
                src={`/images/countries/${countryFlags['sk']}`}
              />
            </DroppedLangButton>
          )}
          {lang !== 'cz' && (
            <DroppedLangButton id="cz" onClick={handleLangChange}>
              <StyledIconImg
                title={__('Prejsť na českú verziu')}
                alt={__('Prejsť na českú verziu')}
                id="cz"
                src={`/images/countries/${countryFlags['cz']}`}
              />
            </DroppedLangButton>
          )}
        </DropWrapper>
      </OutsideClick>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #ffffff;
  border: ${({ theme }) => theme.borders.primary};
  border-radius: 4px;
  box-shadow: 0px 1px 4px #00000014;
  opacity: 1;
  margin-left: ${rem(30)};
  cursor: pointer;
`;
const DownArrowIcon = styled(SvgIcon)`
  position: relative;
  top: 10px;
  left: 10px;
  cursor: pointer;
`;
const UpArrowIcon = styled(SvgIcon)`
  position: relative;
  top: 8px;
  left: 3px;
  cursor: pointer;
`;
const LangButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 13px 13px 13px;
  cursor: pointer;
`;

const DroppedLangButton = styled(LangButton)`
  border-top: ${({ theme }) => theme.borders.primary};
  justify-content: center;
  cursor: pointer;
`;

const DropWrapper = styled.div<{ isOpen: boolean }>`
  color: #ffffff;
  border: ${({ theme }) => theme.borders.primary};
  background-color: #ffffff;
  position: absolute;
  width: ${rem(76)};
  z-index: 100;
  display: ${({ isOpen }) => (!isOpen ? 'none' : 'block')};
`;

const StyledIconImg = styled(IconImg)`
  cursor: pointer;
`;

export { HeaderLangButton };
