import * as React from 'react';
import {
  getOtherLang,
  resolveBaseUrlByLang,
  resolveOtherBaseUrlByLang,
} from '../../configureTrans';
import { prop } from '../../utilities';

interface Props {
  langChanged: boolean;
  lang: string;
  currentLocation: any;
}

function AppLanguageRedirectChecker({
  langChanged,
  lang,
  currentLocation,
}: Props) {
  React.useEffect(() => {
    const currentUrl = prop(window, 'location.origin');
    const otherLang = getOtherLang(lang);
    if (currentLocation === '/' || currentLocation === '/cz') {
      if (langChanged) {
        window.location.href = currentUrl.replace(`.${lang}`, `.${otherLang}`);
      }
    } else {
      setTimeout(() => {
        if (langChanged) {
          window.location.href = currentUrl.replace(
            `.${lang}`,
            `.${otherLang}`,
          );
        }
      }, 1500);
    }
  }, [lang, langChanged]);

  return null;
}

export default AppLanguageRedirectChecker;
