import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { ErrorTypeAPI, getObjectProperty } from '../../utilities/redux';
import { prop } from '../../utilities';

interface ManagerReportState {
  data: any;
  filters: any;
  error: ErrorTypeAPI;
  isFetching: boolean;
}

const initialState: ManagerReportState = {
  data: [],
  filters: {},
  isFetching: false,
  error: null,
};

const managerReport = createSlice({
  name: 'managerReport',
  initialState,
  reducers: {
    startLoadingData(state) {
      state.isFetching = true;
    },
    setFilters(
      state,
      action: PayloadAction<{
        filters: any;
      }>,
    ) {
      state.filters = action.payload.filters;
    },
    fetchDataSuccess(
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) {
      action.payload.data.data.forEach(item => {
        item.id = `${item.id.toString()}-${item.lang_id}`;
      });

      state.data = action.payload.data;
      state.isFetching = false;
    },
    stopLoadingData(state) {
      state.isFetching = false;
    },
    fetchDataError(state, action: PayloadAction<ErrorTypeAPI>) {},
    //   setInitialCategory(state) {
    //     state.data = null;
    //     state.error = null;
    //     stopFetching(state, '');
    //   },
    //   startFetchingCategory(state) {
    //     startFetching(state, '');
    //   },
    //   fetchCategorySuccess(
    //     state,
    //     action: PayloadAction<{
    //       category: any;
    //     }>,
    //   ) {
    //     state.data = action.payload.category;
    //     state.error = null;
    //     stopFetching(state, '');
    //   },
    //   fetchCategoryError(state, action: PayloadAction<ErrorTypeAPI>) {
    //     // state.data = null;
    //     state.error = action.payload;
    //     stopFetching(state, '');
    //   },
  },
});

export const fetchData = (urlAttribsObj): any => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingData());
    const params = {};
    for (const [key, value] of urlAttribsObj) {
      params[key] = value;
    }
    params['offset'] = params['offset'] - 1;
    params['offset'] =
      (params['offset'] === '1' || params['offset'] === '0' || !params['offset']
        ? 0
        : params['offset']) * (params['limit'] || 10);
    params['customerId'] = params['customerId'].split('-')[0];
    params['customerAddressId'] = params['customerAddressId'].split('ch_')[1];
    params['sortDirection'] = params['sortDir'];
    const data = await API.loadManagerI6InvoicesData(params);
    dispatch(fetchDataSuccess({ data }));
  } catch (err) {}
};

export const {
  startLoadingData,
  fetchDataSuccess,
  fetchDataError,
} = managerReport.actions;
export default managerReport.reducer;

export const managerReportDomainSelector = state => state.managerReport || {};

export const managerReportDataSelector = createSelector(
  managerReportDomainSelector,
  substate => prop(substate, 'data', null),
);

export const managerReportFetchingSelector = createSelector(
  managerReportDomainSelector,
  substate => prop(substate, 'isFetching', null),
);
