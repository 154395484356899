import {
  REQUEST_CATEGORIES,
  RECEIVE_CATEGORIES_SUCCESS,
  RECEIVE_CATEGORIES_ERROR,
  RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  REQUEST_TOP_PRODUCTS_CATEGORY,
  RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  REQUEST_CATEGORY_PRODUCTS,
  RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  RECEIVE_CATEGORY_PRODUCTS_ERROR,
  SET_CATEGORY_FILTER,
  REQUEST_CATEGORY_BRANDS,
  RECEIVE_CATEGORY_BRANDS_SUCCESS,
  RECEIVE_CATEGORY_BRANDS_ERROR,
  SET_CATEGORY_FILTERS,
  RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  REQUEST_SITEMAP_ARTICLES,
  RECEIVE_SITEMAP_ARTICLES_ERROR,
  RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  SET_CATEGORY_ARTICLES_FILTERS,
  REQUEST_SITEMAP_DATA,
  RECEIVE_SITEMAP_DATA_SUCCESS,
  RECEIVE_SITEMAP_DATA_ERROR,
  REQUEST_SUBCATEGORIES,
  RECEIVE_SUBCATEGORIES_SUCCESS,
  RECEIVE_SUBCATEGORIES_ERROR,
} from './constants';
import { filterEmptyCategories, prop } from '../../utilities';
import {
  currencySelector,
  hostnameSelector,
  langSelector,
  userSelector,
} from '../App/selectors';
import { categoryDataSelector } from './selectors';
import { resolveCurrentThemeFromCategory } from '../../utilities/category';
import { setCurrentTheme } from '../App/actions';
import {
  DEFAULT_CATEGORY_ID,
  extractBaseDomain,
  resolveDomainByHostname,
} from '../../configureTrans';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { defaultSortOption } from '../../components/Category/Category';
import { __, __r } from 'react-i18n';
import { DSI_VAT_VIEW_COOKIE, DSI_VOC_VIEW_COOKIE } from '../App/constants';
import * as cookie from 'react-cookies';
import { cartIdSelector } from '../Cart/cartSlice';

export const setCategoryFilter = (sort, sortDir) => ({
  type: SET_CATEGORY_FILTER,
  payload: { sort, sortDir },
});

const requestCategoryProducts = () => ({
  type: REQUEST_CATEGORY_PRODUCTS,
});

const receiveCategoryProductsSuccess = products => ({
  type: RECEIVE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveCategoryProductsError = error => ({
  type: RECEIVE_CATEGORY_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

const receiveMoreCategoryProductsSuccess = products => ({
  type: RECEIVE_MORE_CATEGORY_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

export const setCategoryInitialStateFromUrl = location => {
  return async dispatch => {
    const { query } = location;
    let offset = 0;
    let sort = '';
    let sortDir = '';
    if (query.offset) {
      if (isNaN(query.offset)) {
        offset = 0;
      } else {
        offset = Number(query.offset);
      }
    }
    if (query.sort) {
      sort = prop(query, 'sort', '');
    }
    if (query.sortDir) {
      sortDir = prop(query, 'sortDir', '');
    }
    dispatch(setCategoryFilters({ offset, pathname: location.pathname }));
    dispatch(setCategoryFilter(sort, sortDir));
  };
};

export const getNextCategoryUrl = (category, newOffset = 0, sort, sortDir) => {
  return {
    pathname: category.category.data.url,
    query: {
      offset:
        newOffset.toString() || category.category.products.products.offset,
      sort: sort || sort === '' ? sort : category.category.products.sort,
      sortDir:
        sortDir || sortDir === ''
          ? sortDir
          : category.category.products.sortDir,
    },
    state: {
      reload: false,
    },
  };
};

export const setCategoryFilters = filters => ({
  type: SET_CATEGORY_FILTERS,
  payload: {
    filters,
  },
});

export const loadCategoryProducts = (
  categoryIdOrUrl,
  filters?: any,
  isSaleout = false,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());

      const lang: string = langSelector(getState());
      const currency: string = currencySelector(getState());
      const cartId = cartIdSelector(getState());

      const [defaultSort, defaultSortDir] = defaultSortOption.split('-');

      const user = userSelector(getState());

      const hostname = hostnameSelector(getState());
      if (isSaleout) {
        filters.saleout = '1';
      }

      const isB2B = user && user.b2b;

      const voc =
        cookie.load(DSI_VOC_VIEW_COOKIE) === 'voc'
          ? '1'
          : !cookie.load(DSI_VOC_VIEW_COOKIE) && isB2B
          ? '1'
          : '0';

      const withVat =
        cookie.load(DSI_VAT_VIEW_COOKIE) === 'withVat' ? '1' : '0';

      let categoryId = categoryIdOrUrl;

      let categoryTopParentId =
        DEFAULT_CATEGORY_ID[extractBaseDomain(hostname)][lang];
      if (categoryIdOrUrl === 'edsi-kategorie/bazar') {
        categoryTopParentId = undefined;
      }

      const categories = await API.loadCategories({
        publishUrl: categoryIdOrUrl,
        categoryTopParentId,
        publishLang: lang,
      });

      if (categories && categories.length) {
        categoryId = categories[0].category_id;
      }

      const elasticCategory = await API.loadElasticCategoryProducts(
        categoryId,
        {
          limit: 24,
          domainId: resolveDomainByHostname(hostname),
          langId: lang,
          onlySubcategories: '1',
          voc,
          withVat,
          sort: defaultSortOption,
          cartId,
          ...filters,
          ...filters.otherAttribs,
        },
      );

      if (isSaleout) {
        dispatch(
          setBreadCrumbPath(BreadCrumbType.CUSTOM, {
            name: __('Výpredaj'),
            url: __r('routes:vypredaj', '/vypredaj'),
          }),
        );
      } else {
        dispatch(
          setBreadCrumbPath(
            BreadCrumbType.CATEGORY,
            elasticCategory.categoryInfo,
          ),
        );
      }
      dispatch(
        receiveCategoryProductsSuccess({
          ...elasticCategory.products,
          price_min: elasticCategory.minPrice,
          price_max: elasticCategory.maxPrice,
          price_min_without_vat: elasticCategory.minMocPriceWithoutVat,
          voc_price_min: elasticCategory.minVocPrice,
          voc_price_min_without_vat: elasticCategory.minVocPriceWithoutVat,
          price_max_without_vat: elasticCategory.maxVocPriceWithoutVat,
          voc_price_max: elasticCategory.maxVocPrice,
          voc_price_max_without_vat: elasticCategory.maxVocPriceWithoutVat,
          includesGifts: elasticCategory.includesGifts,
          includesSales: elasticCategory.includesSales,
          includesRecycled: elasticCategory.includesRecycled,
          includesFreeDelivery: elasticCategory.includesFreeDelivery,
        }),
      );
      dispatch(
        receiveCategorySuccess(
          elasticCategory.categoryInfo,
          elasticCategory.attribsAggs,
        ),
      );

      const redirectedCategoryIds = elasticCategory.subTree
        .map(o => o.redirect_category_id)
        .filter(o => o);
      const redirectCategoryPromises = redirectedCategoryIds.map(
        async categoryId => {
          return API.loadElasticCategory(categoryId, {
            sort: defaultSortOption,
            langId: lang,
            onlySubcategories: '1',
            domainId: resolveDomainByHostname(hostname),
          });
        },
      );

      const redirectCategoryResults = await Promise.all(
        redirectCategoryPromises,
      );
      for (const subCategoryItem of elasticCategory.subTree) {
        const redirectCategoryItem: any = redirectCategoryResults.find(
          (pr: any) =>
            pr.categoryInfo.category_id ===
            subCategoryItem.redirect_category_id,
        );
        if (redirectCategoryItem) {
          subCategoryItem.isRedirected = true;
          subCategoryItem.redirectCounts = redirectCategoryItem.products.total;
          subCategoryItem.redirectChildren = redirectCategoryItem.subTree;
        }
      }

      dispatch(
        receiveSubCategoriesTreeSuccess(
          filterEmptyCategories(elasticCategory.subTree),
        ),
      );
    } catch (e) {
      // console.log({ e });
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

export const loadMoreCategoryProducts = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryProducts());
      const products = await API.searchProducts({
        categoryId,
        offset:
          prop(getState(), 'category.category.products.products.offset', 0) +
          10,
        limit: 10,
        sort: prop(getState(), 'category.category.products.sort'),
        sortDir: prop(getState(), 'category.category.products.sortDir'),
      });
      dispatch(receiveMoreCategoryProductsSuccess(products));
    } catch (e) {
      dispatch(receiveCategoryProductsError(e));
    }
  };
};

const requestTopProductsCategory = () => ({
  type: REQUEST_TOP_PRODUCTS_CATEGORY,
});

const receiveTopProductsCategorySuccess = topProducts => ({
  type: RECEIVE_TOP_PRODUCTS_CATEGORY_SUCCESS,
  payload: {
    topProducts,
  },
});

const receiveTopProductsCategoryError = error => ({
  type: RECEIVE_TOP_PRODUCTS_CATEGORY_ERROR,
  payload: {
    error,
  },
});

export const loadTopProductsCategory = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestTopProductsCategory());
      const topProducts = await API.searchProducts({
        categoryId,
        isTop: 1,
      });
      dispatch(receiveTopProductsCategorySuccess(topProducts));
    } catch (e) {
      dispatch(receiveTopProductsCategoryError(e));
    }
  };
};

const requestCategory = () => ({
  type: REQUEST_CATEGORIES,
});

const receiveCategorySuccess = (category, attribs) => ({
  type: RECEIVE_CATEGORIES_SUCCESS,
  payload: {
    category,
    attribs,
  },
});

const receiveCategoryError = error => ({
  type: RECEIVE_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadCategory = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      const currentCategory = categoryDataSelector(getState());
      let category = currentCategory;
      if (
        !category ||
        !category.category_id ||
        category.category_id.toString() !== categoryId
      ) {
        dispatch(requestCategory());
        category = await API.loadCategory(categoryId);
        // dispatch(receiveCategorySuccess(category));
      }

      const { category_number, parent_categories } = category;
      const uniqueCatIds: string[] = [];
      uniqueCatIds.push(category_number);
      parent_categories.map(p => uniqueCatIds.push(p.category_number));
      const theme = resolveCurrentThemeFromCategory(uniqueCatIds);
      dispatch(setCurrentTheme(theme));
    } catch (e) {
      dispatch(receiveCategoryError(e));
    }
  };
};

const requestCategoryBrands = () => ({
  type: REQUEST_CATEGORY_BRANDS,
});

const receiveCaegoryBrandsSuccess = category => ({
  type: RECEIVE_CATEGORY_BRANDS_SUCCESS,
  payload: {
    category,
  },
});

const receiveCategoryBrandsError = error => ({
  type: RECEIVE_CATEGORY_BRANDS_ERROR,
  payload: {
    error,
  },
});

export const loadCategoryBrands = categoryId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCategoryBrands());
      const brands = await API.loadBrands(categoryId);
      dispatch(receiveCaegoryBrandsSuccess(brands));
    } catch (e) {
      dispatch(receiveCategoryBrandsError(e));
    }
  };
};

const requestSitemapArticles = () => ({
  type: REQUEST_SITEMAP_ARTICLES,
});

const receiveSitemapArticlesSuccess = articles => ({
  type: RECEIVE_SITEMAP_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveSitemapArticlesError = error => ({
  type: RECEIVE_SITEMAP_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadSitemapArticles = (sitemapId, offset = 0) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSitemapArticles());
      dispatch(setCategoryArticlesFilters({ offset }));
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          sitemapId,
          offset,
          limit: 10,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(
        receiveSitemapArticlesSuccess(articles || { articles: [], total: 0 }),
      );
    } catch (e) {
      dispatch(receiveSitemapArticlesError(e));
    }
  };
};

const requestSitemapData = () => ({
  type: REQUEST_SITEMAP_DATA,
});

const receiveSitemapDataSuccess = data => ({
  type: RECEIVE_SITEMAP_DATA_SUCCESS,
  payload: {
    data,
  },
});

const receiveSitemapDataError = error => ({
  type: RECEIVE_SITEMAP_DATA_ERROR,
  payload: {
    error,
  },
});

export const loadSitemapData = sitemapId => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSitemapData());
      const data = await API.loadSitemap(sitemapId);
      dispatch(receiveSitemapDataSuccess(data));
    } catch (e) {
      dispatch(receiveSitemapDataError(e));
    }
  };
};

const requestSubCategoriesTree = () => ({
  type: REQUEST_SUBCATEGORIES,
});

const receiveSubCategoriesTreeSuccess = categories => ({
  type: RECEIVE_SUBCATEGORIES_SUCCESS,
  payload: {
    categories,
  },
});

const receiveSubCategoriesTreeError = error => ({
  type: RECEIVE_SUBCATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadCategoriesTree = category_id => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestSubCategoriesTree());
      const categories = await API.loadSubTree(category_id, {
        onlyVisible: '1',
      });
      dispatch(receiveSubCategoriesTreeSuccess(categories));
    } catch (e) {
      dispatch(receiveSubCategoriesTreeError(e));
    }
  };
};

export const setCategoryArticlesFilters = filters => ({
  type: SET_CATEGORY_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});
