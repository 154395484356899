import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  fetchCustomerDetails,
  customerInfoSelector,
  fetchCustomerFinancialDetails,
  customerFinancialInfoSelector,
} from './myAccountSlice';
import MyDashboard from '../../components/MyAccount/MyDashboard';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __, __r } from 'react-i18n';
import { currencySelector, langSelector } from '../App/selectors';
import { Redirect, WithRouterProps, withRouter } from 'react-router';

const INVOICES = [
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
    notPaid: true,
  },
  {
    invoiceNr: '401902305',
    sumPrice: '9999,99',
    invoicePath: '',
    invoiceFileName: '401902305.pdf',
  },
];

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  customerInfo: any;
  lang: string;
  userFinancialData: any;
  currency: any;
}

interface State {
  currentId: string;
}

class MyDashboardContainer extends React.Component<
  Props & WithRouterProps,
  State
> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_DASHBOARD));
    } catch (e) {
      // // console.log(e);
    }
    try {
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchOrders(null, LIMIT, 0)),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: props.user.id,
    };
  }

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
      customerInfo,
      lang,
      userFinancialData,
      currency,
    } = this.props;
    const { currentId } = this.state;
    const isB2C = user && !user.b2b;

    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: (user.id || '').toString(),
          }))
          .map(a => a)
      : [];
    const options = [
      { name: __('Všetky pobočky'), value: user.id.toString() },
      ...childUserOptions,
    ];
    if (isB2C) {
      this.props.router.push(`/`);
      // return (
      //   <Redirect
      //     from={__r('routes:moj-ucet/dashboard', '/moj-ucet/dashboard')}
      //     to={__r('routes:moj-ucet/moje-nakupy', '/moj-ucet/moje-nakupy')}
      //   />
      // );
    }

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Dashboard') }} />
        <MyDashboard
          orders={ordersById[currentId]}
          invoices={INVOICES}
          ordersData={ordersData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrders}
          user={customerInfo}
          lang={lang}
          userData={customerInfo}
          userFinancialData={userFinancialData}
          currency={currency}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ currentId: id });
    this.props.dispatch(fetchOrders(id, LIMIT, 0));
  };

  public handleOffsetChange = e => {
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;
    const offset = LIMIT * num;
    this.props.dispatch(fetchOrders(currentId, LIMIT, offset));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
    customerInfo: customerInfoSelector(state),
    lang: langSelector(state),
    userFinancialData: customerFinancialInfoSelector(state),
    currency: currencySelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyDashboardContainer' })(
    withRouter(MyDashboardContainer),
  ),
);
