import * as React from 'react';
import styled from 'styled-components';

import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel';
import { round } from '../../../utilities/index';
import { rem, rgba } from 'polished';
import { DsiSvgIcon as SvgIcon, DsiTag as Tag } from 'eshop-defaults';
import { DsiTags as Tags } from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { FlexRowCenterVertical, Picture } from 'eshop-defaults/lib';
import { css } from '../../../theme/styled-components';
import { __ } from 'react-i18n';
import { Helmet } from 'react-helmet';
import { getImagePath } from '../../../utilities/product';

const BtnBackWrapper = styled(ButtonBack)<{ isFullscreen: boolean }>`
  position: absolute;
  padding-bottom: ${rem(8)};
  border: none;
  z-index: 1;
  background-color: transparent;
  opacity: 1;
  top: ${({ isFullscreen }) => (isFullscreen ? '50%' : '40%')};
  left: 0;
  transform: translate(0%, -50%);

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const Icon = styled(SvgIcon)`
  cursor: pointer;
  padding: 0px !important;
`;

const BtnBack = styled(Icon)`
  cursor: pointer;
  padding: 0px !important;
`;

const BtnNext = styled(Icon)`
  cursor: pointer;
  padding: 0px !important;
`;

const BtnNextWrapper = styled(ButtonNext)<{ isFullscreen: boolean }>`
  position: absolute;
  padding-bottom: ${rem(8)};
  z-index: 1;
  border: none;
  top: ${({ isFullscreen }) => (isFullscreen ? '50%' : '40%')};
  right: 10px;
  transform: translate(0%, -50%);
  background-color: transparent;
  opacity: 1;

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const Carousel = styled(CarouselProvider)`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const DotComponent = styled(Dot)`
  border: 0;
  width: 100%;
  background-color: transparent;
  padding: ${rem(12)} ${rem(24)};
  opacity: 0.16;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
  &:disabled {
    opacity: 1;
  }
`;

const DotWrapper = styled.div`
  margin: ${rem(16)} 0;
  position: relative;
  width: 100%;
  border-right: ${({ theme }) => theme.borders.primary};
  &:last-child {
    border: 0;
  }
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const sharedDotImage = css`
  margin: 0 auto;
  ${({ theme }) => theme.mediab.l925`
    width: 40px;
    height: 40px;
  `}
`;

const DotImage = styled.img`
  ${sharedDotImage}
`;

const Dots = styled.div`
  width: 100%;
  margin: auto;
  bottom: 0;
  text-align: center;
  position: absolute;
  border-top: ${({ theme }) => theme.borders.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  ${({ theme }) => theme.mediab.l925`
    padding-bottom: ${rem(16)};
    transform: translate(-50%, -50%);
    left: 50%;
    width: auto;
    diplay: none;
    border-top: 0;
    justify-content: center;
  `}
`;

const MobileDot = styled(Dot)`
  display: none;
  width: 8px;
  height: 8px;
  border: none;
  background-color: rgba(30, 57, 141, 0.16);
  padding: 0;
  margin: 0 4px;
  border-radius: 50%;
  ${({ theme }) => theme.mediab.l925`
    display: block;
  `}
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const PhotogalleryItem = styled.div<{
  isFullscreen: boolean;
  saleEnded: boolean;
}>`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  ${({ isFullscreen }) =>
    !isFullscreen ? 'padding: 0px 80px 0px 80px' : 'padding: 0 80px 0 80px'};
  width: 90%;
  margin: 0 auto;
  height: 100%;

  ${({ saleEnded }) => (saleEnded ? 'opacity: 0.5' : '')};

  ${({ theme }) => theme.mediab.l925`
    max-height: 450px;
    height: 420px;
    width: 90%;
    padding: 20px;
    margin: 20px auto;
  `}

  ${({ theme }) => theme.mediab.l1920`
    height: ${({ isFullscreen }) => (isFullscreen ? '90%' : '100%')};
  `}
`;

const sharedPhotogalleryCover = css`
  object-fit: contain;
  width: 90%;
  height: auto;

  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`;

const PhotogalleryCoverSource = styled.source<{ isFullscreen: boolean }>`
  ${sharedPhotogalleryCover}
`;

const PhotogalleryCover = styled.img<{ isFullscreen: boolean }>`
  ${sharedPhotogalleryCover}
`;

const FullscreenButton = styled(FlexRowCenterVertical)`
  width: ${rem(56)};
  height: ${rem(56)};
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.04)};
  border-radius: 50%;
  position: absolute;
  right: ${rem(24)};
  top: ${rem(24)};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const FullscreenIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ImageWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  margin: 0 auto;
`;

const SliderCustom = styled(Slider)<{ isFullscreen?: boolean }>`
  height: 100%;
  ${({ isFullscreen }) =>
    !isFullscreen
      ? `display: flex;
  align-items: center;
  padding-bottom: 20%;
  padding-top: 40px;
  justify-content: center;`
      : ``};

  ${({ theme }) => theme.mediab.l925`
    ${({ isFullscreen }) =>
      isFullscreen &&
      ` 
      display: flex;
      align-items: center;
    `};
  `}

  & > .carousel__slider-tray-wrapper {
    height: 100%;
  }

  & > .carousel__slider-tray-wrapper > .carousel__slider-tray {
    height: 100%;
  }
`;

const customImageFull = css`
  ${sharedPhotogalleryCover}
  max-height: 640px;
  height: auto;
  width: 100%;

  @media (max-height: 920px) {
    max-height: 520px;
  }
  @media (max-height: 780px) {
    max-height: 325px;
  }
  @media (max-height: 600px) {
    max-height: 225px;
  }
  @media (max-width: 925px) {
    max-height: 420px;
    padding-bottom: 20px;
  }
`;
const customImage = css`
  ${sharedPhotogalleryCover}
  max-height: 480px;
  height: auto;
  width: 100%;
`;

const SlideCustom = styled(Slide)<{ isFullscreen?: boolean }>`
  position: relative;
  padding-bottom: 0 !important;
  height: 100%;

  ${({ theme }) => theme.mediab.l925`
  `}
`;

const SaleEndedOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  color: black;
  text-align: center;
  line-height: ${rem(32)};
`;

export interface Props {
  type: string;
  slides: any;
  isSale: boolean;
  isNew: number;
  changeDimmerVisible: () => void;
  isFullscreen?: boolean;
  salePercentage?: number;
  openPhotogalleryModal?: () => void;
  isSecondHand?: boolean;
  isFreeDelivery?: boolean;
  saleEnded?: boolean;
  isPrelisting?: boolean;
  hasGift?: boolean;
}

function SliderComponent({
  type,
  slides,
  isSale,
  isNew,
  isSecondHand = false,
  salePercentage,
  changeDimmerVisible,
  openPhotogalleryModal,
  isFullscreen = false,
  isFreeDelivery,
  saleEnded,
  isPrelisting,
  hasGift,
}: Props) {
  const [state, setState]: any = React.useState(() => {
    return {
      width: 400,
      height: 235,
      visibleSlides: 1,
    };
  });

  React.useEffect(() => {
    const handleResize = () => updateState(window.innerWidth);

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const { width, height, visibleSlides } = state;

  const renderItem = (key: number, img: string) => {
    return (
      <>
        <PhotogalleryItem
          onClick={openPhotogalleryModal}
          key={key}
          isFullscreen={isFullscreen}
          saleEnded={saleEnded || isPrelisting}
        >
          <Picture
            image={img}
            width={600}
            height={600}
            alt={__('Obrázok vo fotogalerií')}
            customStyles={isFullscreen ? customImageFull : customImage}
            noSize={isFullscreen ? true : true}
            lazy={key === 0 ? false : true}
          />

          {/*
          <picture>
            <PhotogalleryCoverSource
              srcSet={getImagePath(img, {
                width: 1064,
                height: 456,
              },false,true,false)}
              isFullscreen={isFullscreen}
              type="image/webp"
            />
            <PhotogalleryCoverSource
              srcSet={getImagePath(img, {
                width: 1064,
                height: 456,
              },false,false,false)}
              isFullscreen={isFullscreen}
              type="image/jpeg"
            />
            <PhotogalleryCover
              alt="Obrázok vo fotogalerií"
              src={getImagePath(img, {
                width: 1064,
                height: 456,
              })}
              isFullscreen={isFullscreen}
            />
          </picture>
          */}
        </PhotogalleryItem>
        {isPrelisting ? (
          <SaleEndedOverlay>{__('Predaj sa začne čoskoro')}</SaleEndedOverlay>
        ) : saleEnded ? (
          <SaleEndedOverlay>{__('Predaj sa skončil')}</SaleEndedOverlay>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderDots = (items: any[], slide: number) => {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(
        <DotWrapper key={i}>
          <DotComponent slide={i}>
            <Picture
              alt={`${__('Obrázok vo fotogalerií')} ${i + 1}`}
              image={items[i]}
              width={64}
              height={64}
            />
          </DotComponent>
        </DotWrapper>,
      );
    }
    return table;
  };

  const renderMobileDots = (items: any[], slide: number) => {
    const table: any[] = [];
    for (let i = 0; i < items.length; i += slide) {
      table.push(<MobileDot slide={i} key={i} />);
    }
    return table;
  };

  const renderBtnBack = (type: string) => {
    return (
      <BtnBackWrapper
        title={__('Späť')}
        type={type}
        isFullscreen={isFullscreen}
      >
        <BtnBack
          icon={IconType.photogalleryBack}
          fill={'#414141'}
          height={32}
        />
      </BtnBackWrapper>
    );
  };

  const renderBtnNext = (type: string) => {
    return (
      <BtnNextWrapper
        title={__('Ďalej')}
        type={type}
        isFullscreen={isFullscreen}
      >
        <BtnNext
          icon={IconType.photogalleryNext}
          fill={'#414141'}
          height={32}
        />
      </BtnNextWrapper>
    );
  };

  const updateState = win => {
    if (type === 'photogallery') {
      if (win < 925 && win >= 580) {
        setState({
          width: 400,
          height: 250,
          visibleSlides: 1,
        });
      } else if (win < 580 && win >= 420) {
        setState({
          width: 400,
          height: 255,
          visibleSlides: 1,
        });
      } else if (win < 420 && win >= 380) {
        setState({
          width: 350,
          height: 255,
          visibleSlides: 1,
        });
      } else {
        setState({
          width: 300,
          height: 250,
          visibleSlides: 1,
        });
      }
    }
  };

  return (
    <>
      {slides && slides.length > 0 ? (
        <>
          <Helmet>
            <link
              rel="preload"
              href={getImagePath(slides[0], { width: 600, height: 600 }, true)}
              as="image"
            />
          </Helmet>

          <Carousel
            naturalSlideHeight={height}
            naturalSlideWidth={width}
            visibleSlides={visibleSlides}
            step={1}
            totalSlides={slides.length}
            infinite={true}
          >
            {!isFullscreen && (
              <Tags
                isFreeDelivery={isFreeDelivery}
                isNew={isNew}
                isSale={isSale}
                isSecondHand={isSecondHand}
                isVertical={true}
                tagWidth={40}
                tagHeight={40}
                hasGift={hasGift}
              />
            )}
            {renderBtnBack(type)}
            <SliderCustom type={type} isFullscreen={isFullscreen}>
              {slides.map((item, index) => {
                return (
                  <SlideCustom
                    isFullscreen={isFullscreen}
                    innerClassName="sliderCentered"
                    index={index}
                    key={index}
                  >
                    {renderItem(index, item)}
                  </SlideCustom>
                );
              })}
            </SliderCustom>
            <FullscreenButton onClick={changeDimmerVisible}>
              {isFullscreen ? (
                <FullscreenIcon
                  icon={IconType.close}
                  width={18}
                  height={18}
                  title={__('Zrušiť zobrazenie na celú obrazovku.')}
                />
              ) : (
                <FullscreenIcon
                  icon={IconType.fullscreen}
                  title={__('Zobraziť na celú obrazovku.')}
                />
              )}
            </FullscreenButton>
            {renderBtnNext(type)}
            <Dots type={type}>
              {renderDots(slides, visibleSlides)}
              {renderMobileDots(slides, visibleSlides)}
            </Dots>
          </Carousel>
        </>
      ) : (
        <ImageWrapper>
          <Image
            src="/images/icons/placeholder.svg"
            alt={__('Obrázok produktu')}
            width={200}
          />
          {isPrelisting ? (
            <SaleEndedOverlay>{__('Predaj sa začne čoskoro')}</SaleEndedOverlay>
          ) : saleEnded ? (
            <SaleEndedOverlay>{__('Predaj sa skončil')}</SaleEndedOverlay>
          ) : (
            <></>
          )}
        </ImageWrapper>
      )}
    </>
  );
}
export { SliderComponent as ProductDetailSliderComponent };
