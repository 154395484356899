import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { DsiRegisterForm as RegisterForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { registerUser } from '../App/actions';
import {
  registrationIsFetchingSelector,
  registrationSuccessMessageSelector,
  registrationErrorMessageSelector,
  hostnameSelector,
} from '../App/selectors';
import { onlyPublic } from '../../utilities/auth';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import API from '../../services/API';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { prop } from '../../utilities';
import { resolveDomainByHostname } from '../../configureTrans';

interface Props {
  lang: string;
  hostname: string;
  dispatch: (action: any) => void;
  isFetching: boolean;
  successMessage: string;
  errorMessage: string;
}

interface State {
  error: string;
  success: string;
}

const Wrapper = styled.div`
  width: 100%;
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(16)};
     margin: 0 auto;
  `}
`;

class RegisterPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      success: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.REGISTRATION));
  }

  public render() {
    const { isFetching, successMessage, errorMessage, lang } = this.props;
    const { success, error } = this.state;
    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: __('Registrácia') }} />
          <RegisterForm
            cartId={''}
            handleFormSubmit={this.handleFormSubmit}
            handleGoogleLogin={this.handleGoogleLogin}
            isFetching={isFetching}
            successMessage={success || successMessage}
            errorMessage={error || errorMessage}
            lang={lang}
            enableWarehouse={true}
            enableEndUser={true}
            showPreRegister={true}
          />
        </Wrapper>
      </>
    );
  }

  public handleFormSubmit = async body => {
    if (body && body.b2c) {
      this.handleUserFormSubmit(body);
      return;
    }
    const { lang, hostname } = this.props;
    try {
      const domain_id = +resolveDomainByHostname(hostname);
      await API.registerB2B({}, { ...body, lang, domain_id });

      this.setState({ success: 'Úspešné zaznamenanie žiadosti.' });
    } catch (e) {
      // console.log({ e });
      this.setState({
        error: prop(e, 'details.description') || __('Vyskytla sa chyba'),
      });
    }
    // this.props.dispatch(registerUser(email, password, true));
  };

  public handleUserFormSubmit = body => {
    const {
      email,
      password,
      confirmPassword,
      termsAccept,
      newsletter_accept,
    } = body;
    this.props.dispatch(
      registerUser(
        email,
        password,
        confirmPassword,
        termsAccept,
        newsletter_accept,
      ),
    );
  };

  public handleFacebookLogin = () => {};

  public handleGoogleLogin = () => {};
}

const mapStateToProps = state => {
  return {
    lang: state.general.lang,
    isFetching: registrationIsFetchingSelector(state),
    successMessage: registrationSuccessMessageSelector(state),
    errorMessage: registrationErrorMessageSelector(state),
    hostname: hostnameSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(RegisterPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
