import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiBackorder as Backorder,
  PaginationSimple,
} from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { LoadAllButton } from './MyInvoices';

interface Props {
  orders: any;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  currency: string;
  brands: any;
  categories: any;
  refetchData: any;
  user: any;
}

function MyBackorder({
  currentId,
  orders,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  currency,
  brands,
  categories,
  refetchData,
  user,
}: Props) {
  const { limit, offset, total } = ordersData;

  const loadAllData = () => {
    refetchData({
      limit: prop(orders, 'pagination.total'),
      userOuterId: prop(orders, 'activeFilters.user_outer_id'),
      categId: prop(orders, 'activeFilters.category_id'),
      brandId: prop(orders, 'activeFilters.brand_id'),
      reserved: prop(orders, 'activeFilters.reserved'),
    });
  };

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Backorder')} (${prop(orders, 'pagination.total') || 0})`}
      </StyledTitle>
      {options && options.length > 1 && (
        <>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <br />
        </>
      )}
      <Backorder
        currency={currency}
        isFetching={isFetchingOrders}
        orders={orders ? orders.backorders : []}
        sumPrice={prop(orders, 'price.sumWithoutVat') || 0}
        brands={
          brands && prop(orders, 'brands')
            ? brands.filter(b => prop(orders, 'brands').includes(b.brand_id))
            : []
        }
        categories={
          categories && prop(orders, 'categories')
            ? categories.filter(c =>
                prop(orders, 'categories').includes(c.category_id),
              )
            : []
        }
        refetchData={refetchData}
        user={user}
      />
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <PaginationSimple
          totalItems={prop(orders, 'pagination.total')}
          limit={prop(orders, 'pagination.limit')}
          offset={prop(orders, 'pagination.offset')}
          handleOffsetChange={handleOffsetChange}
        />
      )}
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <LoadAllButton onClick={loadAllData}>
          {__('Zobraziť všetky')}
        </LoadAllButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export default MyBackorder;
