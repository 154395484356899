import React from 'react';
import {
  FlexCol,
  TextP,
  DsiSvgIcon as SvgIcon,
  DsiButton as Button,
  DsiMiniCart as MiniCart,
} from 'eshop-defaults';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { formatPrice, prop, round } from '../../utilities';
import { cartIndexRoute } from '../../utilities/cart';
import API, { ThenArg } from '../../services/API';
import OutsideClick from '../_helpers/Default/OutsideClick';
import {
  addItemToCart,
  removeItemFromCart,
} from '../../containers/Cart/cartSlice';

interface Props {
  numberOfProducts: number;
  totalPrice: number;
  currency: string;
  cartWidth?: number;
  isMobile?: boolean;
  cartData: ThenArg<typeof API.getCart>;
  dispatch: any;
  user: any;
}

function HeaderCart({
  numberOfProducts,
  totalPrice,
  currency,
  cartWidth,
  isMobile,
  router,
  cartData,
  dispatch,
  user,
}: Props & WithRouterProps) {
  const isB2B = user && user.b2b;
  const totalItemsPrice = cartData ? cartData.total_items_price : 0;
  const totalItemsPriceWithoutVat = cartData
    ? cartData.total_items_price_without_vat
    : 0;
  const lang = cartData ? cartData.lang : 'sk';

  const [isCartVisible, setIsCartVisible] = React.useState(false);
  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  const handleToCartButtonClick = () => {
    // dispatch(setWasAddedToCartModalVisibility());
    setIsCartVisible(false);
    router.push(cartIndexRoute);
  };

  const handleRemoveFromCartButtonClick = (pId: number, gId: number) => {
    dispatch(removeItemFromCart(gId, pId));
  };

  const handleCountChange = (product: any, count: number) => {
    const cartProduct = {
      ...product,
      product_id: prop(product, 'product.product_id'),
      main_good: {
        good_id: prop(product, 'good.good_id'),
      },
    };
    dispatch(addItemToCart(cartProduct, count, false, false));
  };

  const closeMiniCart = () => {
    setIsCartVisible(false);
  };

  return (
    <OutsideClick handleFunction={closeMiniCart}>
      <Wrapper>
        <CartWrapper
          color={isMobile ? 'transparent' : '#1E398D'}
          padding={isMobile ? '0' : '13px 24px'}
          width={'initial'}
          onClick={toggleCart}
        >
          <SvgIcon
            icon={IconType.cart}
            alt={__('Prejsť do košíka')}
            cursor={'pointer'}
            fill={isMobile ? '#1E398D' : '#FFFFFF'}
          />
          <NameLinkWrapper>
            <TotalPrice>
              {formatPrice(
                isB2B ? totalItemsPriceWithoutVat : totalItemsPrice,
                currency,
                2,
                false,
                '',
                lang === 'cz' && !isB2B ? 0 : 2,
              )}
            </TotalPrice>
          </NameLinkWrapper>
        </CartWrapper>
        {isCartVisible && (
          <MiniCartWrapper>
            <MiniCart
              cartData={cartData}
              handleToCartButtonClick={handleToCartButtonClick}
              handleRemoveFromCartButtonClick={handleRemoveFromCartButtonClick}
              handleCountChange={handleCountChange}
              user={user}
            />
          </MiniCartWrapper>
        )}
      </Wrapper>
    </OutsideClick>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const CartWrapper = styled(Button)`
  position: relative;

  &:hover {
    opacity: unset;
    filter: unset;
  }

  ${({ theme }) => theme.mediab.l1150`
    background: transparent;
    padding: 0;
  `}

  ${({ theme }) => theme.mediab.l925`
    width: 24px !important;
    max-width: 24px !important;
  `}
`;

const MiniCartWrapper = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  position: absolute;
  top: ${rem(72)};
  left: -${rem(132)};
  z-index: 99;
  border-radius: ${rem(4)};

  ${({ theme }) => theme.mediab.l1150`
    left:unset;
    right: 0;
  `}

  ${({ theme }) => theme.mediab.m580`
    max-width: ${rem(300)};
    overflow: auto;
  `}
`;

const NameLinkWrapper = styled(FlexCol)`
  margin-left: ${rem(16)};

  ${({ theme }) => theme.mediab.l1150`
    display: none;
  `}
`;

const TotalPrice = styled(TextP)`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${rem(20)};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
`;

const StyledLink = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  position: relative;
`;

export default withRouter(HeaderCart);
