import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  searchResultsSelector,
  searchResultsIsFetchingSelector,
  searchResultsArticlesSelector,
  searchResultsIsFetchingArticlesSelector,
  hostnameSelector,
} from '../App/selectors';
import { FlexCol, FlexRowCenterVertical } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  loadSearchTermArticlesResults,
  loadSearchTermResults,
  setIsBreadcrumbVisible,
} from '../App/actions';
import { push } from 'react-router-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { langSelector } from '../Category/selectors';
import { TagsResults } from '../../components/Tags/TagsResults';
import { addItemToCart } from '../Cart/cartSlice';
import { prop } from '../../utilities';
import {
  resolveBaseUrlByLang,
  resolveDomainByHostname,
} from '../../configureTrans';

interface Props {
  user: any;
  offset: number;
  lang: string;
  location: {
    query: {
      q: string;
      offset?: number;
      sort?: string;
      sortDir?: string;
    };
    pathname: string;
  };
  searchedProducts: ThenArg<typeof API.searchProducts>;
  searchIsFetching: boolean | true;
  dispatch: (action: any) => void;
  articles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  tagId: number;
  hostname: string;
  params: any;
}

const Wrapper = styled(FlexCol)`
  padding: ${rem(56)} ${rem(20)};
  width: 100%;
`;

const Header = styled(FlexCol)`
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m580`
     padding: 0;
     border: 0;
  `}
`;

const SearchedTermWrapper = styled(FlexRowCenterVertical)`
  font-size: ${rem(30)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  text-transform: uppercase;
  line-height: ${rem(38)};
  flex-flow: row wrap;
  display: block;

  ${({ theme }) => theme.mediab.m580`
     font-size: ${rem(20)};
     line-height: ${rem(20)};
  `}
`;

const HighlitedSearchTerm = styled.span`
  font-weight: 700;
  margin-left: ${rem(5)};
`;

const ResultsTotal = styled.p`
  margin: 0;
  margin-top: ${rem(16)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 400;

  ${({ theme }) => theme.mediab.m580`
     margin-top: ${rem(8)};
     font-size: ${rem(16)};
  `}
`;

class TagResultsContainer extends React.Component<Props & WithRouterProps> {
  public static getSearchQuery = (props: Props): string =>
    props.location.query.q;

  public static async getInitialProps(props: Props) {
    try {
      const { dispatch, location, params, lang } = props;
      const {
        query: { offset, sort, sortDir },
      } = location;
      const { tag_name } = params;

      const urlMapData = await API.getUrlMap(
        {
          url: decodeURI(tag_name),
          domainId: resolveDomainByHostname(props.hostname),
          noTag: '0',
        },
        { xAcceptLanguage: lang },
      );

      const tagId = prop(urlMapData, 'data.tag_id');
      if (tagId) {
        await Promise.all([
          // (await dispatch(loadSearchTermArticlesResults(searchTerm, 4, tagId)),
          dispatch(
            loadSearchTermResults('', 20, offset, '', { sort, sortDir }, tagId),
          ),
        ]);
      }
      dispatch(setIsBreadcrumbVisible(false));

      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
  }

  public render() {
    const { lang, location, params } = this.props;
    const tagName = prop(params, 'tag_name');

    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: `${__('Tag')} ${tagName}` }}
            canonicalUrl={`${resolveBaseUrlByLang(lang)}${location.pathname}`}
          />
          {this.renderHeader()}
          {this.renderResults()}
        </Wrapper>
      </>
    );
  }

  public renderHeader() {
    const { params } = this.props;
    const tagName = prop(params, 'tag_name');

    return (
      <Header>
        <h1>
          {' '}
          <SearchedTermWrapper>
            {__('Tag')} <HighlitedSearchTerm>"{tagName}"</HighlitedSearchTerm>
          </SearchedTermWrapper>
        </h1>
      </Header>
    );
  }

  public renderResults() {
    const {
      searchedProducts: products,
      searchIsFetching,
      dispatch,
      user,
      router,
      location,
      articles,
      isFetchingArticles,
      tagId,
      hostname,
    } = this.props;
    const baseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;
    const searchTerm = TagResultsContainer.getSearchQuery(this.props);

    return (
      // <FlexCol>
      //   <ProductList
      //     addToFavorite={add}
      //     apiUrl={baseApiUrl}
      //     isFetching={searchIsFetching}
      //     products={products.products}
      //     addToCart={this.addToCart}
      //     addToRequest={this.addToRequest}
      //   />
      //   <Pagination
      //     query={this.getPaginationQuery()}
      //     totalItems={(products && products.total) || 0}
      //     limit={(products && products.limit) || 24}
      //     offset={(products && products.offset) || 0}
      //     onOffsetChange={this.handleOffsetChange}
      //   />
      // </FlexCol>
      <TagsResults
        user={user}
        apiUrl={baseApiUrl}
        query={this.getPaginationQuery()}
        dispatch={dispatch}
        isFetching={searchIsFetching}
        products={prop(products, 'products')}
        addToCart={this.addToCart}
        router={router}
        searchTerm={searchTerm}
        location={location}
        articles={articles}
        isFetchingArticles={isFetchingArticles}
        tagId={tagId}
        domainId={+resolveDomainByHostname(hostname)}
      />
    );
  }

  public handleOffsetChange = async (newOffset: number) => {
    const q = this.props.location.query.q;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: `?q=${q}&offset=${newOffset}`,
      }),
    );
  };

  public getPaginationQuery = () => {
    return `${this.props.location.pathname}?`;
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count));
  };

  private addToRequest = () => {
    // console.log('add to request');
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  searchIsFetching: searchResultsIsFetchingSelector(state),
  searchedProducts: searchResultsSelector(state),
  lang: langSelector(state),
  articles: searchResultsArticlesSelector(state),
  isFetchingArticles: searchResultsIsFetchingArticlesSelector(state),
  hostname: hostnameSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'TagResultsContainer' })(
    withRouter(TagResultsContainer),
  ),
);
