import { arrayToObject } from '../../utilities';
import { langSelector } from '../App/selectors';

export const namespace = 'CMS';

const requestCMSCategoriesTree = () => ({
  type: 'CMS/REQUEST_CMS_CATEGORIES',
});

const receiveCMSCategoriesTreeSuccess = (sitemap, sitemapObj) => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_SUCCESS',
  payload: {
    sitemap,
    sitemapObj,
  },
});

const receiveCMSCategoriesTreeError = error => ({
  type: 'CMS/RECEIVE_CMS_CATEGORIES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSCategoriesTree = () => {
  return async (dispatch, getState, API) => {
    try {
      if (!getState().cms.sitemap) {
        dispatch(requestCMSCategoriesTree());
        const { sitemap_tree } = await API.loadSitemapSubtree(
          'AKTUALITY',
          {},
          { xAcceptLanguage: getState().general.lang },
        );
        const sitemapObj = arrayToObject(sitemap_tree, 'id');
        dispatch(receiveCMSCategoriesTreeSuccess(sitemap_tree, sitemapObj));
      }
    } catch (e) {
      dispatch(receiveCMSCategoriesTreeError(e));
    }
  };
};

const requestCMSArticles = () => ({
  type: 'CMS/REQUEST_CMS_ARTICLES',
});

const receiveCMSArticlesSuccess = articles => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_SUCCESS',
  payload: {
    articles,
  },
});

const receiveCMSArticlesError = (error: any) => ({
  type: 'CMS/RECEIVE_CMS_ARTICLES_ERROR',
  payload: {
    error,
  },
});

export const loadCMSArticles = (
  sitemapId: number | null = null,
  limit: number = 15,
  offset: number = 0,
  fallbackLang?: string,
) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestCMSArticles());

      // const lang = langSelector(getState()) || fallbackLang;
      const lang = langSelector(getState());
      const paramsObject: any = { limit, offset };
      // if (!sitemapId) {
      //   paramsObject.sitemapId = lang === 'sk' ? 135 : 157;
      // } else {
      //   paramsObject.sitemapId = sitemapId;
      // }

      const articles = await API.loadArticles(
        {
          ...paramsObject,
          sort: 'created_date',
          sortDir: 'desc',
          sfForm: 'Article',
          isPublished: '1',
          onlyActual: '1',
          excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveCMSArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveCMSArticlesError(e));
    }
  };
};
