import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  customerInfoSelector,
  fetchChildUsers,
  childUsersSelector,
  childUsersIsFetchingSelector,
} from './myAccountSlice';
import AccountManagement from '../../components/MyAccount/AccountManagement';
import {
  setBreadCrumbPath,
  BreadCrumbType,
} from '../BreadCrumb/breadCrumbSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  isFetching: boolean;
}

class AccountManagementContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_ACCOUNTS));
      await dispatch(fetchChildUsers());
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public render() {
    const { user, childUsers, isFetching } = this.props;

    return (
      <>
        <MetaTags tags={{ title: 'Moj účet - Správa účtov' }} />
        <AccountManagement
          refreshUsers={this.refreshUsers}
          isFetching={isFetching}
          childUsers={childUsers}
          userId={user && user.id}
        />
      </>
    );
  }

  public refreshUsers = async () => {
    const { dispatch } = this.props;
    try {
      await dispatch(fetchChildUsers(true));
    } catch (exp) {
      // console.log(exp);
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
    childUsers: childUsersSelector(state),
    isFetching: childUsersIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'AccountManagementContainer' })(
    AccountManagementContainer,
  ),
);
