import * as React from 'react';
import { __, __r } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexRowCenterVertical } from 'eshop-defaults';
import { Link } from 'react-router';

const Wrapper = styled(FlexRowCenterVertical)`
  flex-wrap: wrap;
  justify-content: ${({ articleDetail }) =>
    articleDetail ? 'center' : 'initial'};

  ${({ theme }) => theme.mediab.m580`
    justify-content: initial;
  `}
`;

const Icon = styled.img`
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${rem(10)};

  ${({ theme }) => theme.mediab.m580`
    display: none;
  `}
`;

export const Tag = styled(Link)<{ topArticle: boolean; navTags: boolean }>`
  text-decoration: none;
  margin: 0 ${rem(5)} ${rem(5)} 0;
  padding: ${rem(4)} ${rem(8)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ topArticle, theme }) =>
    topArticle ? 'white' : theme.blogColors.primary};
  font-weight: 500;
  background-color: ${({ topArticle, isNav, theme }) =>
    isNav
      ? theme.blogColors.darkGray
      : topArticle
      ? 'rgba(0,0,0,0.16)'
      : theme.blogColors.tagBg};

  pointer-events: ${({ moreTag }) => (moreTag ? 'none' : 'all')};

  &:hover {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  ${({ theme }) => theme.mediab.m580`
    flex-flow: column;
    font-size: ${rem(14)};
  `}
`;

const MoreTag = styled.div<{ topArticle: boolean; navTags: boolean }>`
  text-decoration: none;
  margin: 0 ${rem(5)} ${rem(5)} 0;
  padding: ${rem(4)} ${rem(8)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ topArticle, theme }) =>
    topArticle ? 'white' : theme.blogColors.primary};
  font-weight: 500;
  background-color: ${({ topArticle, isNav, theme }) =>
    isNav
      ? theme.blogColors.darkGray
      : topArticle
      ? 'rgba(0,0,0,0.16)'
      : theme.blogColors.tagBg};

  pointer-events: ${({ moreTag }) => (moreTag ? 'none' : 'all')};
  cursor: pointer;

  &:hover {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  ${({ theme }) => theme.mediab.m580`
  flex-flow: column;
  font-size: ${rem(14)};
`}
`;

interface Props {
  tags: any;
  mobile?: boolean;
  topArticle?: boolean;
  articleDetail?: boolean;
  isNav?: boolean;
  afterTagClick?: any;
}

function ArticleTags({
  tags,
  mobile = false,
  topArticle = false,
  articleDetail = false,
  isNav = false,
  afterTagClick = null,
}: Props) {
  const [showAll, setShowAll] = React.useState(false);

  if (!tags || tags.length === 0) {
    return null;
  }
  if (!showAll && mobile && tags && tags.length > 3) {
    tags = tags.filter((t, i) => i < 3);
    tags.push({ id: 0, name: '...' });
  }

  let tagIcon = '/images/blog_assets/tag_black.svg';
  if (topArticle) {
    tagIcon = '/images/blog_assets/tag_white.svg';
  }

  const onClickShowAll = () => {
    setShowAll(true);
  };

  return (
    <>
      {tags && (
        <Wrapper articleDetail={articleDetail}>
          <Icon
            src={`
          ${tagIcon}`}
            alt="tag-icon"
          />
          {tags.map(tag => (
            <>
              {tag.name !== '...' ? (
                <Tag
                  moreTag={tag.name === '...'}
                  to={
                    tag.name === '...'
                      ? ''
                      : `${__r('routes:tag', '/tag')}/${tag.name.replace(
                          /\//g,
                          '%2F',
                        )}`
                  }
                  topArticle={topArticle}
                  key={`${tag.id}-${tag.name}`}
                  isNav={isNav}
                  onClick={afterTagClick}
                >
                  {tag.name}
                </Tag>
              ) : (
                <MoreTag onClick={onClickShowAll}>{tag.name}</MoreTag>
              )}
            </>
          ))}
        </Wrapper>
      )}
    </>
  );
}

export default ArticleTags;
