export function getObjectProperty(object: object, path: string): any | null {
  if (!path) {
    return object;
  }
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');
  var a = path.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
}

export type ErrorTypeAPI = {
  name?: string;
  description?: string;
  status?: number;
} | null;

export function startFetching(state: object, path: string) {
  const currentState = getObjectProperty(state, path);
  setState(currentState, 'isFetching', true);
}

export function stopFetching(state: object, path: string) {
  const currentState = getObjectProperty(state, path);
  setState(currentState, 'isFetching', false);
}

function setState(state: object, path: string, value: any) {
  if (state) {
    state[path] = value;
  } else {
    // console.log(`wrong path when setting state: path=${path}`);
  }
}
