import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import Actions from '../../components/Actions/Actions';
import * as cookie from 'react-cookies';
import {
  DSI_VAT_VIEW_COOKIE,
  DSI_VOC_VIEW_COOKIE,
} from '../../containers/App/constants';
import {
  actionSelector,
  fetchAction,
  SingleActionItemInterface,
} from './actionSlice';
import { __, __r } from 'react-i18n';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { getCmsMetaTags } from '../../utilities/metatags';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  DsiPagination as Pagination,
  DsiProductList as ProductList,
} from 'eshop-defaults';
import { resolveUserArePricesLoading } from '../../utilities/user';
import { addItemToCart, setAddToCartModalVisibility } from '../Cart/cartSlice';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { prop } from '../../utilities';
import { resolveDomainByHostname } from '../../configureTrans';
import { hostnameSelector } from '../App/selectors';

const MainContent = styled.div`
  max-width: ${rem(1240)};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(30)};
  padding-bottom: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.m580`
     padding: 0 ${rem(16)};
  `}
`;

const NotFoundContainer = styled.div`
  padding-top: ${rem(70)};
  text-align: center;
  font-size: ${rem(18)};
`;

const CmsTitle = styled.h1`
  margin: 0;
  margin-bottom: ${rem(48)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  font-size: ${rem(36)};
  color: ${({ theme }) => theme.blogColors.primary};
  text-align: center;
  line-height: 48px;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  `}
`;

const SliderWrapper = styled.div`
  /* width: 1000px; */
  margin: auto;
  margin-top: ${rem(48)};
  /* @media only screen and (max-width: 1130px) {
    width: 800px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 650px) {
    width: 90vw;
  }
  @media only screen and (max-width: 380px) {
    width: 80vw;
  } */
`;

const Also = styled.h3`
  text-align: left;
  font: 24px/32px Tahoma;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-top: ${rem(36)};

  @media only screen and (max-width: 1130px) {
    padding-left: 30px;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 10px;
  }

  @media only screen and (max-width: 580px) {
    padding-left: 0;
  }
`;

const DateWrapper = styled.div`
  text-align: center;
  font-weight: bold;
`;

const BlueMediumTextP = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface Props {
  dispatch: any;
  lang: string;
  user: any;
  id: number;
  action: SingleActionItemInterface;
  location: any;
  hostname: string;
}

class ActionContainer extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(props) {
    const { dispatch, params, location } = props;
    await dispatch(fetchAction(params.id, prop(location, 'query.offset') || 0));
    return;
  }

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    if (count > 0) {
      await dispatch(addItemToCart(product, count, false, true));
    } else {
      await dispatch(setAddToCartModalVisibility(false, product));
    }
  };

  public render() {
    const { dispatch, action, user, location, hostname } = this.props;
    const offset = prop(location, 'query.offset');
    const isB2B = user && user.b2b;
    const vocMoc = cookie.load(DSI_VOC_VIEW_COOKIE) || 'voc';
    const vatView = cookie.load(DSI_VAT_VIEW_COOKIE) || 'withVat';

    return (
      <>
        <MetaTags
          // tags={{ title: __('Akcia') }}
          tags={{
            ...getCmsMetaTags(prop(action, 'content')),
            title: prop(action, 'title'),
            description: prop(action, 'tidescriptiontle'),
            og_title: prop(action, 'title'),
            og_description: prop(action, 'description'),
            og_image: prop(action, 'imageUrl'),
            og_image_width: 1024,
            og_image_height: 768,
          }}
        />
        <div>
          {action ? (
            <>
              <MainContent>
                <CmsTitle>{action.title}</CmsTitle>
                <DateWrapper>
                  {action.dateFromReadable !== '' && (
                    <>
                      {__('Akcia platná od')}:{action.dateFromReadable}
                      &nbsp;
                    </>
                  )}
                  {action.dateToReadable !== '' && (
                    <>
                      {action.dateFromReadable !== ''
                        ? __('do')
                        : __('Akcia platná do:')}
                      &nbsp;
                      {action.dateToReadable}
                    </>
                  )}
                </DateWrapper>
                <div dangerouslySetInnerHTML={{ __html: action.annotation }} />
                <div dangerouslySetInnerHTML={{ __html: action.description }} />
                <ProductList
                  apiUrl={process.env.REACT_APP_API_BASE_URL as string}
                  isFetching={false}
                  products={action.products}
                  addToCart={this.addToCart}
                  addToRequest={() => {}}
                  visible={true}
                  addToFavorite={() => {}}
                  hasFavourite={false}
                  canOrder={true}
                  showMoc={isB2B ? vocMoc === 'moc' : true}
                  showWithVat={isB2B ? vatView === 'withVat' : true}
                  loadingPrices={resolveUserArePricesLoading(user)}
                  isB2b={isB2B}
                  hasUser={!!user}
                  showStockCount={!!(user && user.b2b && user.originalUser)}
                  domainId={+resolveDomainByHostname(hostname)}
                />
                {/*{action.products && action.products.length > 0 && (*/}
                {/*  <SliderWrapper>*/}
                {/*    <Also>{__('Súvisiace produkty')}</Also>*/}
                {/*    <SliderComponent*/}
                {/*      type="blog-product"*/}
                {/*      slides={action.products}*/}
                {/*    />*/}
                {/*  </SliderWrapper>*/}
                {/*)}*/}

                <Pagination
                  query={`${location.pathname}${
                    location.search ? location.search : '?'
                  }`}
                  totalItems={action.totalProducts}
                  limit={12}
                  offset={offset || 0}
                  isMobile={false}
                />
              </MainContent>
            </>
          ) : (
            <MainContent>
              <NotFoundContainer>
                {__('Pre zobrazenie akcie sa musíte prihlásiť.')}
                <br />
                <br />
                <StyledLink to={__r('routes:prihlasenie', '/prihlasenie')}>
                  <BlueMediumTextP>{`${__('Prihlásenie')}`}</BlueMediumTextP>
                </StyledLink>
              </NotFoundContainer>
            </MainContent>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    user: state.auth.user,
    action: actionSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ActionContainer' })(ActionContainer),
);
