import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { DsiLoginForm as LoginForm } from 'eshop-defaults';
import { withRouter, WithRouterProps } from 'react-router';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { UrlHashService } from '../../services/HashService';
import { onlyPublic } from '../../utilities/auth';
import { cartIdSelector, fetchCart, resetCart } from '../Cart/cartSlice';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { connectSsr } from 'ssr-service';
import { hostnameSelector, langSelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';
import { removeCartCookie } from '../Cart/helpers';
import { prop } from '../../utilities';
import { resolveDomainByHostname } from '../../configureTrans';

interface Props {
  cartId: string | undefined;
  dispatch: (action: any) => void;
  lang: string;
  hostname: string;
}

interface State {
  error: string;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(400)};
  margin: ${rem(32)} auto;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(16)};
     margin: 0 auto;
  `}
`;

class LoginPage extends React.Component<Props & WithRouterProps, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  public componentDidMount() {
    this.props.dispatch(setBreadCrumbPath(BreadCrumbType.LOGIN, null));

    const { hash } = this.props.location;
    const urlHashService = new UrlHashService(hash.replace('#', ''));
    const accessToken = urlHashService.get('access_token');
    const error = decodeURIComponent(urlHashService.get('error'));

    if (accessToken) {
      removeCartCookie();
      this.props.dispatch(
        loginUser(accessToken, false, () =>
          this.props.dispatch(resetCart(false)),
        ),
      );
      this.props.dispatch(resetCart(false));
      // window.location.href = previousUrl;
      // this.props.dispatch(push('/'));
    } else if (error) {
      this.setState({
        error: error && error !== 'null' ? error : '',
      });
    }
  }

  public render() {
    const {
      cartId,
      location: { pathname, search },
      lang,
      location,
      hostname,
    } = this.props;
    const { error } = this.state;
    const fromQueryParam = prop(location, 'query.from');

    return (
      <>
        <Wrapper>
          <MetaTags tags={{ title: __('Prihlásenie') }} />
          <LoginForm
            baseApiUrl={process.env.REACT_APP_API_BASE_URL}
            baseUrl={
              lang === 'sk'
                ? process.env.REACT_APP_BASE_URL
                : process.env.REACT_APP_BASE_URL?.replace('.sk', `.${lang}`)
            }
            cartId={cartId}
            pathname={pathname}
            search={search}
            error={error}
            lang={lang}
            showRegisterSuccess={fromQueryParam === 'register'}
            domainId={+resolveDomainByHostname(hostname)}
          />
        </Wrapper>
      </>
    );
  }

  public handleFacebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/facebook?state="${this.props.cartId}"`;
  };

  public handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/oauth/google?state="${this.props.cartId}"`;
  };
}

const mapStateToProps = state => {
  return {
    cartId: cartIdSelector(state),
    lang: langSelector(state),
    hostname: hostnameSelector(state),
  };
};

const withRouterLoginPage = onlyPublic(LoginPage);
export default connect(mapStateToProps)(withRouter(withRouterLoginPage));
