import { getSuccesfullLoginMsg } from 'eshop-defaults/lib/defaults';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  SET_LANGUAGE,
  SET_CURRENCY,
  SET_DEFAULT_TITLE,
  SET_FOOTER_DATA,
  SET_CONTACT_INFO,
  SET_SOCIAL_URLS,
  SET_META_TAGS,
  REQUEST_REGISTER_USER,
  RECEIVE_REGISTER_USER_SUCCESS,
  RECEIVE_REGISTER_USER_FAILURE,
  REQUEST_LOGIN_USER,
  RECEIVE_LOGIN_USER_SUCCESS,
  RECEIVE_LOGIN_USER_FAILURE,
  OPEN_DIMMER,
  CLOSE_DIMMER,
  REQUEST_CREATED_ORDER,
  RECEIVE_CREATED_ORDER_SUCCESS,
  RECEIVE_CREATED_ORDER_ERROR,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  SET_INVOICE_DATA,
  SET_CONTROL_INSTITUTE_DATA,
  SET_POINTS_AMOUNT_RATIO,
  SET_FREE_DELIVERY_INFO,
  SET_TOP_MENU_BRANDS,
  REQUEST_SEARCH_TERM,
  RECEIVE_SEARCH_TERM_SUCCESS,
  REQUEST_PARTIAL_SEARCH_TERM,
  RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS,
  SET_SEARCH_RESULTS_FILTERS,
  RECEIVE_SITEMAP_SUCCESS,
  REQUEST_PARTIAL_CATEGORY_SEARCH_TERM,
  RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS,
  SET_CURRENT_THEME,
  SET_SETTINGS_LOADED,
  SET_HEADER_DATA,
  SET_HOSTNAME,
  SET_LANGUAGE_CHANGED,
  SET_NOT_FOUND,
  SET_BREADCRUMB_VISIBLE,
  SET_OTHER_TEXTS,
  RECEIVE_BRAND_DETAIL_SUCCESS,
  RECEIVE_BRAND_CATEGORIES_SUCCESS,
  RECEIVE_PRICE_RETAIL_WHOLESALE_DISPLAY_TYPE_SUCCESS,
  RECEIVE_PRICE_VAT_DISPLAY_TYPE_SUCCESS,
  SET_DEFAULT_HOME_TITLE,
  ADMIN_CONTENT_PATH,
} from './constants';

import { REDIRECT } from '@bart.sk-ecommerce/react-eshop-redirects/lib/constants';
import { redirectReducer } from '@bart.sk-ecommerce/react-eshop-redirects/lib/reducer';
import { initialState, resetCart } from '../Cart/cartSlice';
import { RESET_CART } from 'react-auth/lib/containers/Auth/constants';

export const generalInitialState = {
  lang: 'sk',
  langSet: false,
  currency: 'EUR',
  title: 'DSI.sk',
  socialUrls: {
    fbUrl: 'http://facebook.com',
    igUrl: 'http://www.instagram.sk',
    ytUrl: 'http://www.youtube.sk',
  },
  search: {
    isFetching: false,
    results: [],
    offset: 0,
    partialResults: {
      isFetching: false,
      products: null,
      isFetchingCategories: false,
      categories: null,
    },
  },
  register: {
    isFetching: false,
    success: '',
    error: '',
  },
  currentTheme: '',
  areSettingsLoaded: false,
  isBreadcrumbVisible: true,
  otherTexts: {},
  priceDisplay: {
    priceRetailWholesaleDisplayType: null,
    priceVatDisplayType: null,
  },
};

export const generalReducer = (
  state = generalInitialState,
  { type, payload },
) => {
  switch (type) {
    case SET_SETTINGS_LOADED: {
      return {
        ...state,
        areSettingsLoaded: payload.isLoaded,
      };
    }

    case LOCATION_CHANGE:
      const { pathname } = payload;
      // themes are applied only to category and product detail
      const isDefault =
        pathname.includes('kategoria/') || pathname.includes('produkt/')
          ? false
          : true;
      return {
        ...state,
        currentTheme: isDefault ? '' : state.currentTheme,
      };
    case SET_CURRENT_THEME:
      return {
        ...state,
        currentTheme: payload.theme,
      };
    case RECEIVE_SITEMAP_SUCCESS:
      return {
        ...state,
        sitemap: payload.content,
      };
    case SET_FREE_DELIVERY_INFO:
      return {
        ...state,
        freeDeliveryInfo: payload.freeDeliveryInfo,
      };
    case SET_HOSTNAME:
      return {
        ...state,
        hostname: payload.hostname,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: payload.lang,
        langSet: true,
      };
    case SET_LANGUAGE_CHANGED:
      return {
        ...state,
        langChanged: true,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: payload.currency,
      };
    case SET_DEFAULT_TITLE:
      return {
        ...state,
        title: payload.defaultTitle,
      };
    case SET_DEFAULT_HOME_TITLE:
      return {
        ...state,
        homeTitle: payload.defaultHomeTitle,
      };
    case SET_HEADER_DATA:
      return {
        ...state,
        headerData: payload.headerData,
      };
    case SET_TOP_MENU_BRANDS:
      return {
        ...state,
        brands: payload.brands,
      };
    case SET_FOOTER_DATA:
      return {
        ...state,
        footerData: payload.footerData,
      };
    case SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: payload.contactInfo,
      };
    case SET_SOCIAL_URLS:
      return {
        ...state,
        socialUrls: payload.socialUrls,
      };
    case SET_META_TAGS:
      return {
        ...state,
        metaTags: payload.metaTags,
      };
    case OPEN_DIMMER: {
      return {
        ...state,
        dimmerVisible: true,
        lockScrollBar: payload.lockScrollBar,
      };
    }
    case CLOSE_DIMMER: {
      return {
        ...state,
        dimmerVisible: false,
      };
    }
    case OPEN_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: true,
      };
    }

    case CLOSE_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: false,
      };
    }
    case SET_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: payload.invoiceData,
      };
    }
    case SET_CONTROL_INSTITUTE_DATA: {
      return {
        ...state,
        controlInstituteData: payload.controlInstituteData,
      };
    }
    case SET_POINTS_AMOUNT_RATIO: {
      return {
        ...state,
        pointsAmountRatio: parseFloat(payload.pointsAmountRatio),
      };
    }
    case SET_SEARCH_RESULTS_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          offset: payload.filters.offset,
        },
      };
    }
    case REQUEST_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          searchTerm: payload.searchTerm,
          isFetching: true,
        },
      };
    }

    case RECEIVE_SEARCH_TERM_SUCCESS: {
      // if (payload.searchTerm !== state.searchTerm) {
      //   return state;
      // }
      return {
        ...state,
        search: {
          ...state.search,
          isFetching: false,
          results: {
            products: payload.products,
          },
        },
      };
    }
    case REQUEST_PARTIAL_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetching: true,
          },
        },
      };
    }

    case RECEIVE_PARTIAL_SEARCH_TERM_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetching: false,
            products: payload.products.products,
          },
        },
      };
    }

    case REQUEST_PARTIAL_CATEGORY_SEARCH_TERM: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetchingCategories: true,
          },
        },
      };
    }

    case RECEIVE_PARTIAL_CATEGORY_SEARCH_TERM_SUCCESS: {
      return {
        ...state,
        search: {
          ...state.search,
          partialResults: {
            ...state.search.partialResults,
            isFetchingCategories: false,
            categories: payload.products,
          },
        },
      };
    }

    case REQUEST_REGISTER_USER:
      return {
        ...state,
        register: {
          isFetching: true,
          error: '',
          success: '',
        },
      };
    case RECEIVE_REGISTER_USER_SUCCESS:
      return {
        ...state,
        register: {
          success: getSuccesfullLoginMsg(),
          error: '',
          isFetching: false,
        },
      };
    case RECEIVE_REGISTER_USER_FAILURE:
      return {
        ...state,
        register: {
          error: payload.error,
          success: '',
          isFetching: false,
        },
      };

    case SET_NOT_FOUND:
      return {
        ...state,
        notFound: payload.notFound,
      };

    case REDIRECT: {
      return redirectReducer(state, payload);
    }

    case SET_BREADCRUMB_VISIBLE:
      return {
        ...state,
        isBreadcrumbVisible: payload.isVisible,
      };

    case SET_OTHER_TEXTS:
      return {
        ...state,
        otherTexts: payload.otherTexts,
      };
    case RECEIVE_BRAND_DETAIL_SUCCESS:
      return {
        ...state,
        brandDetail: payload.brandDetail,
      };
    case RECEIVE_BRAND_CATEGORIES_SUCCESS:
      return {
        ...state,
        brandCategories: payload.brandCategories,
      };
    case RECEIVE_PRICE_RETAIL_WHOLESALE_DISPLAY_TYPE_SUCCESS: {
      return {
        ...state,
        priceDisplay: {
          ...state.priceDisplay,
          priceRetailWholesaleDisplayType:
            payload.priceRetailWholesaleDisplayType,
        },
      };
    }
    case RECEIVE_PRICE_VAT_DISPLAY_TYPE_SUCCESS: {
      return {
        ...state,
        priceDisplay: {
          ...state.priceDisplay,
          priceVatDisplayType: payload.priceVatDisplayType,
        },
      };
    }
    case ADMIN_CONTENT_PATH: {
      return {
        ...state,
        adminContentPath: payload.adminContentPath,
      };
    }
    default:
      return state;
  }
};

const loginReducerInitialState = {};

export const loginReducer = (state = loginReducerInitialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_LOGIN_USER_SUCCESS:
      return {
        ...state,
        success: getSuccesfullLoginMsg(),
        isFetching: false,
      };
    case RECEIVE_LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const ordersReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CREATED_ORDER: {
      return {
        ...state,
        createdOrder: {
          isFetching: true,
        },
      };
    }

    case RECEIVE_CREATED_ORDER_SUCCESS: {
      return {
        ...state,
        createdOrder: {
          isFetching: false,
          createdOrderData: { ...action.payload.createdOrder },
        },
      };
    }

    case RECEIVE_CREATED_ORDER_ERROR: {
      return {
        ...state,
        createdOrder: {
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      return state;
  }
};
