import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { FlexCol, FlexRowCenter, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
} from 'eshop-defaults';
import { Modal } from '../_helpers/Modal/Modal';

import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { prop } from '../../utilities';

interface Props {
  name: string;
  login?: string;
  onClick: any;
  isParent: boolean;
  address: string;
  parent?: any;
  outerId: number;
}

function AccountSelectItem({
  name,
  login,
  onClick,
  isParent,
  address,
  parent,
  outerId,
}: Props) {
  return (
    <Wrapper onClick={onClick}>
      <ImageNameWrapper>
        <Photo>{prop(name, '0')}</Photo>
        <NameLoginWrapper>
          <Name bold={isParent ? 'bold' : '500'}>
            {name} ({outerId})
          </Name>
          <Address>{address}</Address>
          {login ? (
            <Login>
              {__('login')}: {login}
            </Login>
          ) : null}

          {!!parent && (
            <ParentLoginWrapper>
              <Name bold={isParent ? 'bold' : '500'}>
                {__('Nadradený účet')}:
              </Name>
              <Address>{prop(parent, 'firma') || prop(parent, 'meno')}</Address>
              {prop(parent, 'login') ? (
                <Login>
                  {__('login')}: {prop(parent, 'login')}
                </Login>
              ) : null}
            </ParentLoginWrapper>
          )}
        </NameLoginWrapper>
      </ImageNameWrapper>
      <StyledSvgIcon
        icon={IconType.rightArrowNoFill}
        width={24}
        height={24}
        fill={'#1E398D'}
        viewBox="0 0 24 24"
      />
    </Wrapper>
  );
}

const Wrapper = styled.button`
  cursor: pointer;
  width: 100%;
  max-width: ${rem(352)};
  border-radius: ${rem(4)};
  background-color: rgba(30, 57, 141, 0.04);
  padding: ${rem(16)};
  display: flex;
  flex-flow: row;
  align-items: center;
  border: 0;
  margin-bottom: ${rem(4)};
  margin-right: ${rem(4)};
`;

const ImageNameWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
`;

const NameLoginWrapper = styled(FlexCol)``;

const ParentLoginWrapper = styled(NameLoginWrapper)`
  margin-top: ${rem(16)};
`;

const Photo = styled(FlexRowCenter)`
  width: ${rem(48)};
  height: ${rem(48)};
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${rem(24)};
  margin-right: ${rem(16)};
  color: white;
  font-weight: 700;
  font-size: ${rem(20)};
`;

const Name = styled(TextP)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  font-weight: ${props => props.bold || '500'};
`;

const Address = styled(TextP)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  font-size: ${rem(12)};
`;

const Login = styled(TextP)`
  font-size: ${rem(12)};
  color: rgba(65, 65, 65, 0.56);
  text-align: left;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
  transform: scale(1.5);
`;

export default AccountSelectItem;
