import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { MetaTags } from 'eshop-defaults';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  backOrdersSelector,
  backOrdersIsFetchingSelector,
  fetchBackorders,
  fetchCustomerFinancialDetails,
  fetchBrandList,
  brandsSelector,
} from './myAccountSlice';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import MyBackorder from '../../components/MyAccount/MyBackorder';
import { __ } from 'react-i18n';
import { currencySelector } from '../App/selectors';
import { productCategoriesSelector } from '../Header/selectors';
import { prop } from '../../utilities';

const LIMIT = 5;

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  backorders: any;
  currency: string;
  brands: any;
  categories: any;
}

interface State {
  currentId: string;
}

class BackorderContainer extends React.Component<Props, State> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_BACKORDER));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        // await dispatch(fetchOrders(null, LIMIT, 0)),
        await dispatch(fetchBackorders({})),
        await dispatch(fetchBrandList()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: '',
    };
  }

  public refetchData = async ({
    brandId,
    categId,
    userOuterId,
    limit,
    reserved,
  }) => {
    const { currentId } = this.state;

    await this.props.dispatch(
      fetchBackorders({
        offset: 0,
        brandId,
        categId,
        userOuterId: userOuterId || currentId,
        limit,
        reserved,
      }),
    );
  };

  public render() {
    const {
      user,
      ordersById,
      ordersData,
      childUsers,
      isFetchingOrders,
      backorders,
      currency,
      brands,
      categories,
    } = this.props;
    const { currentId } = this.state;
    const isChild = !!(childUsers && childUsers.find(c => user.id === c.id));
    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: user.outer_id,
          }))
          .map(a => a)
      : [];
    const options = [
      { name: __('Všetky pobočky'), value: '' },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Backorder') }} />
        <MyBackorder
          currency={currency}
          orders={backorders}
          ordersData={ordersData}
          options={options}
          currentId={currentId}
          handleOffsetChange={this.handleOffsetChange}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrders}
          brands={brands}
          categories={categories}
          refetchData={this.refetchData}
          user={user}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const { backorders } = this.props;
    const id = e.target.value;
    this.setState({ currentId: id });

    this.props.dispatch(
      fetchBackorders({
        ...prop(backorders, 'activeFilters'),
        brandId: prop(backorders, 'activeFilters.brand_id'),
        categId: prop(backorders, 'activeFilters.category_id'),
        offset: prop(backorders, 'activeFilters.offset') || 0,
        userOuterId: id,
      }),
    );
  };

  public handleOffsetChange = async e => {
    const { backorders } = this.props;
    const { currentId } = this.state;

    const num = parseInt(e.target.text, 10) - 1;

    const offset = prop(backorders, 'pagination.limit') * num;
    await this.props.dispatch(
      fetchBackorders({
        ...prop(backorders, 'activeFilters'),
        brandId: prop(backorders, 'activeFilters.brand_id'),
        categId: prop(backorders, 'activeFilters.category_id'),
        offset,
        userOuterId: currentId,
      }),
    );
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: backOrdersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
    backorders: backOrdersSelector(state),
    currency: currencySelector(state),
    brands: brandsSelector(state),
    categories: productCategoriesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'BackorderContainer' })(BackorderContainer),
);
