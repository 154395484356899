import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import Actions from '../../components/Actions/Actions';
import {
  ActionItemInterface,
  actionsSelector,
  fetchActions,
} from './actionsSlice';
import { langChangedSelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';

export const MainWrapper = styled.div`
  padding: ${rem(30)} 0 0;
  width: 100%;

  ${({ theme }) => theme.mediab.m810`
     padding-top: ${rem(16)};
  `}
`;

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`;

interface Props {
  dispatch: any;
  lang: string;
  actions: Array<ActionItemInterface>;
  langChanged: boolean;
}

class ActionsContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    dispatch(fetchActions());

    return {};
  }

  public render() {
    const { dispatch, actions, lang, langChanged } = this.props;
    return (
      <>
        <MetaTags tags={{ title: __('Akcie') }} />
        <Actions
          actions={actions}
          dispatch={dispatch}
          lang={lang}
          langChanged={langChanged}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    actions: actionsSelector(state),
    langChanged: langChangedSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ActionsContainer' })(ActionsContainer),
);
