import { __r } from 'react-i18n';
import { prop } from 'eshop-defaults';

export enum cartRoutes {
  INDEX = 'routes:kosik',
  SECOND_STEP = 'routes:kosik/osobne-udaje',
  THIRD_STEP = 'routes:cart/dodanie-a-platba',
}

export const cartIndexRoute = __r('routes:kosik', '/kosik');
export const cartSecondStepRoute = __r(
  'routes:kosik/osobne-udaje',
  '/kosik/osobne-udaje',
);
export const cartThirdStepRoute = __r(
  'routes:cart/dodanie-a-platba',
  '/kosik/dodanie-a-platba',
);

export function getGoodProductId(
  product: any,
): { goodId: number; productId: number } {
  const goodId = prop(product, 'main_good.good_id');
  const productId = prop(product, 'product_id');
  return { goodId, productId };
}
