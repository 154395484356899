export const resolveUserArePricesLoading = (user: any): boolean => {
  if (!user) {
    return false;
  }
  const { pricesUpdatedAt, updatingPrices } = user;

  // first update
  if (updatingPrices && !pricesUpdatedAt) {
    return true;
  }
  return pricesUpdatedAt && updatingPrices && updatingPrices > pricesUpdatedAt;
};
