import API from './services/API';
import * as cookies from 'react-cookies';
import { LANG_COOKIE } from './containers/App/constants';
import { prop } from './utilities';

export async function loadTranslations(location) {
  return API.loadLangTranslation(
    resolveLangByHostname(prop(location, 'hostname')),
  );
}

export function resolveLangFromCookies(hostname: string): string {
  return cookies.load(LANG_COOKIE) || 'sk';
}

export function resolveLangByHostname(hostname: string): string {
  /*
  if (process.env.NODE_ENV === 'development') {
    return 'sk';
  }
  */

  if (hostname && hostname.includes('cz')) {
    return 'cz';
  } else {
    return 'sk';
  }
}

export function resolveCurrencyByHostname(hostname: string): string {
  /*
  if (process.env.NODE_ENV === 'development') {
    return 'EUR';
  }
  */

  if (hostname && hostname.includes('cz')) {
    return 'CZK';
  } else {
    return 'EUR';
  }
}

export function resolveCurrencySignFromLang(lang: string): string {
  if (lang === 'cz') {
    return 'Kč';
  } else {
    return '€';
  }
}

export const DEFAULT_SITEMAP_ID = {
  'beta.edsi.sk': { sk: 471, cz: 473 },
  'beta.edsi.cz': { sk: 471, cz: 473 },
  'edsi.sk': { sk: 471, cz: 473 },
  'www.edsi.sk': { sk: 471, cz: 473 },
  'edsi.cz': { sk: 471, cz: 473 },
  'www.edsi.cz': { sk: 471, cz: 473 },
  localhost: { sk: 471, cz: 473 },
  'localhost:7000': { sk: 471, cz: 473 },
};

export const DEFAULT_CATEGORY_ID = {
  'beta.edsi.sk': { sk: 75759, cz: 75759 },
  'beta.edsi.cz': { sk: 75759, cz: 75759 },
  'www.edsi.sk': { sk: 75759, cz: 75759 },
  'edsi.sk': { sk: 75759, cz: 75759 },
  'edsi.cz': { sk: 75759, cz: 75759 },
  'www.edsi.cz': { sk: 75759, cz: 75759 },
  localhost: { sk: 75759, cz: 75759 },
  'localhost:7000': { sk: 75759, cz: 75759 },
};

export function resolveBaseUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }
  // we are returning other url - if lang is cz we return sk url
  if (lang === 'cz') {
    return process.env.REACT_APP_LANG_OTHER_VERSION as string;
  } else {
    return process.env.REACT_APP_BASE_URL as string;
  }
}

export function resolveOtherBaseUrlByLang(lang?: string): string {
  // default is base url
  if (!lang) {
    return process.env.REACT_APP_BASE_URL as string;
  }
  // we are returning other url - if lang is cz we return sk url
  if (lang === 'cz') {
    return process.env.REACT_APP_BASE_URL as string;
  } else {
    return process.env.REACT_APP_LANG_OTHER_VERSION as string;
  }
}

export function getOtherLang(lang: string): string {
  if (lang === 'cz') {
    return 'sk';
  } else {
    return 'cz';
  }
}

export function resolveTagByHostname(hostname: string): string {
  /*
  if (process.env.NODE_ENV === 'development') {
    return 'EDSI';
  }
  */

  if (hostname.includes('edsi')) {
    return 'EDSI';
  } else {
    return 'EDSI';
  }
}

export function resolveDomainByHostname(hostname: string): string {
  /*
  if (process.env.NODE_ENV === 'development') {
    return '4';
  }
  */

  if (hostname.includes('edsi')) {
    return '4';
  } else {
    return '4';
  }
}

export function extractBaseDomain(url: string): string {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im;
  const match = url.match(domainRegex);

  if (match && match.length >= 2) {
    const domain = match[1];
    const parts = domain.split('.').slice(-2); // Extract last two parts of the domain

    // Check if the domain is "localhost"
    if (domain === 'localhost' || domain.endsWith('.localhost')) {
      return 'localhost'; // Return "localhost" for localhost URLs
    }

    // Check if the first part is "www" or "beta"
    if (parts[0] === 'www' || parts[0] === 'beta') {
      return domain; // Return the original domain if "www" or "beta" is in the first part
    }

    return parts.join('.');
  }

  return ''; // Return null if the base domain extraction fails
}

export function extractRustPart(url: string): string | null {
  const parts = url.split('.');
  if (parts.length === 2 && parts[1] === 'localhost') {
    return parts[0];
  }

  if (parts.length === 3) {
    if (parts[0] === 'www' || parts[0] === 'beta') {
      return null;
    } else {
      return parts[0];
    }
  }

  return null;
}
