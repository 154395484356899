import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { formatPrice } from '../../utilities';

interface Props {
  type: string;
  handleClose: () => void;
  productName: string;
  picture?: string;
  price?: string;
  currency?: string;
  plu?: string;
}

function AddTo(props) {
  const {
    type,
    handleClose,
    productName,
    picture = '/images/icons/placeholder.svg',
    price = '',
    currency = '',
    plu,
  } = props;
  const image = picture;
  const title =
    type === 'cart'
      ? __('Produkt bol pridaný do košíka')
      : __('Produkt bol pridaný k obľúbeným');
  const view = type === 'cart' ? __('košík') : __('obľúbené');

  return (
    <>
      <Wrapper>
        <Heading>
          <Title>{`${title}`}</Title>
          <IconWrapper onClick={handleClose}>
            <StyledSvgIcon
              icon={IconType.close}
              width={24}
              height={24}
              fill={'#414141'}
            />
          </IconWrapper>
        </Heading>
        <ProductWrapper>
          <FlexRowCenterVertical>
            <ProductImg
              src={
                image && image !== '' ? image : '/images/icons/placeholder.svg'
              }
              alt={__('Obrázok produktu')}
              width={56}
              height={56}
            />
            <FlexCol>
              <Name>{`${productName}`}</Name>
              <Plu>{plu}</Plu>
            </FlexCol>
          </FlexRowCenterVertical>
          {!!price && <Price>{formatPrice(price, currency)}</Price>}
        </ProductWrapper>
        <Buttons>
          <CloseButton onClick={handleClose}>{__('Zavrieť')}</CloseButton>
          <StyledLink
            to={
              type === 'cart'
                ? __r('routes:kosik', '/kosik')
                : __r('routes:moj-ucet/oblubene', '/moj-ucet/oblubene')
            }
            onClick={handleClose}
          >
            <StyledButton>{`${__('Zobraziť')} ${view}`}</StyledButton>
          </StyledLink>
        </Buttons>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 500;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};
  justify-content: space-between;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  flex-flow: row wrap;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
    margin-bottom:${rem(16)};
  `}
`;

const CloseButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const Plu = styled(Name)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.inactiveFontColor};
`;

const Price = styled(Name)`
  min-width: ${rem(56)};
  text-align: right;
  margin-left: ${rem(8)};
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
  `}
`;

export { AddTo };
