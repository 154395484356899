import {
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  REQUEST_HOME_PRODUCT_LIST2,
  RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  REQUEST_HOME_PRODUCT_LIST1,
  RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  REQUEST_HOME_CATEGORIES,
  RECEIVE_HOME_CATEGORIES_ERROR,
  RECEIVE_HOME_CATEGORIES_SUCCESS,
  REQUEST_REVIEWS,
  RECEIVE_REVIEWS_SUCCESS,
  RECEIVE_REVIEWS_ERROR,
  REQUEST_THEMES,
  RECEIVE_THEMES_SUCCESS,
  RECEIVE_THEMES_ERROR,
  REQUEST_BLOG_ARTICLES,
  RECEIVE_BLOG_ARTICLES_SUCCESS,
  RECEIVE_BLOG_ARTICLES_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { hostnameSelector, langSelector } from '../App/selectors';
import { productList1Selector, productList2Selector } from './selectors';
import { DEFAULT_CATEGORY_ID, extractBaseDomain } from '../../configureTrans';

const requestHomeProductList1 = () => ({
  type: REQUEST_HOME_PRODUCT_LIST1,
});

const receiveHomeProductList1Success = (products, categoryId: string) => ({
  type: RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeProductList1Error = error => ({
  type: RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  payload: {
    error,
  },
});

export const loadHomeProductList1 = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = productList1Selector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        dispatch(requestHomeProductList1());
        const products = await API.loadProductList('ACTION_FIRM');
        dispatch(receiveHomeProductList1Success(products, categoryId));
      }
    } catch (e) {
      // console.log({ error: e });
      dispatch(receiveHomeProductList1Error(e));
    }
  };
};

const requestHomeProductList2 = () => ({
  type: REQUEST_HOME_PRODUCT_LIST2,
});

const receiveHomeProductList2Success = (products, categoryId: string) => ({
  type: RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeProductList2Error = error => ({
  type: RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  payload: {
    error,
  },
});

export const loadHomeProductList2 = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = productList2Selector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        dispatch(requestHomeProductList2());
        const products = await API.loadProductList('ACTION_PACK');
        dispatch(receiveHomeProductList2Success(products, categoryId));
      }
    } catch (e) {
      // console.log({ error: e });
      dispatch(receiveHomeProductList2Error(e));
    }
  };
};

const requestFavoriteCategories = () => ({
  type: REQUEST_HOME_CATEGORIES,
});

const receiveFavoriteCategoriesSuccess = homeCategories => ({
  type: RECEIVE_HOME_CATEGORIES_SUCCESS,
  payload: {
    homeCategories,
  },
});

const receiveFavoriteCategoriesError = error => ({
  type: RECEIVE_HOME_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadFavoriteCategories = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestFavoriteCategories());
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const homeCategories = await API.loadCategories(
        {
          isFavorite: '1',
          categoryTopParentId:
            DEFAULT_CATEGORY_ID[extractBaseDomain(hostname)][lang],
          withPublish: '1',
          publishLang: lang,
        },
        // we use slovak language here explicitly - there is no tree in other lang versions
        { xAcceptLanguage: 'sk' },
      );
      if (homeCategories && homeCategories[0]) {
        dispatch(receiveFavoriteCategoriesSuccess(homeCategories));
      }
    } catch (e) {
      // console.log({ e });
      dispatch(receiveFavoriteCategoriesError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await API.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await API.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit: 3,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeFastNewsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticle => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticle,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticle = await API.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
          onlyActual: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopArticlesSuccess(topArticle));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const banners = await API.loadBanners(
        { position: 'EDSI' },
        { xAcceptLanguage: lang },
      );
      const banner = prop(banners, 'banners');
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      // console.log(e);
    }
  };
};

const receiveHomeTopBrandsSuccess = topBrand => ({
  type: RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  payload: {
    topBrand,
  },
});

export const loadTopBrands = () => {
  return async (dispatch, getState, API) => {
    try {
      const brands = await API.loadBrands({ onlyTop: '1' });
      const topBrand = prop(brands, 'brands');
      if (topBrand && topBrand.length > 0) {
        dispatch(receiveHomeTopBrandsSuccess(topBrand));
      } else {
        dispatch(receiveHomeTopBrandsSuccess(null));
      }
    } catch (e) {
      // console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const ebooks = await API.loadArticles(
        {
          limit: 3,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeEbooksSuccess(ebooks));
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};

const requestReviews = () => ({
  type: REQUEST_REVIEWS,
});

const receiveReviewsSuccess = reviews => ({
  type: RECEIVE_REVIEWS_SUCCESS,
  payload: {
    reviews,
  },
});

const receiveReviewsError = error => ({
  type: RECEIVE_REVIEWS_ERROR,
  payload: {
    error,
  },
});

export const loadReviews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestReviews());
      const lang = langSelector(getState());
      const reviews = await API.loadArticles(
        {
          limit: 2,
          sitemapUniqueId: 'RADCA',
          isPublished: '1',
          withoutStats: '1',
          onlyActual: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveReviewsSuccess(reviews));
    } catch (e) {
      dispatch(receiveReviewsError(e));
    }
  };
};

const requestBlogArticles = () => ({
  type: REQUEST_BLOG_ARTICLES,
});

const receiveBlogArticlesSuccess = articles => ({
  type: RECEIVE_BLOG_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveBlogArticlesError = error => ({
  type: RECEIVE_BLOG_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadBlogArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestBlogArticles());
      const lang = langSelector(getState());
      const reviews = await API.loadArticles(
        {
          sort: 'created_date',
          sortDir: 'desc',
          limit: 18,
          sfForm: 'Article',
          isPublished: '1',
          withoutStats: '1',
          onlyActual: '1',
          withoutJsonContent: '1',
          excludeSitemapUniqueId: ['JBL_CATEGORY_TEXTS'],
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveBlogArticlesSuccess(reviews));
    } catch (e) {
      dispatch(receiveBlogArticlesError(e));
    }
  };
};

const requestThemes = () => ({
  type: REQUEST_THEMES,
});

const receiveThemesSuccess = themes => ({
  type: RECEIVE_THEMES_SUCCESS,
  payload: {
    themes,
  },
});

const receiveThemesError = error => ({
  type: RECEIVE_THEMES_ERROR,
  payload: {
    error,
  },
});

export const loadThemes = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestThemes());
      const lang = langSelector(getState());
      const themes = await API.loadArticles(
        {
          limit: 2,
          sitemapUniqueId: 'TEMATA',
          isPublished: '1',
          withoutStats: '1',
          onlyActual: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveThemesSuccess(themes));
    } catch (e) {
      dispatch(receiveThemesError(e));
    }
  };
};
