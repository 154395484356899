import { css, createGlobalStyle } from 'styled-components';
import { rem } from 'polished';
import { InputSlider } from './libs/InputSlider';
import { theme } from './theme';

export const GlobalStyles = createGlobalStyle`
  html {
    background: ${theme.blogColors.background};
    color: ${theme.blogColors.gray};
    font-size: 1em;
    font-family: ${theme.font.secondary};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
    margin: 0;
    padding: 0;
    line-height: 20px;
  }
  body {
      max-width: 100%;
      -webkit-overflow-scrolling: touch;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height:auto;
    vertical-align: middle;

    // hides alt text
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  video {
      max-width: 95%;
      height: auto;
  }
  a {
    color: ${theme.color.link};
    cursor: pointer;
    text-decoration: none;

    /* &:hover {
      text-decoration: underline;
    } */
  }

  /* * {
    border: 1px solid red;
  } */
  
  h1{
    padding: 0;
    margin: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: ${theme.font.primary};
    color: ${theme.colors.textPrimary}
  }

  /* button {
      &:focus {
          outline: none;
      }
  } */

  p {
      line-height: 20px;
  }

  input[type="password"] {
    font-family: caption;
  }

  #root {
      display: flex;
      flex-flow: column;
      min-height: 100vh;
  }
  .form-group {
    margin-bottom: ${rem(15)};
  }
  .input-with-text {
    display: flex;
    align-items: center;
  }
  .input-with-text__text {
    display: block;
    font-family: ${theme.font.secondary};
    margin-left: ${rem(10)};
  }
  .container {
    padding-right: ${rem(theme.grid.padding / 2)};
    padding-left: ${rem(theme.grid.padding / 2)};
    margin: 0 auto;
  }
  .container--base {
    max-width: ${rem(theme.grid.container.base)};
  }
  .container--wide {
    max-width: ${rem(theme.grid.container.wide)};
  }
  .row {
    margin-right: ${rem(-theme.grid.padding / 2)};
    margin-left: ${rem(-theme.grid.padding / 2)};
  }
  .col {
    float: left;
    min-height: ${rem(1)};
    width: 100%;
    padding-right: ${rem(theme.grid.padding / 2)};
    padding-left: ${rem(theme.grid.padding / 2)};
  }
  .sliderCenteredDefault{
    margin: 0 auto!important;
  }
  .sliderCentered{
    margin: 0 auto!important;
    position: relative!important;
  }
  .clearfix,
  .container,
  .row {
    &:before,
    &:after {
      content: " ";
      display: table;
    }
    &:after {
       clear: both;
    }
  }

  .st-inline-share-buttons {
    z-index: 10 !important;
  }

  ${InputSlider};

  ${() => theme.mediab.l925`
     .container {
       padding-left: ${rem(16)};
       padding-right: ${rem(16)};
     }
  `}
`;

export const TitlePlaceholder = css`
  font-family: ${theme.font.secondary};
  font-size: ${rem(32)};
  font-weight: 700;
  text-align: center;
`;
