import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  ordersIsFetchingSelector,
  fetchOrders,
  ordersByIdSelector,
  fetchChildUsers,
  ordersDataSelector,
  childUsersSelector,
  orderedProductsDataSelector,
  orderedProductsIsFetchingSelector,
  fetchOrderedProducts,
  fetchCustomerFinancialDetails,
  fetchCustomerDetails,
  fetchBrandList,
  brandsSelector,
} from './myAccountSlice';
import MyPurchase from '../../components/MyAccount/MyPurchase';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';
import { addItemToCart, setAddToCartModalVisibility } from '../Cart/cartSlice';
import { headerDataSelector } from '../App/selectors';
import { productCategoriesSelector } from '../Header/selectors';
import { prop } from '../../utilities';

interface Props {
  user: any;
  dispatch: any;
  isFetchingOrders: boolean;
  ordersById: object;
  ordersData: any;
  // childUsers: ThenArg<typeof API.loadChildUsers>['customers'];
  childUsers: any;
  orderedProductsData: any;
  isFetchingOrderedProducts: boolean;
  brands?: any;
  categories?: any;
}

interface State {
  currentId: string;
}

class MyPurchaseContainer extends React.Component<Props, State> {
  private loadResultsTimeout;

  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_PURCHASE));
      await Promise.all([
        await dispatch(fetchChildUsers()),
        await dispatch(fetchOrderedProducts({})),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchBrandList()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      currentId: '',
    };
  }

  public refetchData = async ({
    brandId,
    categId,
    fromDate,
    toDate,
    limit,
  }) => {
    const { currentId } = this.state;
    if (this.loadResultsTimeout) {
      clearTimeout(this.loadResultsTimeout);
    }

    this.loadResultsTimeout = setTimeout(async () => {
      await this.props.dispatch(
        fetchOrderedProducts({
          brandId,
          categId,
          fromDate,
          toDate,
          offset: 0,
          userOuterId: currentId,
          limit,
        }),
      );
    }, 1000);
  };

  public render() {
    const {
      user,
      orderedProductsData,
      isFetchingOrderedProducts,
      brands,
      categories,
      dispatch,
      childUsers,
    } = this.props;
    const { currentId } = this.state;
    const isChild = !!(childUsers && childUsers.find(c => user.id === c.id));
    const childUserOptions = childUsers
      ? childUsers
          .map(user => ({
            name: `${user.meno} ${user.priezvisko}`,
            value: user.outer_id,
          }))
          .map(a => a)
      : [];
    const options = [
      { name: __('Všetky pobočky'), value: '' },
      ...childUserOptions,
    ];

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Moje nákupy') }} />
        <MyPurchase
          orders={orderedProductsData}
          options={options}
          currentId={currentId}
          handleSelectChange={this.handleSelectChange}
          isFetchingOrders={isFetchingOrderedProducts}
          handleAddToCart={this.addToCart}
          brands={brands}
          categories={categories}
          refetchData={this.refetchData}
          handleOffsetChange={this.handleOffsetChange}
          user={user}
        />
      </>
    );
  }

  public handleSelectChange = e => {
    const id = e.target.value;
    this.setState({ currentId: id });
    const { orderedProductsData, dispatch } = this.props;

    dispatch(
      fetchOrderedProducts({
        ...prop(orderedProductsData, 'activeFilters'),
        brandId: prop(orderedProductsData, 'activeFilters.brand_id'),
        categId: prop(orderedProductsData, 'activeFilters.category_id'),
        fromDate: prop(orderedProductsData, 'activeFilters.filter_from'),
        toDate: prop(orderedProductsData, 'activeFilters.filter_to'),
        offset: prop(orderedProductsData, 'activeFilters.offset') || 0,
        userOuterId: id,
      }),
    );
  };

  public handleOffsetChange = async e => {
    const { orderedProductsData } = this.props;
    const { currentId } = this.state;
    const num = parseInt(e.target.text, 10) - 1;

    const offset = prop(orderedProductsData, 'pagination.limit') * num;
    await this.props.dispatch(
      fetchOrderedProducts({
        ...prop(orderedProductsData, 'activeFilters'),
        brandId: prop(orderedProductsData, 'activeFilters.brand_id'),
        categId: prop(orderedProductsData, 'activeFilters.category_id'),
        fromDate: prop(orderedProductsData, 'activeFilters.filter_from'),
        toDate: prop(orderedProductsData, 'activeFilters.filter_to'),
        offset,
        userOuterId: currentId,
      }),
    );
  };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    if (count > 0) {
      await dispatch(addItemToCart(product, count, false, true));
    } else {
      await dispatch(setAddToCartModalVisibility(false, product));
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isFetchingOrders: ordersIsFetchingSelector(state),
    ordersById: ordersByIdSelector(state),
    ordersData: ordersDataSelector(state),
    childUsers: childUsersSelector(state),
    orderedProductsData: orderedProductsDataSelector(state),
    isFetchingOrderedProducts: orderedProductsIsFetchingSelector(state),
    brands: brandsSelector(state),
    categories: productCategoriesSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyPurchaseContainer' })(MyPurchaseContainer),
);
