import { currencySelector } from './../App/selectors';
import { langSelector } from './../Category/selectors';
import { ErrorTypeAPI, getObjectProperty } from './../../utilities/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import APIClass, { ThenArg } from '../../services/API';
import { createSelector } from 'reselect';
import { prop } from '../../utilities';
import { userSelector } from '../App/selectors';
import { AppThunk } from '../../rootReducer';
import moment from 'moment';

interface MyAccountState {
  userInfo: {
    isFetching: boolean;
    data: ThenArg<typeof APIClass.getCustomerDetails> | null;
    financialData: ThenArg<typeof APIClass.getCustomerDetails> | null;
    addresses: ThenArg<typeof APIClass.getBillingAddresses> | null;
    error?: ErrorTypeAPI;
    deliveryAddresses: {
      delivery_addresses: ThenArg<typeof APIClass.getDeliveryAddresses>;
      isFetching: boolean;
      error?: ErrorTypeAPI;
    };
    childUsers: {
      isFetching: boolean;
      // child_users: ThenArg<typeof APIClass.loadChildUsers>['customers'] | null;
      child_users: any | null;
      error?: ErrorTypeAPI;
    };
    orders: {
      isFetching: boolean;
      orders_by_id: {};
      limit?: number | null;
      offset?: number | null;
      total?: number | null;
      error?: ErrorTypeAPI;
      orders_ids: string[];
      orders: any;
    };
    invoices: {
      isFetching: boolean;
      invoices: any[];
      error?: ErrorTypeAPI;
      unpaidInvoices: any[];
    };
    brands: {
      isFetching: boolean;
      brands: any[];
      error?: ErrorTypeAPI;
    };
    backOrders: {
      isFetching: boolean;
      backOrders: any[];
      error?: ErrorTypeAPI;
    };
    orderedProducts: {
      // orderedProducts: ThenArg<typeof APIClass.loadOrderedProducts>['products'];
      isFetching: boolean;
      orderedProducts: any;
      error?: ErrorTypeAPI;
    };
    finishedOrder: {
      isFetching: boolean;
      data: ThenArg<typeof APIClass.getOrder> | null;
      error?: ErrorTypeAPI;
    };
    orderBody: {
      isFetching: boolean;
      data: ThenArg<typeof APIClass.getOrderBodyHtml> | null;
      error?: ErrorTypeAPI;
    };
    requestDetail: {
      isFetching: boolean;
      data: ThenArg<typeof APIClass.getOrder> | null;
      error?: ErrorTypeAPI;
    };
    requests: {
      isFetching: boolean;
      requests_by_id: {};
      limit?: number | null;
      offset?: number | null;
      total?: number | null;
      error?: ErrorTypeAPI;
      requests_ids: string[];
    };
    favorites: {
      isFetching: boolean;
      favorites_by_id: {};
      limit?: number | null;
      offset?: number | null;
      total?: number | null;
      error?: ErrorTypeAPI;
      favorites_ids: string[];
    };
  };
}

const initialState: MyAccountState = {
  userInfo: {
    isFetching: false,
    data: null,
    financialData: null,
    addresses: [],
    deliveryAddresses: {
      delivery_addresses: [],
      isFetching: false,
    },
    childUsers: {
      isFetching: false,
      child_users: null,
    },
    orders: {
      isFetching: false,
      orders_by_id: {},
      orders_ids: [],
      orders: [],
    },
    invoices: {
      isFetching: true,
      invoices: [],
      unpaidInvoices: [],
    },
    brands: {
      isFetching: true,
      brands: [],
    },
    backOrders: {
      isFetching: false,
      backOrders: [],
    },
    orderedProducts: {
      orderedProducts: [],
      isFetching: false,
    },
    finishedOrder: {
      isFetching: false,
      data: null,
    },
    orderBody: {
      isFetching: false,
      data: null,
    },
    requestDetail: {
      isFetching: false,
      data: null,
    },
    requests: {
      isFetching: false,
      requests_by_id: {},
      requests_ids: [],
    },
    favorites: {
      isFetching: false,
      favorites_by_id: {},
      favorites_ids: [],
    },
  },
};

function startLoading(state, path) {
  const currentState = getObjectProperty(state, path);
  currentState.isFetching = true;
}

function stopLoading(state, path) {
  const currentState = getObjectProperty(state, path);
  currentState.isFetching = false;
}

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    startLoadingBillingAddresses(state) {
      startLoading(state, 'userInfo');
    },
    fetchBillingAddressesSuccess(
      state,
      action: PayloadAction<{
        addresses: ThenArg<typeof APIClass.getBillingAddresses>;
      }>,
    ) {
      state.userInfo.addresses = action.payload.addresses;
      stopLoading(state, 'userInfo');
    },
    fetchBillingAddressesError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.addresses = null;
      state.userInfo.error = action.payload;
      stopLoading(state, 'userInfo');
    },

    startLoadingCustomerInfo(state) {
      startLoading(state, 'userInfo');
    },
    fetchCustomerInfoSuccess(
      state,
      action: PayloadAction<{
        customer: ThenArg<typeof APIClass.getCustomerDetails>;
      }>,
    ) {
      state.userInfo.data = action.payload.customer;
      stopLoading(state, 'userInfo');
    },
    fetchCustomerInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.data = null;
      state.userInfo.error = action.payload;
      stopLoading(state, 'userInfo');
    },

    startLoadingCustomerFinancialInfo(state) {
      // startLoading(state, 'userFinancialInfo');
    },
    fetchCustomerFinancialInfoSuccess(
      state,
      action: PayloadAction<{
        customer: ThenArg<typeof APIClass.getCustomerDetails>;
      }>,
    ) {
      state.userInfo.financialData = action.payload.customer;
      // stopLoading(state, 'financialData');
    },
    fetchCustomerFinancialInfoError(
      state,
      action: PayloadAction<ErrorTypeAPI>,
    ) {
      state.userInfo.financialData = null;
      state.userInfo.error = action.payload;
      // stopLoading(state, 'financialData');
    },

    startLoadingDeliveryAddresses(state) {
      startLoading(state, 'userInfo.deliveryAddresses');
    },
    fetchDeliveryAddressesSuccess(
      state,
      action: PayloadAction<{
        addresses: ThenArg<typeof APIClass.getDeliveryAddresses>;
      }>,
    ) {
      state.userInfo.deliveryAddresses.delivery_addresses =
        action.payload.addresses;
      stopLoading(state, 'userInfo.deliveryAddresses');
    },
    fetchDeliveryAddressesError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.deliveryAddresses.delivery_addresses = [];
      state.userInfo.deliveryAddresses.error = action.payload;
      stopLoading(state, 'userInfo.deliveryAddresses');
    },

    startLoadingChildUsers(state) {
      startLoading(state, 'userInfo.childUsers');
    },
    fetchChildUsersSuccess(
      state,
      action: PayloadAction<{
        // customers: ThenArg<typeof APIClass.loadChildUsers>['customers'];
        customers: any;
      }>,
    ) {
      state.userInfo.childUsers.child_users = action.payload.customers;
      stopLoading(state, 'userInfo.childUsers');
    },
    fetchChildUsersError(state, action: PayloadAction<ErrorTypeAPI>) {
      state.userInfo.childUsers.child_users = null;
      state.userInfo.childUsers.error = action.payload;
      stopLoading(state, 'userInfo.childUsers');
    },

    startLoadingOrders(state) {
      startLoading(state, 'userInfo.orders');
      const ordersState = state.userInfo.orders;
      ordersState.limit = null;
      ordersState.offset = null;
      ordersState.total = null;
    },
    fetchOrdersSuccess(state, action: PayloadAction<any>) {
      const { orders, limit, offset, total } = action.payload;
      const ordersState = state.userInfo.orders;

      ordersState.limit = limit;
      ordersState.offset = offset;
      ordersState.total = total;
      ordersState.error = null;
      ordersState.orders_by_id[orders[0].club_user_id] = orders;
      ordersState.orders = {
        orders: action.payload.orders,
        pagination: action.payload.pagination,
        activeFilters: action.payload.activeFilters,
      };

      stopLoading(state, 'userInfo.orders');
    },
    fetchOrdersError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.orders;
      ordersState.orders_ids = initialState.userInfo.orders.orders_ids;
      ordersState.error = action.payload;
      ordersState.orders = { orders: [], pagination: {} };
      stopLoading(state, 'userInfo.orders');
    },

    startLoadingInvoices(state) {
      startLoading(state, 'userInfo.invoices');
      const invState = state.userInfo.invoices;
      // invState.limit = null;
      // invState.offset = null;
      // invState.total = null;
    },
    fetchInvoicesSuccess(state, action: PayloadAction<any>) {
      const { invoices } = action.payload;
      const invState = state.userInfo.invoices;

      // invState.limit = limit;
      // invState.offset = offset;
      // invState.total = total;
      // invState.error = null;
      // invState.orders_by_id[orders[0].club_user_id] = orders;
      invState.invoices = invoices;

      stopLoading(state, 'userInfo.invoices');
    },
    fetchInvoicesError(state, action: PayloadAction<ErrorTypeAPI>) {
      // const invState = state.userInfo.invoices;
      // invState.invoices = initialState.userInfo.invoices.invoices;
      // invState.error = action.payload;
      stopLoading(state, 'userInfo.invoices');
    },
    fetchUnpaidInvoicesSuccess(state, action: PayloadAction<any>) {
      const { invoices } = action.payload;
      const invState = state.userInfo.invoices;

      // invState.limit = limit;
      // invState.offset = offset;
      // invState.total = total;
      // invState.error = null;
      // invState.orders_by_id[orders[0].club_user_id] = orders;
      invState.unpaidInvoices = invoices;

      stopLoading(state, 'userInfo.invoices');
    },

    startLoadingBackorders(state) {
      startLoading(state, 'userInfo.backOrders');
      // const invState = state.userInfo.startLoadingBackorders;
      // invState.limit = null;
      // invState.offset = null;
      // invState.total = null;
    },
    fetchBackordersSuccess(state, action: PayloadAction<any>) {
      const { backOrders } = action.payload;
      const backorderState = state.userInfo.backOrders;

      // invState.limit = limit;
      // invState.offset = offset;
      // invState.total = total;
      // invState.error = null;
      // invState.orders_by_id[orders[0].club_user_id] = orders;
      backorderState.backOrders = backOrders;

      stopLoading(state, 'userInfo.backOrders');
    },
    fetchBackordersError(state, action: PayloadAction<ErrorTypeAPI>) {
      const invState = state.userInfo.invoices;
      invState.invoices = initialState.userInfo.backOrders.backOrders;
      invState.error = action.payload;
      stopLoading(state, 'userInfo.backOrders');
    },

    startLoadingBrands(state) {
      startLoading(state, 'userInfo.brands');
      // const invState = state.userInfo.startLoadingBackorders;
      // invState.limit = null;
      // invState.offset = null;
      // invState.total = null;
    },
    fetchBrandsSuccess(state, action: PayloadAction<any>) {
      const { brands } = action.payload;
      const brandsState = state.userInfo.brands;

      // invState.limit = limit;
      // invState.offset = offset;
      // invState.total = total;
      // invState.error = null;
      // invState.orders_by_id[orders[0].club_user_id] = orders;
      brandsState.brands = brands;

      stopLoading(state, 'userInfo.brands');
    },
    fetchBrandsError(state, action: PayloadAction<ErrorTypeAPI>) {
      // const invState = state.userInfo.invoices;
      // invState.invoices = initialState.userInfo.backOrders.brands;
      // invState.error = action.payload;
      stopLoading(state, 'userInfo.brands');
    },

    startLoadingOrderedProducts(state) {
      startLoading(state, 'userInfo.orderedProducts');
    },
    fetchOrderedProductsSuccess(state, action: PayloadAction<any>) {
      const { products } = action.payload;
      const orderedProductsState = state.userInfo.orderedProducts;

      orderedProductsState.error = null;
      orderedProductsState.orderedProducts = products;

      stopLoading(state, 'userInfo.orderedProducts');
    },
    fetchOrderedProductsError(state, action: PayloadAction<ErrorTypeAPI>) {
      const orderedProductsState = state.userInfo.orderedProducts;
      orderedProductsState.error = action.payload;
      stopLoading(state, 'userInfo.orderedProducts');
    },

    startLoadingFinishedOrder(state) {
      startLoading(state, 'userInfo.finishedOrder');
      const ordersState = state.userInfo.finishedOrder;
      ordersState.data = null;
    },
    fetchFinishedOrderSuccess(
      state,
      action: PayloadAction<{ order: ThenArg<typeof APIClass.getOrder> }>,
    ) {
      const { order } = action.payload;
      const ordersState = state.userInfo.finishedOrder;

      ordersState.error = null;
      ordersState.data = order;

      stopLoading(state, 'userInfo.finishedOrder');
    },
    fetchFinishedOrdersError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.finishedOrder;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.finishedOrder');
    },
    startLoadingOrderBody(state) {
      startLoading(state, 'userInfo.orderBody');
      const ordersState = state.userInfo.orderBody;
      ordersState.data = null;
    },
    fetchOrderBodySuccess(
      state,
      action: PayloadAction<{
        orderBody: ThenArg<typeof APIClass.getOrderBodyHtml>;
      }>,
    ) {
      const { orderBody } = action.payload;
      const ordersState = state.userInfo.orderBody;

      ordersState.error = null;
      ordersState.data = orderBody;

      stopLoading(state, 'userInfo.orderBody');
    },
    fetchOrderBodyError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.orderBody;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.orderBody');
    },

    startLoadingRequestDetail(state) {
      startLoading(state, 'userInfo.requestDetail');
      const ordersState = state.userInfo.finishedOrder;
      ordersState.data = null;
    },
    fetchRequestDetailSuccess(
      state,
      action: PayloadAction<{ order: ThenArg<typeof APIClass.getOrder> }>,
    ) {
      const { order } = action.payload;
      const ordersState = state.userInfo.requestDetail;

      ordersState.error = null;
      ordersState.data = order;

      stopLoading(state, 'userInfo.requestDetail');
    },
    fetchRequestDetailError(state, action: PayloadAction<ErrorTypeAPI>) {
      const ordersState = state.userInfo.requestDetail;
      ordersState.error = action.payload;
      stopLoading(state, 'userInfo.requestDetail');
    },

    startLoadingRequests(state) {
      startLoading(state, 'userInfo.requests');
      const requestsState = state.userInfo.requests;
      requestsState.limit = null;
      requestsState.offset = null;
      requestsState.total = null;
    },
    fetchRequestsSuccess(state, action: PayloadAction<any>) {
      const { orders, limit, offset, total } = action.payload;
      const requestsState = state.userInfo.requests;

      requestsState.limit = limit;
      requestsState.offset = offset;
      requestsState.total = total;
      requestsState.error = null;
      requestsState.requests_by_id[orders[0].club_user_id] = orders;

      stopLoading(state, 'userInfo.requests');
    },
    fetchRequestsError(state, action: PayloadAction<ErrorTypeAPI>) {
      const requestsState = state.userInfo.requests;
      requestsState.requests_ids = initialState.userInfo.requests.requests_ids;
      requestsState.error = action.payload;
      stopLoading(state, 'userInfo.requests');
    },

    startLoadingFavorites(state) {
      startLoading(state, 'userInfo.favorites');
      const favoritesState = state.userInfo.favorites;
      favoritesState.limit = null;
      favoritesState.offset = null;
      favoritesState.total = null;
    },
    fetchFavoritesSuccess(state, action: PayloadAction<any>) {
      const favorites = action.payload;
      const favoritesState = state.userInfo.favorites;
      favoritesState.favorites_by_id = favorites;

      stopLoading(state, 'userInfo.favorites');
    },
    fetchFavoritesError(state, action: PayloadAction<ErrorTypeAPI>) {
      const favoritesState = state.userInfo.favorites;
      favoritesState.favorites_ids =
        initialState.userInfo.favorites.favorites_ids;
      favoritesState.error = action.payload;
      stopLoading(state, 'userInfo.favorites');
    },
  },
});

export const {
  startLoadingBillingAddresses,
  fetchBillingAddressesSuccess,
  fetchBillingAddressesError,
  startLoadingCustomerInfo,
  fetchCustomerInfoSuccess,
  fetchCustomerInfoError,
  startLoadingDeliveryAddresses,
  fetchDeliveryAddressesSuccess,
  fetchDeliveryAddressesError,
  startLoadingChildUsers,
  fetchChildUsersSuccess,
  fetchChildUsersError,
  startLoadingOrders,
  fetchOrdersSuccess,
  fetchOrdersError,
  startLoadingInvoices,
  fetchInvoicesSuccess,
  fetchInvoicesError,
  fetchUnpaidInvoicesSuccess,
  startLoadingBackorders,
  fetchBackordersSuccess,
  fetchBackordersError,
  startLoadingBrands,
  fetchBrandsSuccess,
  fetchBrandsError,
  startLoadingOrderedProducts,
  fetchOrderedProductsSuccess,
  fetchOrderedProductsError,
  startLoadingFinishedOrder,
  fetchFinishedOrderSuccess,
  fetchFinishedOrdersError,
  startLoadingOrderBody,
  fetchOrderBodySuccess,
  fetchOrderBodyError,
  startLoadingRequestDetail,
  fetchRequestDetailSuccess,
  fetchRequestDetailError,
  startLoadingRequests,
  fetchRequestsSuccess,
  fetchRequestsError,
  startLoadingFavorites,
  fetchFavoritesSuccess,
  fetchFavoritesError,
  startLoadingCustomerFinancialInfo,
  fetchCustomerFinancialInfoSuccess,
  fetchCustomerFinancialInfoError,
} = myAccountSlice.actions;

export default myAccountSlice.reducer;

const myAccountDomainSelector = state => state.myAccount;

export const customerInfoSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.data', null),
);

export const customerFinancialInfoSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.financialData', null),
);

export const deliveryAddressesSelector = createSelector(
  myAccountDomainSelector,
  substate =>
    prop(substate, 'userInfo.deliveryAddresses.delivery_addresses', []),
);

export const deliveryAddressesIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.deliveryAddresses.isFetching', false),
);

export const childUsersSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.childUsers.child_users', []),
);

export const childUsersIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.childUsers.isFetching', false),
);

export const ordersDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders', {}),
);

export const ordersArraySelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders.orders', []),
);

export const ordersByIdSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders.orders_by_id', {}),
);

export const ordersIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orders.isFetching', false),
);

export const orderedProductsDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orderedProducts.orderedProducts', {}),
);

export const orderedProductsIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orderedProducts.isFetching', false),
);

export const brandsSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.brands.brands', []),
);

export const invoicesSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.invoices.invoices', []),
);

export const invoicesPaginationSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.invoices.invoices.pagination', {}),
);

export const unpaidInvoicesSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.invoices.unpaidInvoices', []),
);

export const invoicesFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.invoices.isFetching', false),
);

export const backOrdersSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.backOrders.backOrders', []),
);

export const backOrdersIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.backOrders.isFetching', false),
);

export const requestsDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests', {}),
);

export const requestsByIdSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests.requests_by_id', {}),
);

export const favoritesDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.favorites', {}),
);

export const favoritesByIdSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.favorites.favorites_by_id', {}),
);

export const requestsIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requests.isFetching', false),
);

export const finishedOrderDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.finishedOrder.data', {}),
);

export const orderBodyDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.orderBody.data', {}),
);

export const finishedOrderIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.finishedOrder.isFetching', false),
);

export const requestDetailDataSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requestDetail.data', {}),
);

export const requestDetailIsFetchingSelector = createSelector(
  myAccountDomainSelector,
  substate => prop(substate, 'userInfo.requestDetail.isFetching', false),
);

export const fetchBillingAddresses = (): any => async (
  dispatch,
  getState,
  API,
) => {
  if (!getState().myAccount.data) {
    try {
      dispatch(startLoadingBillingAddresses());
      const userId = userSelector(getState()).id;
      const billingAddresses = await API.getBillingAddresses(userId, {});
      dispatch(fetchBillingAddressesSuccess({ addresses: [] }));
    } catch (err) {
      dispatch(fetchBillingAddressesError(err));
    }
  }
};

export const fetchCustomerDetails = (): any => async (
  dispatch,
  getState,
  API,
) => {
  if (!getState().myAccount.data) {
    try {
      dispatch(startLoadingCustomerInfo());
      const userId = userSelector(getState()).id;
      const customerInfo = await API.getCustomerDetails(userId, {
        withParent: '1',
      });
      dispatch(fetchCustomerInfoSuccess({ customer: customerInfo }));

      // dispatch(fetchCustomerFinancialDetails());
    } catch (err) {
      dispatch(fetchCustomerInfoError(err));
    }
  }
};

export const fetchDeliveryAddresses = (
  forceRefresh: boolean = false,
): any => async (dispatch, getState, API) => {
  if (
    forceRefresh ||
    !getState().myAccount.userInfo.deliveryAddresses.delivery_addresses ||
    getState().myAccount.userInfo.deliveryAddresses.delivery_addresses
      .length === 0
  ) {
    try {
      dispatch(startLoadingDeliveryAddresses());
      const userId = userSelector(getState()).id;
      const deliveryAddresses = await API.getDeliveryAddresses(userId, {});
      dispatch(fetchDeliveryAddressesSuccess({ addresses: deliveryAddresses }));
    } catch (err) {
      dispatch(fetchDeliveryAddressesError(err));
    }
  }
};

export const fetchChildUsers = (forceRefresh: boolean = false): any => async (
  dispatch,
  getState,
  API,
) => {
  if (
    forceRefresh ||
    !getState().myAccount.userInfo.childUsers.child_users ||
    getState().myAccount.userInfo.childUsers.child_users.length === 0
  ) {
    try {
      dispatch(startLoadingChildUsers());
      const userId = userSelector(getState()).id;
      const childUsers = await API.loadChildSiblingsUsers(userId, {
        onlyActive: '1',
      });
      dispatch(fetchChildUsersSuccess({ customers: childUsers.customers }));
    } catch (err) {
      dispatch(fetchChildUsersError(err));
    }
  }
};

export const fetchOrders = (
  userId: number | string | null = null,
  limit: number = 5,
  offset: number = 0,
  fromDate?: any,
  toDate?: any,
): any => async (dispatch, getState, API) => {
  try {
    dispatch(startLoadingOrders());
    const currentUserId = userSelector(getState()).id;
    // const orders = await API.getOrders(currentUserId, { limit, offset });
    const orders = await API.geti6Orders(currentUserId, {
      limit,
      offset,
      userOuterId: userId || undefined,
      filterFrom:
        fromDate ||
        moment()
          .subtract('3', 'months')
          .format('DD-MM-YYYY'),
      filterTo: toDate || undefined,
    });
    dispatch(fetchOrdersSuccess(orders));
  } catch (err) {
    dispatch(fetchOrdersError(err));
  }
};

export const fetchBackorders = ({
  brandId,
  categId,
  offset,
  userOuterId,
  reserved,
  limit,
}: {
  brandId?: any;
  categId?: any;
  offset?: any;
  userOuterId?: any;
  reserved?: any;
  limit?: any;
}): any => async (dispatch, getState, API) => {
  // if (
  //   !getState().myAccount.userInfo.backOrders.backOrders ||
  //   getState().myAccount.userInfo.backOrders.backOrders.length === 0
  // ) {
  try {
    dispatch(startLoadingBackorders());
    const currentUserId = userSelector(getState()).id;
    const backOrders = await API.loadi6Backorders(currentUserId, {
      categoryId: categId ? categId : undefined,
      brandId: brandId ? brandId : undefined,
      offset,
      userOuterId: userOuterId || undefined,
      reserved: reserved || undefined,
      limit: limit || undefined,
    });
    dispatch(fetchBackordersSuccess({ backOrders }));
  } catch (err) {
    dispatch(fetchBackordersError(err));
  }
  // }
};

export const fetchBrandList = (
  userId: number | string | null = null,
  limit: number = 5,
  offset: number = 0,
): any => async (dispatch, getState, API) => {
  // console.log('fetching brands');
  try {
    dispatch(startLoadingBrands());
    const lang = langSelector(getState());
    const brands = await API.loadBrands(
      {},
      {
        xAcceptLanguage: lang,
      },
    );
    dispatch(fetchBrandsSuccess({ brands: prop(brands, 'brands') || [] }));
  } catch (err) {
    // console.log({ FETCHERR: err });
    dispatch(fetchBrandsError(err));
  }
};

export const fetchInvoices = ({
  type,
  paid,
  limit,
  offset,
  fromDate,
  toDate,
  userOuterId,
}: {
  type?: any;
  paid?: any;
  limit?: any;
  offset?: any;
  fromDate?: any;
  toDate?: any;
  userOuterId?: any;
}): any => async (dispatch, getState, API) => {
  // if (
  //   !getState().myAccount.userInfo.orders.orders_ids ||
  //   getState().myAccount.userInfo.orders.orders_ids.length === 0
  // ) {
  try {
    dispatch(startLoadingInvoices());
    const currentUserId = userSelector(getState()).id;
    const invoices = await API.loadi6Documents(currentUserId, {
      type: type ? type : undefined,
      paid: paid || undefined,
      offset: offset || 0,
      filterFrom:
        fromDate ||
        moment()
          .subtract('3', 'months')
          .format('DD-MM-YYYY'),
      filterTo: toDate || undefined,
      limit: limit || undefined,
      userOuterId: userOuterId || undefined,
    });
    // const sorted = [...invoices];

    dispatch(
      fetchInvoicesSuccess({
        invoices,
      }),
    );
  } catch (err) {
    dispatch(fetchInvoicesError(err));
  }
  // }
};

export const fetchOrderedProducts = (
  {
    brandId,
    categId,
    fromDate,
    toDate,
    offset,
    userOuterId,
    limit,
  }: {
    brandId?: any;
    categId?: any;
    fromDate?: any;
    toDate?: any;
    offset?: any;
    userOuterId?: any;
    limit?: any;
  },
  userId?: any,
): any => async (dispatch, getState, API) => {
  try {
    dispatch(startLoadingOrderedProducts());
    const lang = langSelector(getState());
    const currency = currencySelector(getState());
    const currentUserId = userId ? userId : userSelector(getState()).id;

    const products = await API.loadi6OrderedProducts(currentUserId, {
      filterFrom:
        fromDate ||
        moment()
          .subtract('3', 'months')
          .format('DD-MM-YYYY'),
      // today.subtract('3', 'months').format('YYYY-MM-DD')
      filterTo: toDate || undefined,
      categoryId: categId || undefined,
      brandId: brandId || undefined,
      offset,
      userOuterId: userOuterId || undefined,
      limit: limit || undefined,
    });
    dispatch(fetchOrderedProductsSuccess({ products }));
  } catch (err) {
    dispatch(fetchOrderedProductsError(err));
  }
};

export const fetchFavorites = (
  userId: number | string | null = null,
  limit: number = 20,
  offset: number = 0,
): any => async (dispatch, getState, API) => {
  if (
    !getState().myAccount.userInfo.favorites.favorites_ids ||
    getState().myAccount.userInfo.favorites.favorites_ids.length === 0
  ) {
    try {
      dispatch(startLoadingFavorites());
      const currentUserId = userId ? userId : userSelector(getState()).id;
      const favorites = await API.loadFavoriteProducts(currentUserId, {
        limit,
        offset,
      });
      dispatch(fetchFavoritesSuccess(favorites));
    } catch (err) {
      dispatch(fetchFavoritesError(err));
    }
  }
};

export const fetchFinishedOrder = (orderId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingFinishedOrder());
    const order = await API.getOrder(orderId);
    dispatch(fetchFinishedOrderSuccess({ order }));
  } catch (err) {
    dispatch(fetchFinishedOrdersError(err));
  }
};

export const fetchOrderHtmlBody = (orderId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingOrderBody());
    const orderBody = await API.getOrderBodyHtml(orderId);
    dispatch(fetchOrderBodySuccess({ orderBody }));
  } catch (err) {
    dispatch(fetchOrderBodyError(err));
  }
};

export const fetchRequests = (
  userId: number | string | null = null,
  limit: number = 5,
  offset: number = 0,
): any => async (dispatch, getState, API) => {
  if (
    !getState().myAccount.userInfo.requests.requests_ids ||
    getState().myAccount.userInfo.requests.requests_ids.length === 0
  ) {
    try {
      dispatch(startLoadingRequests());
      const currentUserId = userId ? userId : userSelector(getState()).id;
      const requests = await API.getOrders(currentUserId, {
        limit,
        offset,
        filterType: 'DEMAND',
      });
      dispatch(fetchRequestsSuccess(requests));
    } catch (err) {
      dispatch(fetchRequestsError(err));
    }
  }
};

export const fetchOrderDetail = (orderPublicId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(startLoadingRequestDetail());
    const currentUserId = userSelector(getState()).id;
    // const order = await API.getOrder(orderPublicId);
    const order = await API.geti6Order(currentUserId, orderPublicId, {});
    dispatch(fetchRequestDetailSuccess({ order }));
  } catch (err) {
    dispatch(fetchRequestDetailError(err));
  }
};

export const fetchCustomerFinancialDetails = (): any => async (
  dispatch,
  getState,
  API,
) => {
  if (!getState().myAccount.financialData) {
    try {
      dispatch(startLoadingCustomerFinancialInfo());
      const userId = userSelector(getState()).id;
      const customerInfo = await API.loadAdditionalInfo(userId, {});
      dispatch(fetchCustomerFinancialInfoSuccess({ customer: customerInfo }));
    } catch (err) {
      dispatch(fetchCustomerFinancialInfoError(err));
    }
  }
};
