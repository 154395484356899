import { prop } from '.';

enum THEMES {
  DEFAULT = '',
  HEAT = 'heatCategory',
  WOOD = 'woodCategory',
  REST = 'restCategory',
}

enum CategoryNames {
  SPOJ_MATERIAL = 'SPOJ_MATERIAL',
  CENTRUM_VYKUROVANIA = 'CENTRUM_VYKUROV',
  PRE_DREVAROV = 'PRE_DREVAROV',
  OSTATNE = 'OSTATNE',
}

export const resolveCurrentThemeFromCategory = (categoryIds: string[]) => {
  if (categoryIds.includes(CategoryNames.CENTRUM_VYKUROVANIA)) {
    return THEMES.HEAT;
  }

  if (categoryIds.includes(CategoryNames.PRE_DREVAROV)) {
    return THEMES.WOOD;
  }

  if (categoryIds.includes(CategoryNames.OSTATNE)) {
    return THEMES.REST;
  }

  return THEMES.DEFAULT;
};

export function getAttribNameFromAttribObj(attrib: any, lang?: string) {
  if (lang && attrib && attrib.translations) {
    const langTranslation = attrib.translations.find(t => t.lang_id === lang);
    if (prop(langTranslation, 'attrib_name')) {
      return prop(langTranslation, 'attrib_name');
    }
  }
  return prop(attrib, 'attrib_name');
}

export function getAttribParameterFromAttribObj(
  value: any,
  parameterName: string,
  lang?: string,
) {
  if (lang && value && value.translations) {
    const langTranslation = value.translations.find(t => t.lang_id === lang);
    if (prop(langTranslation, parameterName)) {
      return prop(langTranslation, parameterName);
    }
  }
  return prop(value, parameterName);
}

export function resolveCategoryName(data: any) {
  return (
    prop(
      data,
      'publish.0.category_name',
      prop(data, 'content.0.name', prop(data, 'category_name', '')),
    ) || prop(data, 'category_name', '')
  );
}

export function resolveCategoryUrlString(data: any, byId?: any) {
  return (
    (byId ? prop(byId, `${data.redirect_category_id}.publish.0.url`) : false) ||
    prop(data, 'publish.0.url') ||
    prop(data, 'url', '')
  );
}
