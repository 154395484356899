import * as React from 'react';
import { connect } from 'react-redux';
import { default as API, ThenArg } from '../../services/API';
import { langSelector, titleSelector } from '../Category/selectors';
import {
  userSelector,
  contactInfoSelector,
  langChangedSelector,
  hostnameSelector,
} from '../App/selectors';
import Product from '../../components/Product/Product';
import { connectSsr } from 'ssr-service';
import {
  loadProductDetail,
  loadProductDetailBazar,
  loadProductDetailCrosssell,
  loadProductDetailRelatedColors,
  loadProductDetailRelatedConnections,
} from './actions';
import {
  productAlternativesIsFetchingSelector,
  productAlternativesSelector,
  productBazarSelector,
  productCrosssellIsFetchingSelector,
  productCrosssellSelector,
  productDataSelector,
  productDetailIsFetchingSelector,
} from './selectors';
import { addItemToCart } from '../Cart/cartSlice';
import NotFound from '../Errors/NotFound';
import { resolveDomainByHostname } from '../../configureTrans';

export interface ProductProps {
  productData: ThenArg<typeof API.loadProduct>;
  dispatch: any;
  lang: string;
  langChanged: boolean;
  params: {
    product_id: number | string;
    product_url: string;
  };
  alternativeProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingAlternatives: boolean;
  crosssellProducts: ThenArg<typeof API.loadProductConnections>;
  bazarProducts: ThenArg<typeof API.loadProductConnections>;
  isFetchingCrosssell: boolean;
  hostname: string;
  customer: any;
  isGift: boolean;
}

class ProductContainer extends React.Component<ProductProps> {
  public static async getInitialProps({
    params: { product_id },
    product_id: productId,
    dispatch,
  }) {
    const pId = product_id || productId;

    try {
      await Promise.all([
        dispatch(loadProductDetail(pId)),
        dispatch(loadProductDetailBazar(pId)),
        dispatch(loadProductDetailCrosssell(pId)),
        dispatch(loadProductDetailRelatedColors(pId)),
        dispatch(loadProductDetailRelatedConnections(pId)),
      ]);

      return;
    } catch (exp) {
      // console.log(exp);
      return {
        isError: true,
      };
    }
  }

  // public metaHandler = lang => {
  //   const { product } = this.props;
  //   const productPublish = propByKey(product, 'publish', []).find(
  //     pp => pp.lang_id === lang,
  //   );
  //
  //   const metaTags = propByKey(productPublish, 'content.json_content.meta', {
  //     title: this.props.defaultTitle,
  //   });
  //
  //   if (!metaTags.title.length) {
  //     metaTags.title = `${this.props.defaultTitle} - ${product.name}`;
  //   }
  //
  //   return <MetaTags tags={metaTags} />;
  // };

  private addToCart = async (product: any, count: number) => {
    const { dispatch } = this.props;
    await dispatch(addItemToCart(product, count, false, true));
  };

  public render() {
    const {
      productData,
      alternativeProducts,
      isFetchingAlternatives,
      crosssellProducts,
      isFetchingCrosssell,
      dispatch,
      lang,
      langChanged,
      hostname,
      customer,
      bazarProducts,
      isGift,
    } = this.props;
    const baseApiUrl: string = process.env.REACT_APP_API_BASE_URL as string;

    if (!productData) {
      return <NotFound />;
    }

    return (
      <Product
        product={productData}
        apiUrl={baseApiUrl}
        alternativeProducts={alternativeProducts}
        isFetchingAlternatives={isFetchingAlternatives}
        crosssellProducts={crosssellProducts}
        bazarProducts={bazarProducts}
        isFetchingCrosssell={isFetchingCrosssell}
        dispatch={dispatch}
        addToCart={this.addToCart}
        lang={lang}
        langChanged={langChanged}
        hostname={hostname}
        user={customer}
        isGift={isGift}
        domainId={+resolveDomainByHostname(hostname)}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: userSelector(state),
    lang: langSelector(state),
    langChanged: langChangedSelector(state),
    defaultTitle: titleSelector(state),
    contactInfo: contactInfoSelector(state),
    productData: productDataSelector(state),
    isFetching: productDetailIsFetchingSelector(state),
    alternativeProducts: productAlternativesSelector(state),
    isFetchingAlternatives: productAlternativesIsFetchingSelector(state),
    crosssellProducts: productCrosssellSelector(state),
    bazarProducts: productBazarSelector(state),
    isFetchingCrosssell: productCrosssellIsFetchingSelector(state),
    hostname: hostnameSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductContainer' })(ProductContainer),
);
