import * as React from 'react';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { prop, getAuthorUrl, formatDate, stripHtmlTags } from '../../utilities';
import styled from 'styled-components';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { __ } from 'react-i18n';
import { resolveArticleContentDetails } from '../../utilities/article';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { getCmsMetaTags } from '../../utilities/metatags';
import { rem } from 'polished';
import {
  FlexRowCenter,
  FlexColCenter,
  FlexCol,
  DsiContactForm,
  DsiInfoText,
  DsiInfoType,
} from 'eshop-defaults';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { Link } from 'react-router';
import LazyLoad from 'react-lazyload';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../../components/_helpers/Default/ResponsiveWrappers';
import { Picture } from '../../components/_helpers/Image/Picture';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import RenderedBody from '../../components/Article/RenderedBody';
import ParallaxImage from '../../components/Article/ParallaxImage';
import { langChangedSelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { setIsBreadcrumbVisible } from '../App/actions';
import { DEFAULT_CATEGORY_ID } from '../../configureTrans';
import ArticleTags from '../../components/Article/ArticleTags';
import { connectSsr } from 'ssr-service';
import { Title } from 'eshop-defaults/lib/components/Dsi/General/Title';
import { FormInput } from 'eshop-defaults/lib/components/Dsi/Auth/LoginForm';

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export interface CmsProps {
  sitemap: any;
  sitemapTree: any;
  parentSitemap: any;
  isError: boolean;
  defaultTitle: string;
  articles: any;
  isArticle: boolean;
  contentData: ThenArg<typeof API.loadArticleDetail>;
  draftContent: boolean;
  products: any;
  lang: string;
  langChanged: boolean;
  dispatch: (action: any) => void;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: ${rem(600)};
  margin: ${rem(80)} auto;
  background: ${({ theme }) => theme.colors.white};
  padding: ${rem(32)} ${rem(24)};
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  ${({ theme }) => theme.mediab.l925`
     padding: ${rem(24)} ${rem(16)};
     margin: 0 auto;
  `}
`;

const MainContent = styled.div`
  max-width: ${rem(1020)};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(30)};
  padding-bottom: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.m580`
     padding: 0 ${rem(16)};
  `}
`;

const StyledTitle = styled(Title)`
  text-align: center;
`;

class ContactForm extends React.Component<
  CmsProps,
  {
    wasSuccess: boolean;
  }
> {
  public static async getInitialProps({
    sitemapId,
    contentId,
    lang,
    dispatch,
  }) {
    try {
      return {};
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public constructor(props) {
    super(props);

    this.state = {
      wasSuccess: false,
    };
    // window.handleRecaptcha = this.handleRecaptcha;
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
  }

  // public handleRecaptcha = e => {
  //   alert('You can handle captcha inside the class here');
  //   e.preventDefault();
  //   // console.log('recaptchaa: ', { e });
  // };

  public handleSubmit = async body => {
    try {
      await API.contactForm({}, body);
      this.setState({ wasSuccess: true });
    } catch (e) {
      // console.log({ e });
    }
  };

  public submitForm = async (body, e) => {
    e.preventDefault();
    try {
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha
          .execute(SITE_KEY, { action: 'submit' })
          .then(async token => {
            // console.log({ token });
            await API.contactForm({}, { ...body, token });
            this.setState({ wasSuccess: true });
          });
      });
    } catch (e) {
      // console.log({ e });
      window.alert('Chyba! Váš dopyt nebol odoslaný!');
    }
  };

  public render() {
    const { lang } = this.props;
    const { wasSuccess } = this.state;

    return (
      <Wrapper>
        <MetaTags
          tags={{ title: __('Kontaktný formulár - GDPR') }}
          noindex={true}
          nofollow={true}
        />

        <MainContent>
          <StyledTitle>{__('Kontaktný formulár - GDPR')}</StyledTitle>
          {wasSuccess ? (
            <DsiInfoText
              type={DsiInfoType.SUCCESS}
              info={__('Vaša žiadosť bola zaznamenaná')}
            />
          ) : (
            <DsiContactForm lang={lang} handleSubmit={this.submitForm} />
          )}
        </MainContent>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  defaultTitle: state.general.title,
  lang: state.general.lang,
  langChanged: langChangedSelector(state),
});

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContactForm' })(ContactForm),
);
