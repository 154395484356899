import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import API, { ThenArg } from '../../services/API';
import DataTable from 'react-data-table-component';
import * as cookie from 'react-cookies';
import {
  fetchData as fetchManagerReport,
  managerReportDataSelector,
  managerReportFetchingSelector,
} from './managerReportSlice';
import { connectSsr } from 'ssr-service';
import { prop, stringToMoment } from '../../utilities';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { resetCart } from '../Cart/cartSlice';
import { resetToken } from '../App/actions';
import { __ } from 'react-i18n';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';

interface Props {
  user: any;
  data: any;
  dispatch: any;
  children: any;
  userData: ThenArg<typeof API.getCustomerDetails>;
  currency: string;
  userFinancialData: any;
  pagination: any;
  fetching: boolean;
}

interface State {
  canImpersonateUsers: any[];
  canImpersonateAddresses: any[];
  filters: {
    customerFilter: string;
    customerId: string;
    customerInternalId: string;
    customerAddressId: string;
    dateFrom: string;
    dateTo: string;
    query: string;
    productId: string;
  };
}

class ManagerReport extends React.Component<Props & WithRouterProps, State> {
  DEFAULT_STATE = {
    canImpersonateUsers: [],
    canImpersonateAddresses: [],
    filters: {
      customerFilter: '',
      customerId: '',
      customerInternalId: '',
      customerAddressId: '',
      dateFrom: '',
      dateTo: '',
      query: '',
      productId: '',
    },
  };
  public constructor(props) {
    super(props);
    this.state = this.DEFAULT_STATE;
  }

  async componentDidMount() {
    const { location, user } = this.props;
    const urlAttribsObj = new URLSearchParams(location.query);
    this.setState(
      {
        filters: {
          ...this.DEFAULT_STATE.filters,
          query: urlAttribsObj.get('query') || '',
          customerAddressId: urlAttribsObj.get('customerAddressId') || '',
          customerFilter: urlAttribsObj.get('customerId') || '',
          customerId: urlAttribsObj.get('customerId')?.split('-')[0] || '',
          customerInternalId:
            urlAttribsObj.get('customerId')?.split('-')[1] || '',
          dateFrom: urlAttribsObj.get('dateFrom') || '',
          dateTo: urlAttribsObj.get('dateTo') || '',
          productId: urlAttribsObj.get('productId') || '',
        },
      },
      () => {
        if (user && (user.customerType === 'PERSONAL' || user.originalUser)) {
          this.props.dispatch(fetchManagerReport(urlAttribsObj));
          this.fetchImpersonateData();
          this.onChangeCustomerId(this.state.filters.customerInternalId);
        }
      },
    );
  }

  public static async getInitialProps(props) {
    const { dispatch, location, cookies, token, user } = props;
    const userCookie =
      prop(cookies, `${USER_COOKIE}`) || !isSSR()
        ? cookie.load(USER_COOKIE)
        : null;

    await dispatch(
      loginUser(userCookie, false, () => {
        dispatch(resetCart(false));
        if (window && window.location && window.location.reload) {
          window.location.reload();
        }
      }),
    );
    // if (process.env.NODE_ENV === 'development') {
    //   await dispatch(
    //     loginUser(
    //       'c9cd5e4a6f194cf7742a6aae28a7b42c231e106e9e823e0bcf3fcf0d64091711225b9b5654ba43b3a69fb5b537453298fc10bce33bc0456761c3772675a5732de3ca7f846f896d7a093cbc670e1dbfd90e957259400b50a29173c85263f08efed8bd018e3fe1b1cb6917c0ad72d9705dc2ade9208489199affd70e329ade46e4',
    //     ),
    //   );
    // }

    if (user && (user.customerType === 'PERSONAL' || user.originalUser)) {
      const urlAttribsObj = new URLSearchParams(location.query);
      try {
        await dispatch(fetchManagerReport(urlAttribsObj));
        return;
      } catch (exp) {
        // console.log(exp);
        return;
      }
    }

    return props;
  }

  public fetchImpersonateData = async () => {
    const result: any = await API.loadCustomerRelations(
      prop(this.props.user, 'originalUser.id') || prop(this.props.user, 'id'),
      {
        relation: 'CAN_IMPERSONATE',
        onlyParents: '1',
        query: undefined,
      },
    );
    this.setState({ ...this.state, canImpersonateUsers: result.users });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      const urlAttribsObj = new URLSearchParams(this.props.location.query);
      this.props.dispatch(fetchManagerReport(urlAttribsObj));
      this.fetchImpersonateData();
      this.onChangeCustomerId(this.state.filters.customerInternalId);
    }
  }

  public onChangeCustomerId = id => {
    const fetchData = async id => {
      const childUsers: any = await API.loadChildSiblingsUsers(
        parseInt(this.state.filters.customerInternalId),
        {
          onlyActive: '1',
        },
      );
      this.setState({
        ...this.state,
        canImpersonateAddresses: childUsers.customers,
      });
    };

    fetchData(id);
  };

  public mapUrlToFilterObject = (url: string): URLSearchParams => {
    const searchParams = new URLSearchParams(url);
    return searchParams;
  };

  public mapFilterObjectToUrl = (url, filterObject: URLSearchParams) => {
    const searchParams = new URLSearchParams(filterObject);
    return `${url}?${searchParams.toString()}`;
  };

  public render() {
    console.log('RENDER');
    const {
      router,
      location: { query },
    } = this.props;
    const { data, pagination, fetching, user } = this.props;

    if (!user || (user.customerType !== 'PERSONAL' && !user.originalUser)) {
      return null;
    }

    const urlAttribsObj = this.mapUrlToFilterObject(query);

    const changeUrlParam = (name, value, type = 'DEFAULT') => {
      urlAttribsObj.set(name, value);
      router.push(this.mapFilterObjectToUrl('/manager', urlAttribsObj));
    };

    const changeMultipleUrlParam = data => {
      Object.keys(data).forEach(key => {
        urlAttribsObj.set(key, data[key]);
      });
      router.push(this.mapFilterObjectToUrl('/manager', urlAttribsObj));
    };

    const columns = [
      {
        id: 'created_at',
        name: 'Dátum',
        selector: row =>
          stringToMoment(row.created_at)?.format('DD.MM.YYYY HH:mm:ss'),
        sortable: true,
        sortField: 'created_at',
      },
      {
        name: 'Čislo faktúry',
        selector: row => `${row['@_IniCus_InvCode']}`,
        sortable: false,
      },
      {
        id: 'name',
        name: 'Produkt',
        selector: row => row.name,
        sortable: true,
        sortField: 'name',
      },
      {
        id: 'code',
        name: 'Kód',
        selector: row => row['@_IniCus_CodeX'],
      },
      {
        id: 'quantity',
        name: 'Ks',
        selector: row => row.quantity,
        sortable: true,
        sortField: 'quantity',
      },
      {
        name: 'Celková cena',
        selector: row =>
          `${row.total_price} ${row['lang_id'] === 'sk' ? 'EUR' : 'CZK'}`,
        sortable: true,
        sortField: 'total_price',
        id: 'total_price',
      },
      {
        name: 'Aktuálna cena',
        selector: row =>
          `${
            row.customer_price
              ? `${row.customer_price} ${
                  row['lang_id'] === 'sk' ? 'EUR' : 'CZK'
                }`
              : '-'
          }`,
        sortable: false,
      },
    ];

    const handleSort = (column, sortDirection) => {
      changeMultipleUrlParam({
        sort: column.sortField,
        sortDir: sortDirection,
      });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
      changeUrlParam('limit', newPerPage);
    };

    const handlePageChange = page => {
      changeUrlParam('offset', page);
    };

    const handleFilterChange = (type, e) => {
      const id = e.target.value;

      if (type === 'customerId') {
        const splitted = id.split('-');
        this.setState(
          {
            ...this.state,
            filters: {
              ...this.state.filters,
              [type]: splitted[0],
              customerInternalId: splitted[1],
              customerFilter: id,
              customerAddressId: '',
            },
          },
          () => {
            this.onChangeCustomerId(id);
          },
        );
      } else {
        this.setState({
          ...this.state,
          filters: { ...this.state.filters, [type]: id },
        });
      }
    };

    const onClickSearch = e => {
      e.preventDefault();
      changeMultipleUrlParam({
        offset: 1,
        customerAddressId: this.state.filters.customerAddressId,
        customerId: this.state.filters.customerFilter,
        dateFrom: this.state.filters.dateFrom,
        dateTo: this.state.filters.dateTo,
        query: this.state.filters.query,
      });
    };

    const onClickCancelSearch = () => {
      changeMultipleUrlParam({
        offset: 1,
        customerAddressId: this.state.filters.customerAddressId,
        customerId: this.state.filters.customerFilter,
        dateFrom: '',
        dateTo: '',
        query: '',
      });

      this.setState({
        ...this.state,
        filters: { ...this.state.filters, dateFrom: '', dateTo: '', query: '' },
      });
    };

    return (
      <>
        <div>
          Prihlásený: {prop(user, 'originalUser.meno') || prop(user, 'name')}
          {prop(user, 'originalUser.priezvisko') || prop(user, 'surname')}
        </div>
        <br />
        <hr />

        <form onSubmit={onClickSearch}>
          <label>
            Zákazník:
            <select
              value={this.state.filters.customerFilter}
              onChange={handleFilterChange.bind(this, 'customerId')}
            >
              <option value="">Vyberte zákazníka</option>
              {this.state.canImpersonateUsers.map(user => {
                return (
                  <option value={`${user.outer_id}-${user.id}`}>
                    {user.meno || user.firma} ({user.outer_id})
                  </option>
                );
              })}
            </select>
          </label>
          <br />
          <label>
            Dodacia adresa:
            <select
              value={this.state.filters.customerAddressId}
              onChange={handleFilterChange.bind(this, 'customerAddressId')}
            >
              <option value="">Všetky</option>
              {this.state.canImpersonateAddresses.map(user => {
                return <option value={user.outer_id}>{user.meno}</option>;
              })}
            </select>
          </label>
          <br />
          <label>
            Dátum od:
            <input
              value={this.state.filters.dateFrom}
              onChange={handleFilterChange.bind(this, 'dateFrom')}
              type="date"
            />
          </label>
          <br />
          <label>
            Dátum do:
            <input
              value={this.state.filters.dateTo}
              onChange={handleFilterChange.bind(this, 'dateTo')}
              type="date"
            />
          </label>
          <br />
          <label>
            Výraz:
            <input
              value={this.state.filters.query}
              onChange={handleFilterChange.bind(this, 'query')}
              name="query"
            />
          </label>
          <button onClick={onClickSearch}>Hľadať</button>
          <button onClick={onClickCancelSearch}>Zrušiť</button>
        </form>

        {this.state.filters.customerFilter && (
          <DataTable
            progressPending={fetching}
            title="Prehľad položiek"
            defaultSortAsc={urlAttribsObj.get('sortDir') === 'asc'}
            defaultSortFieldId={urlAttribsObj.get('sort')}
            columns={columns}
            data={data}
            sortServer
            onSort={handleSort}
            persistTableHead
            pagination
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 300]}
            paginationServer
            paginationTotalRows={prop(pagination, 'total', 0)}
            paginationPerPage={parseInt(urlAttribsObj.get('limit') || '10', 10)}
            paginationDefaultPage={parseInt(
              urlAttribsObj.get('offset') || '1',
              10,
            )}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    data: prop(managerReportDataSelector(state), 'data', []),
    fetching: managerReportFetchingSelector(state),
    pagination: prop(managerReportDataSelector(state), 'pagination'),
  };
};
export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ManagerReport' })(withRouter(ManagerReport)),
);
