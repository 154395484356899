import * as React from 'react';
import { Helmet } from 'react-helmet';
import { checkMetaTag, langToISOCode } from '../../../utilities';
import { connect } from 'react-redux';
import { prop } from 'eshop-defaults/lib';
import {
  getFirstValidMetaTagValue,
  isEmptyObj,
} from '../../../utilities/metatags';
import { resolveBaseUrlByLang } from '../../../configureTrans';

interface Props {
  tags: any;
  metaTags: any;
  defaultTitle: string;
  noindex?: boolean;
  nofollow?: boolean;
  canonicalUrl?: string;
  concatDefaultTitle?: boolean;
  hrefLangs?: {
    lang_id: string;
    url: string;
  }[];
}

class MetaTags extends React.PureComponent<Props, {}> {
  public render() {
    const {
      tags,
      metaTags,
      defaultTitle,
      noindex,
      nofollow,
      canonicalUrl,
      hrefLangs,
      concatDefaultTitle,
    } = this.props;

    if (!tags) {
      return null;
    }

    function renderDescr() {
      if (!isEmptyObj(tags.description) && tags.description) {
        return (
          <meta name="description" content={checkMetaTag(tags.description)} />
        );
      }
      if (
        metaTags &&
        (prop(metaTags, 'description') || prop(metaTags, 'metaDescription'))
      ) {
        return (
          <meta
            name="description"
            content={checkMetaTag(
              prop(metaTags, 'description') ||
                prop(metaTags, 'metaDescription'),
            )}
          />
        );
      }
      return null;
    }

    function renderKeyWords() {
      if (!isEmptyObj(tags.keywords) && tags.keywords) {
        return <meta name="keywords" content={checkMetaTag(tags.keywords)} />;
      }
      if (
        metaTags &&
        (prop(metaTags, 'keywords') || prop(metaTags, 'metaKeywords'))
      ) {
        return (
          <meta
            name="keywords"
            content={checkMetaTag(
              prop(metaTags, 'keywords') || prop(metaTags, 'metaKeywords'),
            )}
          />
        );
      }
      return null;
    }

    function renderTitle() {
      if (concatDefaultTitle !== false) {
        let title = `${defaultTitle}`;
        let deftTitle = title ? title : `${''}`;
        if (tags.title) {
          title = `${tags.title} - ${deftTitle}`;
        }
        return <title>{title}</title>;
      } else {
        return <title>{tags.title ? tags.title : ''}</title>;
      }
    }

    function renderHrefLangs() {
      if (hrefLangs && hrefLangs.length > 1) {
        return hrefLangs
          .map(hrefLang => {
            try {
              const isoCode = langToISOCode(hrefLang.lang_id);
              const baseUrl = resolveBaseUrlByLang(hrefLang.lang_id).replace(
                /\/$/,
                '',
              );
              const hrefLangUrl = hrefLang.url.replace(/^\/+/, '');
              const fullUrl = `${baseUrl}/${hrefLangUrl}`;

              return <link rel="alternate" hrefLang={isoCode} href={fullUrl} />;
            } catch (e) {
              return null;
            }
          })
          .filter(o => o);
      } else return '';
    }

    const ogTitle = getFirstValidMetaTagValue([
      prop(tags, 'og_title'),
      prop(metaTags, 'og:title'),
    ]);
    const ogType = getFirstValidMetaTagValue([
      prop(tags, 'og_type'),
      prop(metaTags, 'og:type'),
    ]);
    const ogDescription = getFirstValidMetaTagValue([
      prop(tags, 'og_description'),
      prop(metaTags, 'og:description'),
    ]);
    const ogImage = getFirstValidMetaTagValue([
      prop(tags, 'og_image'),
      prop(metaTags, 'og:image'),
    ]);
    const ogUrl = getFirstValidMetaTagValue([prop(tags, 'og_url')]);

    return (
      <React.Fragment>
        {tags !== {} && (
          <Helmet>
            {(noindex || nofollow) && (
              <meta
                name="robots"
                content={`${noindex ? 'noindex' : ''}${
                  noindex && nofollow ? ', ' : ''
                }${nofollow ? 'nofollow' : ''}`}
              />
            )}
            {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}

            {renderHrefLangs()}

            {renderTitle()}

            {renderDescr()}

            {renderKeyWords()}

            {ogTitle ? <meta name="og:title" content={ogTitle} /> : null}
            {ogType ? <meta name="og:type" content={ogType} /> : null}
            {ogDescription ? (
              <meta name="og:description" content={ogDescription} />
            ) : null}
            {ogImage ? <meta name="og:image" content={ogImage} /> : null}
            {/* {!isEmptyObj(tags.og_type) && tags.og_type && (
              <meta name="og:type" content={checkMetaTag(tags.og_type)} />
            )}
            {!isEmptyObj(tags.og_description) && tags.og_description && (
              <meta
                name="og:description"
                content={checkMetaTag(tags.og_description)}
              />
            )} */}
            {/* {!isEmptyObj(tags.og_url) && tags.og_url && (
              <meta name="og:url" content={checkMetaTag(tags.og_url)} />
            )} */}
            {ogUrl ? <meta property="og:url" content={ogUrl} /> : null}
            {/* {!isEmptyObj(tags.og_image) && tags.og_image && (
              <meta name="og:image" content={checkMetaTag(tags.og_image)} />
            )} */}
            {tags.og_image && tags.og_image_width ? (
              <meta property="og:image:width" content={tags.og_image_width} />
            ) : ogImage ? (
              <meta property="og:image:width" content={'1000'} />
            ) : null}
            {tags.og_image && tags.og_image_height ? (
              <meta property="og:image:height" content={tags.og_image_height} />
            ) : ogImage ? (
              <meta property="og:image:height" content={'1000'} />
            ) : null}
            {!isEmptyObj(tags.og_article_author) && tags.og_article_author && (
              <meta
                name="article:author"
                content={checkMetaTag(tags.og_article_author)}
              />
            )}
            {!isEmptyObj(tags.og_article_section) &&
              tags.og_article_section && (
                <meta
                  name="article:section"
                  content={checkMetaTag(tags.og_article_section)}
                />
              )}
            {!isEmptyObj(tags.og_article_tag) && tags.og_article_tag && (
              <meta
                name="article:tag"
                content={checkMetaTag(tags.og_article_tag)}
              />
            )}
          </Helmet>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaTags: state.general.metaTags,
    defaultTitle: state.general.title,
  };
};
export default connect(mapStateToProps)(MetaTags);
