import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  TextP,
  FlexRowCenterVertical,
  IconImg,
  FlexColCenterVertical,
} from 'eshop-defaults';
import { prop } from '../../utilities';
import { useState } from 'react';

interface Props {
  userFinancialData?: any;
}

function PersonsBox({ userFinancialData }: Props) {
  const [isOpenPerson1, setIsOpenPerson1] = useState(true);
  const [isOpenPerson2, setIsOpenPerson2] = useState(true);

  const [isMobileInfoOpen, setIsMobileInfoOpen] = useState(false);

  const person1Rotation = isOpenPerson1 ? 180 : 0;
  const person2Rotation = isOpenPerson2 ? 180 : 0;

  const hasSr = prop(userFinancialData, 'persons.sr');
  const srImage = prop(userFinancialData, 'persons.sr.image');
  const srName = prop(userFinancialData, 'persons.sr.@_ConInt_FName')
    ? `${prop(userFinancialData, 'persons.sr.@_ConInt_FName') || ''}  ${prop(
        userFinancialData,
        'persons.sr.@_ConInt_LName',
      ) || ''}`
    : '';
  const srPhone = prop(userFinancialData, 'persons.sr.@_ConInt_TelMob');
  const srEmail = prop(userFinancialData, 'persons.sr.@_ConInt_EMail');

  const hasDp = prop(userFinancialData, 'persons.dp');
  const dpImage = prop(userFinancialData, 'persons.dp.image');
  const dpName = prop(userFinancialData, 'persons.dp.@_ConInt_FName')
    ? `${prop(userFinancialData, 'persons.dp.@_ConInt_FName') || ''}  ${prop(
        userFinancialData,
        'persons.dp.@_ConInt_LName',
      ) || ''}`
    : '';
  const dpPhone = prop(userFinancialData, 'persons.dp.@_ConInt_TelMob');
  const dpEmail = prop(userFinancialData, 'persons.dp.@_ConInt_EMail');

  return (
    <ContactWrapper>
      {hasSr && (
        // tslint:disable-next-line:jsx-no-lambda
        <Person onClick={() => setIsOpenPerson1(!isOpenPerson1)}>
          <StyledTextP>{`${__('Obchodný zástupca')}`}</StyledTextP>
          {!isMobileInfoOpen && (
            <IconImg
              rotate={person1Rotation}
              src={'/images/dsi/arrow-down.svg'}
              alt="arrow-icon"
              width={16}
              height={16}
              cursor={'pointer'}
            />
          )}
        </Person>
      )}
      {hasSr && (
        <PersonContent isOpen={isOpenPerson1 || isMobileInfoOpen}>
          <FlexRowCenterVertical>
            <PersonImage
              alt={`${__('Obrázok používateľa')}`}
              src={'/images/dsi/img_avatar.png'}
            />
            <FlexColCenterVertical>
              <StyledTextP marginBottom={4}>{srName}</StyledTextP>
              {srPhone ? (
                <TextP>
                  <ContactPhone href={`tel:${srPhone}`}>{srPhone}</ContactPhone>
                </TextP>
              ) : null}
              {srEmail ? (
                <ContactEmailWrapper>
                  <ContactPhone href={`mailto:${srEmail}`}>
                    {srEmail}
                  </ContactPhone>
                </ContactEmailWrapper>
              ) : null}
            </FlexColCenterVertical>
          </FlexRowCenterVertical>
        </PersonContent>
      )}
      {hasDp && (
        //  tslint:disable-next-line:jsx-no-lambda
        <Person onClick={() => setIsOpenPerson2(!isOpenPerson2)}>
          <StyledTextP>{`${__('Distribučný pracovník')}`}</StyledTextP>
          {!isMobileInfoOpen && (
            <IconImg
              rotate={person2Rotation}
              src={'/images/dsi/arrow-down.svg'}
              alt="arrow-icon"
              width={16}
              height={16}
              cursor={'pointer'}
            />
          )}
        </Person>
      )}
      {hasDp && (
        <PersonContent isOpen={isOpenPerson2 || isMobileInfoOpen}>
          <FlexRowCenterVertical>
            <PersonImage
              alt={`${__('Obrázok používateľa')}`}
              src={'/images/dsi/img_avatar.png'}
            />
            <FlexColCenterVertical>
              <StyledTextP marginBottom={4}>{dpName}</StyledTextP>
              {dpPhone ? (
                <TextP>
                  {' '}
                  <ContactPhone href={`tel:${dpPhone}`}>{dpPhone}</ContactPhone>
                </TextP>
              ) : null}
              {dpEmail ? (
                <ContactEmailWrapper>
                  <ContactPhone href={`mailto:${dpEmail}`}>
                    {dpEmail}
                  </ContactPhone>
                </ContactEmailWrapper>
              ) : null}
            </FlexColCenterVertical>
          </FlexRowCenterVertical>
        </PersonContent>
      )}
    </ContactWrapper>
  );
}

const ContactEmailWrapper = styled(TextP)`
  /* overflow-wrap: break-word;
  word-break: break-word; */
`;

const ContactPhone = styled.a`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${rem(13)};
`;

const PersonImage = styled.img`
  width: ${rem(48)};
  height: ${rem(48)};
  margin-right: ${rem(16)};
  border-radius: 50%;
  flex-shrink: 0;
`;

const PersonContent = styled(FlexColCenterVertical)<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  margin-bottom: ${rem(24)};
`;

const StyledTextP = styled(TextP)<{ marginBottom: number }>`
  font-weight: 500;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? rem(marginBottom) : 0};
`;

const Person = styled(FlexRowCenterVertical)`
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  padding: ${rem(16)} 0;
  margin-bottom: ${rem(16)};
`;

const SitemapWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(328)};
  overflow-y: auto;
  padding: ${rem(16)} ${rem(32)};
  margin-bottom: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.categoryFilterBackground};

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l925`
    white-space: nowrap;
    flex-flow: row;
    margin:0;
    max-width: initial;
    margin: 0 auto;
    width: 100%;
    margin-right: -${rem(16)};
    padding: ${rem(16)};
  `}
`;

const ContactWrapper = styled(SitemapWrapper)`
  ${({ theme }) => theme.mediab.l925`
  flex-flow: column;
  background:white;
  margin-left: 0;
  width:100%;
  max-width:initial;
  `}
`;

export default PersonsBox;
