import {
  prop,
  FlexCol,
  FlexRowCenterVertical,
  TextP,
  DsiInfoText,
  DsiInfoType,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { rem } from 'polished';
import { replaceCurrentCart } from '../Cart/cartSlice';
import { __, __r } from 'react-i18n';
import { cartRoutes } from '../../utilities/cart';
import { WithRouterProps, withRouter, Link } from 'react-router';
import API from '../../services/API';
import { useInterval } from '../../utilities/hooks';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth';
import Modal from '../../components/_helpers/Default/Modal';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';
import { resolveUserArePricesLoading } from '../../utilities/user';
import { fetchCustomerFinancialDetails } from './myAccountSlice';
import App from '../App/App';

interface Props {
  user: any;
  dispatch: any;
  location: any;
  params: any;
}

function PriceUpdateInfo({
  user,
  dispatch,
  location,
  params,
}: Props & WithRouterProps) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [wasSuccess, setWasSuccess] = React.useState(false);
  const [notImported, setNotImported]: any = React.useState(null);
  const [wasUpdated, setWasUpdated]: any = React.useState(null);
  const [isHidden, setIsHidden]: any = React.useState(
    resolveUserArePricesLoading(user) ? false : true,
  );

  useInterval(async () => {
    const userToken = cookie.load(USER_COOKIE);
    if (userToken && !wasUpdated) {
      const response = await API.tokeninfo({ accessToken: userToken });
      if (!resolveUserArePricesLoading(response)) {
        // await dispatch(loginUser(cookie.load(USER_COOKIE)));
        // if (window && window.location) {
        //   window.location.reload();
        // }
        await App.getInitialProps({
          dispatch,
          token: userToken,
          user,
          location,
          forceReload: true,
          params,
        });
        setWasUpdated(true);
      }
    }
  }, 15000);

  if (isHidden) {
    return null;
  }

  return (
    <InfoWrapper>
      {wasUpdated ? (
        <DsiInfoText
          type={DsiInfoType.SUCCESS}
          info={__('Vaše ceny boli úspešne aktualizované.')}
          marginBottom={0}
          isDismissable={true}
          onClick={() => setIsHidden(true)}
        />
      ) : (
        <DsiInfoText
          type={DsiInfoType.ERROR}
          info={__(
            'Vaše ceny sú aktuálne aktualizované. Všetky funkcie stránky sú počas aktualizácie dostupné. O dokončení aktualizácie budete informovaný.',
          )}
          marginBottom={0}
        />
      )}
    </InfoWrapper>
  );
}

const InfoWrapper = styled.div`
  /* position: absolute;
  top: 0; */
  z-index: 100;
  width: 100%;
`;

const Title = styled.h4`
  font-size: ${rem(24)};
  font-weight: 400;
  line-height: ${rem(38)};
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  margin-bottom: ${rem(24)};

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

const NotImportedWrapper = styled(FlexCol)`
  max-height: ${rem(200)};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const NotImportedTitle = styled(Title)`
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.stockCountUnavailable};
`;

const NotImportedRow = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(16)};
`;

const NotImportedText = styled(TextP)`
  margin-left: ${rem(8)};
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const NotImportedOrderNr = styled(NotImportedText)``;
const NotImportedCustomOrderNr = styled(NotImportedText)``;
const NotImportedName = styled(NotImportedText)``;

const Wrapper = styled(FlexCol)`
  height: 100%;
  justify-content: space-between;
  padding: ${rem(24)};
`;

const ButtonsWrapper = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const ImportOrderButton = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(19)};
  line-height: ${rem(22)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const CloseModalButton = styled(Button)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(14)};
  padding: ${rem(8)};
  line-height: ${rem(22)};
  height: ${rem(60)};
  text-decoration: none;

  max-width: ${rem(237)};
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: ${rem(62)};
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const ImportOrderTitle = styled.span`
  margin-left: ${rem(16)};
  display: flex;
  align-items: flex-end;
`;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(PriceUpdateInfo));
