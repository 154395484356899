import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { DsiSvgIcon as SvgIcon, DsiIconType as IconType } from 'eshop-defaults';
import { css } from '../../theme/styled-components';

interface Props {
  filesToDownloads: any;
  searchTerm?: string;
}

export function CmsTable({ filesToDownloads, searchTerm = '' }: Props) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  if (!filesToDownloads || filesToDownloads.length === 0) {
    return null;
  }

  let files = filesToDownloads;

  const normalize = (str: string): string => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  if (searchTerm.trim() !== '' && searchTerm.length >= 3) {
    files = files.filter(file => {
      const term = normalize(searchTerm);
      const fileName = normalize(file.document_name);
      const fileDescription = normalize(file.document_description);
      if (fileName.includes(term) || fileDescription.includes(term)) {
        return file;
      }
    });
  }

  // const searchRegExp = /stiahnut/g;
  // let modifiedTable = table.replace(searchRegExp, icon);

  // if (searchTerm && searchTerm !== '' && searchTerm.length > 2) {
  //   // console.log(searchTerm);
  //   modifiedTable = modifiedTable.replace(new RegExp(searchTerm, "g"), `<span class="highlight">${searchTerm}</span>`);
  // }

  const downloadFile = (url: string): void => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  };

  const renderHead = () => {
    return (
      <THead>
        <TRow>
          <Th scope="col">Názov</Th>
          <Th scope="col">Popis</Th>
          <Th scope="col">Stiahnuť</Th>
        </TRow>
      </THead>
    );
  };

  const renderBody = () => {
    return (
      <tbody>
        {files.map(file => {
          const downLoadUrl = `${baseUrl}/file?query=${file.document_path}`;
          return (
            <TRow>
              <Td>{file.document_name}</Td>
              <Td>{file.document_description}</Td>
              <Td>
                <SvgIcon
                  icon={IconType.download}
                  width={24}
                  height={24}
                  viewBox="0 0 16 16"
                  fill="#414141"
                  cursor="pointer"
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => downloadFile(downLoadUrl)}
                />
              </Td>
            </TRow>
          );
        })}
      </tbody>
    );
  };

  return (
    <Wrapper>
      {renderHead()}
      {renderBody()}
    </Wrapper>
  );
}

const textLikeCss = css`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: left;
`;

const THead = styled.thead`
  background: ${({ theme }) => rgba(theme.colors.white, 0.32)};
`;

const TRow = styled.tr`
  border-bottom: ${rem(2)} solid #f9f9f9;
`;

const tdLikeCss = css`
  padding: ${rem(18)} ${rem(24)};
  ${textLikeCss};
`;

const Th = styled.th`
  ${tdLikeCss};
  font-weight: 500;
`;

const Td = styled.td`
  ${tdLikeCss};
  background: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.table`
  margin-top: ${rem(16)};
  margin-bottom: 0;
  border-collapse: collapse;
  min-width: 100%;
  table-layout: auto;

  ${TRow} {
    ${Th}:last-child,
    ${Td}:last-child {
      text-align: center;
    }
  }
`;
