import * as React from 'react';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { prop, getAuthorUrl, formatDate, stripHtmlTags } from '../../utilities';
import styled from 'styled-components';
import { ArticleMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { __ } from 'react-i18n';
import { resolveArticleContentDetails } from '../../utilities/article';
import SliderComponent from '../../components/_helpers/Slider/Slider';
import { getCmsMetaTags } from '../../utilities/metatags';
import { getImagePath } from '../../utilities/product';
import { rem } from 'polished';
import { FlexRowCenter, FlexColCenter, FlexCol } from 'eshop-defaults';
import ToTopButton from '../../components/_helpers/Default/ToTopButton';
import { Link } from 'react-router';
import LazyLoad from 'react-lazyload';
import {
  DesktopWrapper,
  MobileWrapper,
} from '../../components/_helpers/Default/ResponsiveWrappers';
import { Picture } from '../../components/_helpers/Image/Picture';
import ShareWrapper from '../../components/_helpers/Default/ShareWrapper';
import RenderedBody from '../../components/Article/RenderedBody';
import ParallaxImage from '../../components/Article/ParallaxImage';
import { langChangedSelector } from '../App/selectors';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { setAdminContentPath, setIsBreadcrumbVisible } from '../App/actions';
import { DEFAULT_CATEGORY_ID } from '../../configureTrans';
import ArticleTags from '../../components/Article/ArticleTags';

export interface CmsProps {
  sitemap: any;
  sitemapTree: any;
  parentSitemap: any;
  isError: boolean;
  defaultTitle: string;
  articles: any;
  isArticle: boolean;
  contentData: ThenArg<typeof API.loadArticleDetail>;
  draftContent: boolean;
  products: any;
  lang: string;
  langChanged: boolean;
  user: any;
  dispatch: (action: any) => void;
}

const TitleImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
    max-width: 100%;
  `}
`;

const TitleImage = styled.img`
  max-height: ${rem(600)};
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
    max-width: 100%;
  `}
`;

const CmsContent = styled.div`
  line-height: 21px;
`;

const Annotation = styled(CmsContent)`
  max-width: ${rem(740)};
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${rem(26)};

  overflow: hidden;
  position: relative;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
`;

const CmsTitle = styled.h1`
  margin: 0;
  margin-bottom: ${rem(48)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  font-size: ${rem(36)};
  color: ${({ theme }) => theme.blogColors.primary};
  text-align: center;
  line-height: 48px;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  `}
`;

const MainContent = styled.div`
  max-width: ${rem(1020)};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(30)};
  padding-bottom: ${rem(16)};
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.m580`
     padding: 0 ${rem(16)};
  `}
`;

const AuthorAnnotWrapper = styled(FlexRowCenter)`
  margin-top: ${rem(48)};
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    margin: ${rem(32)} 0 ;
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const AuthorWrapper = styled(FlexColCenter)`
  padding: 0 ${rem(32)};
  margin-right: ${rem(32)};
  border-right: ${({ withannot, theme }) =>
    withannot ? `${rem(1)} solid #EEEEEE` : 0};

  ${({ theme }) => theme.mediab.m580`
     flex-flow: row;
     padding: 0;
     border: 0;
     margin-bottom: ${rem(32)};
  `}
`;

const AuthorImage = styled.img`
  width: ${rem(80)};
  height: ${rem(80)};
  border-radius: 50%;

  ${({ theme }) => theme.mediab.m580`
    width: ${rem(40)};
    height: ${rem(40)};
    margin-right: ${rem(8)};
  `}
`;

const SliderWrapper = styled.div`
  /* width: 1000px; */
  margin: auto;
  margin-top: ${rem(48)};
  /* @media only screen and (max-width: 1130px) {
    width: 800px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 650px) {
    width: 90vw;
  }
  @media only screen and (max-width: 380px) {
    width: 80vw;
  } */
`;

const Also = styled.p`
  text-align: left;
  font: 24px/32px Tahoma;
  font-weight: 500;
  letter-spacing: 0;
  color: #414141;
  opacity: 1;
  margin-top: ${rem(36)};

  @media only screen and (max-width: 1130px) {
    padding-left: 30px;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 10px;
  }

  @media only screen and (max-width: 580px) {
    padding-left: 0;
  }
`;

const NameDateWrapper = styled(FlexCol)`
  text-align: center;

  ${({ theme }) => theme.mediab.m580`
   flex-flow: column;
   text-align: left;
   margin-left: ${rem(16)};
  `}
`;

const NotFoundArticle = styled.p`
  color: indianred;
  margin: 0 auto;
  padding: ${rem(24)};
  font-size: ${rem(24)};
`;

const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.blogColors.primary};
`;

const AuthorName = styled(Text)`
  font-weight: 500;
  margin-bottom: ${rem(8)};
  margin-top: ${rem(16)};
  text-align: center;

  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
    margin-bottom: ${rem(4)};
  `}
`;

const AuthorNameLink = styled(Link)`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  font-weight: 500;
  margin-bottom: ${rem(8)};
  margin-top: ${rem(16)};
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.blogColors.primary};

  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
    margin-bottom: ${rem(4)};
  `}
`;

const AuthorDate = styled(Text)`
  color: ${({ theme }) => theme.colors.inactiveFontColor};
  font-size: ${rem(16)};
  font-weight: 400;
  line-height: ${rem(20)};
`;

const OpacityWrapper = styled.div`
  margin-top: ${rem(80)};
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );

  height: ${rem(950)};
  overflow: hidden;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m810`
    margin-top: 0;
    height: ${rem(800)};
  `}

  ${({ theme }) => theme.mediab.m580`
    margin-top: 0;
    height: ${rem(500)};
  `}
`;

const ReadNextButton = styled(Link)`
  background-color: transparent;
  color: ${({ theme }) => theme.blogColors.primary};
  font-weight: 500;
  padding: ${rem(16)};
  border: ${rem(1)} solid #606060;
  width: initial;
  margin: 0 auto;
  text-decoration: none;
  max-width: ${rem(215)};
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(48)};
`;

const NextArticleImage = styled(TitleImage)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-bottom: ${rem(48)};

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
    max-width: 100%;
  `}
`;

const NextArticleWrapper = styled(MainContent)``;

const NextArticleCmsTitle = styled.h2`
  margin: 0;
  margin-bottom: ${rem(52)};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.blogFonts.primaryFont};
  font-weight: 500;
  font-size: ${rem(36)};
  color: ${({ theme }) => theme.blogColors.primary};
  text-align: center;
  line-height: 48px;

  ${({ theme }) => theme.mediab.m580`
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  `}
`;

const TitleMarginSpacer = styled.div`
  height: 0;
  margin-bottom: 3rem;
`;

class Cms extends React.Component<
  CmsProps,
  { photoIndex: number; isOpen: boolean; galleryPhotos: any[] }
> {
  public static async getInitialProps({
    sitemapId,
    contentId,
    lang,
    dispatch,
  }) {
    try {
      const returnProps: any = {};
      returnProps.articles = [];
      let articleDetail;

      if (!contentId && sitemapId) {
        const sitemapItemDetail = await API.loadSitemap(
          sitemapId,
          { withLangVersions: '1' },
          { xAcceptLanguage: lang },
        );

        articleDetail = sitemapItemDetail.content;
        articleDetail.lang_versions = (sitemapItemDetail as any).lang_versions;
        returnProps.isArticle = false;
      } else {
        articleDetail = await API.loadArticleDetail(
          contentId,
          {
            withNextArticle: '1',
            withLangVersions: '1',
            productCategoryId: DEFAULT_CATEGORY_ID[lang],
            replaceCurrentLangTags: '1',
            withCanonicalUrl: '1',
          },
          { xAcceptLanguage: lang },
        );
        returnProps.isArticle = true;
      }

      if (returnProps.isArticle) {
        // const articleProducts = await API.loadContentConnections(
        //   contentId,
        //   {
        //     connectionType: 'PRODUCT',
        //   },
        //   { xAcceptLanguage: lang },
        // );
        // returnProps.products = articleProducts?.web_content_connections;
      }
      returnProps.contentData = articleDetail;

      dispatch(
        setAdminContentPath(
          `/admin/eshop/cms/contentItem?citem_id=${articleDetail?.id}`,
        ),
      );

      if (prop(returnProps, 'contentData.sf_form') !== 'Article') {
        dispatch(setIsBreadcrumbVisible(false));
      } else {
        dispatch(setIsBreadcrumbVisible(false));
      }
      return returnProps;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public constructor(props) {
    super(props);
  }

  public componentWillUnmount() {
    this.props.dispatch(setIsBreadcrumbVisible(true));
    this.props.dispatch(setAdminContentPath(null));
  }

  public render() {
    // if (this.props.draftContent) {
    //   this.props.dispatch(push('/'));
    //   return null;
    // } else {
    const {
      contentData,
      isArticle,
      isError,
      products,
      lang,
      langChanged,
    } = this.props;

    const tags = prop(contentData, 'tags');
    const lastUpdate = prop(contentData, 'last_update');
    const createdAt = prop(contentData, 'created_date');
    const author = prop(contentData, 'author');
    const {
      name: authorName = '',
      url: authorUrl = '',
      image: authorImage = '',
      author_id = '',
    } = author || {};
    const nextArticle = prop(contentData, 'next_article');
    const nextAuthorName = prop(nextArticle, 'author.name', '');

    const nextAuthorImage = prop(nextArticle, 'author.image', '');
    const article = prop(contentData, 'json_content');
    const photogallery = prop(contentData, 'photogallery');

    let titleImage = '';
    if (photogallery) {
      titleImage = prop(photogallery[0], 'document_path');
    }
    const { name, body, annotation, anotation } = resolveArticleContentDetails(
      contentData,
      this.props.user,
    );

    const isBlogArticle = prop(contentData, 'sf_form') === 'Article';

    if (!contentData) {
      return (
        <NotFoundArticle>
          {__('Tento článok nebolo možné nájsť')}
        </NotFoundArticle>
      );
    }

    return (
      <React.Fragment>
        {isError && !contentData ? (
          <NotFoundArticle>
            {__('Tento článok nebolo možné nájsť')}
          </NotFoundArticle>
        ) : (
          <React.Fragment>
            <MetaTags
              tags={getCmsMetaTags(contentData)}
              canonicalUrl={contentData.canonical_url}
              hrefLangs={(contentData as any)?.lang_versions}
            />
            <ArticleMicrodata
              headline={name}
              image={
                titleImage
                  ? getImagePath(titleImage, { width: 1920, height: 600 }, true)
                  : ''
              }
              datePublished={
                createdAt ? formatDate(createdAt, 'MM/DD/YYYY') : ''
              }
              description={stripHtmlTags(annotation)}
              dateModified={
                lastUpdate ? formatDate(lastUpdate, 'MM/DD/YYYY') : ''
              }
              author={author}
            />
            {titleImage && (
              <ParallaxImage
                articleTitle={true}
                src={getImagePath(
                  titleImage,
                  { width: 1920, height: 600 },
                  true,
                )}
              />
            )}
            <MainContent>
              {(!isArticle || !titleImage) && <TitleMarginSpacer />}
              <CmsTitle>{name}</CmsTitle>
              <DesktopWrapper>
                <ArticleTags articleDetail={true} tags={tags} />
              </DesktopWrapper>
              <MobileWrapper>
                <ArticleTags mobile={true} articleDetail={true} tags={tags} />
              </MobileWrapper>
              {authorName || annotation || anotation ? (
                <AuthorAnnotWrapper>
                  <AuthorWrapper
                    withannot={
                      (annotation && annotation !== '') ||
                      (anotation && anotation !== '')
                    }
                  >
                    {authorName !== '' && authorImage !== '' && (
                      <LazyLoad>
                        {false && (
                          <AuthorImage
                            alt={`Fotka ${authorName}`}
                            src={getImagePath(authorImage, {
                              width: 80,
                              height: 80,
                            })}
                          />
                        )}
                        <Picture
                          circle={true}
                          alt={`Fotka ${authorName}`}
                          image={authorImage}
                          width={80}
                          height={80}
                        />
                      </LazyLoad>
                    )}
                    <NameDateWrapper>
                      {authorName !== '' && (
                        <AuthorNameLink
                        // TODO po doplnenie odkomentovat
                        // to={getAuthorUrl(author_id, authorUrl)}
                        >
                          {authorName}
                        </AuthorNameLink>
                      )}
                      {isBlogArticle && (
                        <AuthorDate>
                          {formatDate(createdAt, 'DD.MM.YYYY')}
                        </AuthorDate>
                      )}
                    </NameDateWrapper>
                  </AuthorWrapper>
                  {((annotation && annotation !== '') ||
                    (anotation && anotation !== '')) && (
                    <Annotation
                      dangerouslySetInnerHTML={{
                        __html: annotation || anotation,
                      }}
                    />
                  )}
                </AuthorAnnotWrapper>
              ) : null}
              <RenderedBody inputBody={body} articleTitle={name} />
              {contentData &&
                contentData.products &&
                contentData.products.length > 0 && (
                  <SliderWrapper>
                    <Also>{__('Súvisiace produkty')}</Also>
                    <SliderComponent
                      type="blog-product"
                      slides={contentData.products}
                    />
                  </SliderWrapper>
                )}

              {isArticle && <ShareWrapper title={name} desc={''} />}
            </MainContent>
            {/* <ToTopButton /> */}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  defaultTitle: state.general.title,
  lang: state.general.lang,
  langChanged: langChangedSelector(state),
});

export default connect(mapStateToProps)(Cms);
