import {
  RECEIVE_HOME_BANNER_SUCCESS,
  RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  REQUEST_HOME_PRODUCT_LIST2,
  RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  REQUEST_HOME_PRODUCT_LIST1,
  RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  REQUEST_HOME_CATEGORIES,
  RECEIVE_HOME_CATEGORIES_SUCCESS,
  RECEIVE_HOME_CATEGORIES_ERROR,
  REQUEST_REVIEWS,
  RECEIVE_REVIEWS_SUCCESS,
  RECEIVE_REVIEWS_ERROR,
  REQUEST_THEMES,
  RECEIVE_THEMES_SUCCESS,
  RECEIVE_THEMES_ERROR,
  RECEIVE_BLOG_ARTICLES_ERROR,
  RECEIVE_BLOG_ARTICLES_SUCCESS,
  REQUEST_BLOG_ARTICLES,
} from './constants';

const INITIAL_STATE: any = {
  newProducts: {
    isFetching: false,
    productsData: null,
  },
  saleProducts: {
    isFetching: false,
    productsData: null,
  },
  adBanner: null,
  topBrands: null,
  homeCategories: null,
  reviews: null,
  articles: null,
};

export const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_HOME_PRODUCT_LIST1: {
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST1_SUCCESS: {
      const productsData = {
        ...state.productList1.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST1_ERROR: {
      return {
        ...state,
        productList1: {
          ...state.productList1,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_HOME_PRODUCT_LIST2: {
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: true,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST2_SUCCESS: {
      const productsData = {
        ...state.productList2.productsData,
        [action.payload.categoryId]: { ...action.payload.products },
      };
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: false,
          productsData,
        },
      };
    }

    case RECEIVE_HOME_PRODUCT_LIST2_ERROR: {
      return {
        ...state,
        productList2: {
          ...state.productList2,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_HOME_BANNER_SUCCESS: {
      return {
        ...state,
        adBanner: action.payload.banner,
      };
    }

    case RECEIVE_HOME_TOP_BRANDS_SUCCESS: {
      return {
        ...state,
        brands: action.payload.topBrand,
      };
    }

    case REQUEST_HOME_CATEGORIES: {
      return {
        ...state,
        categoriesIsFetching: true,
      };
    }

    case RECEIVE_HOME_CATEGORIES_SUCCESS: {
      return {
        ...state,
        homeCategories: action.payload.homeCategories,
      };
    }

    case RECEIVE_HOME_CATEGORIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case REQUEST_REVIEWS: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_REVIEWS_SUCCESS: {
      return {
        ...state,
        articlesIsFetching: false,
        reviews: action.payload.reviews,
      };
    }

    case RECEIVE_REVIEWS_ERROR: {
      return {
        ...state,
        articlesIsFetching: false,
        error: action.payload.error,
      };
    }

    case REQUEST_THEMES: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_THEMES_SUCCESS: {
      return {
        ...state,
        articlesIsFetching: false,
        themes: action.payload.themes,
      };
    }

    case RECEIVE_THEMES_ERROR: {
      return {
        ...state,
        articlesIsFetching: false,
        error: action.payload.error,
      };
    }

    case REQUEST_BLOG_ARTICLES: {
      return {
        ...state,
        articlesIsFetching: true,
      };
    }

    case RECEIVE_BLOG_ARTICLES_SUCCESS: {
      return {
        ...state,
        articlesIsFetching: false,
        articles: action.payload.articles,
      };
    }

    case RECEIVE_BLOG_ARTICLES_ERROR: {
      return {
        ...state,
        articlesIsFetching: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};
