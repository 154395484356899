import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiPayables as Payables,
  PaginationSimple,
  Loader as DefaultLoader,
  TextP,
} from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { LoadAllButton } from './MyInvoices';

interface Props {
  orders: any;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  currency: string;
  refetchData: any;
  downloadFile: any;
  isDownloading: boolean;
}

function MyPayables({
  currentId,
  orders,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  currency,
  refetchData,
  downloadFile,
  isDownloading,
}: Props) {
  const loadAllData = () => {
    refetchData({
      limit: prop(ordersData, 'total'),
      type: prop(ordersData, 'type'),
    });
  };

  React.useEffect(() => {
    if (isDownloading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isDownloading]);

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Záväzky')} (${prop(ordersData, 'total') || 0})`}
      </StyledTitle>
      {options && options.length > 1 && (
        <>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <br />
        </>
      )}
      <Payables
        currency={currency}
        isFetching={isFetchingOrders}
        orders={orders}
        refetchData={refetchData}
        downloadFile={downloadFile}
      />
      {prop(ordersData, 'total') > prop(ordersData, 'limit') && (
        <PaginationSimple
          totalItems={prop(ordersData, 'total')}
          limit={prop(ordersData, 'limit')}
          offset={prop(ordersData, 'offset')}
          handleOffsetChange={handleOffsetChange}
        />
      )}
      {prop(ordersData, 'total') > prop(ordersData, 'limit') && (
        <LoadAllButton onClick={loadAllData}>
          {__('Zobraziť všetky')}
        </LoadAllButton>
      )}

      {isDownloading && (
        <LoaderWrapper>
          <DefaultLoader
            style={{
              position: isDownloading ? 'fixed' : 'initial',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'rgba(0, 0, 0, 0)',
            }}
            circleStyle={{ position: 'fixed', top: '53%' }}
            loading={isDownloading}
            dim={false}
          >
            <TextP
              style={{
                position: 'fixed',
                top: '64%',
                width: '100%',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 700,
              }}
            >
              <span
                style={{
                  background: 'white',
                  padding: '0.5rem',
                  borderRadius: '4px',
                }}
              >
                {__('Pracujeme na príprave Vašej faktúry')}
              </span>
            </TextP>
          </DefaultLoader>
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const LoaderWrapper = styled(FlexCol)``;

export default MyPayables;
