import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  DsiFooterRowWrapper as FooterRowWrapper,
  TextP,
  FlexBetween,
  FlexCol,
  DsiFooterItem as FooterItem,
  DsiFooterMenuCol as FooterMenuCol,
  FlexRow,
  DsiNewsletterInput as NewsletterInput,
  DsiButton as Button,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n';
import { prop } from '../../utilities';
import { Icon } from 'eshop-defaults';

const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${({ theme }) => theme.colors.footer};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  /* margin-top: ${rem(40)}; */
  @media print {
    display: none;
  }
  ${({ theme }) => theme.mediab.m580`
   margin-top: 0;
`};
`;

const ContactWrapper = styled(FlexCol)`
  width: ${rem(344)};
  margin-left: ${rem(40)};
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: ${rem(24)};

  ${({ theme }) => theme.mediab.l925`
    width: 50%;
    border: 0;  
    margin: 0 auto;
    padding-bottom: 0;
    padding-top: ${rem(32)};
  `}
  ${({ theme }) => theme.mediab.m600`
    width: 100%;
    padding-top: ${rem(24)};
  `}
`;

const MenuMapWrapper = styled(FlexBetween)`
  align-items: flex-start;
  width: 100%;

  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;  
  `}
`;

const MenuWrapper = styled(FlexRow)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding-top: ${rem(40)};
  border-top: ${({ theme }) => theme.borders.footer};

  ${({ theme }) => theme.mediab.l925`
    width: 100%;  
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.m600`
    flex-flow: column;  
  `}
`;

const NeedHelp = styled(FlexRowCenterVertical)`
  margin-right: ${rem(16)};

  ${({ theme }) => theme.mediab.l925`
      width: 100%;
      text-align: center;
      flex-direction: column;
      padding-bottom: ${rem(16)};
      padding-left: ${rem(16)};
      margin-bottom: ${rem(16)};
      margin-right: 0;
      border-bottom: ${({ theme }) => theme.borders.footer};
  `};
  ${({ theme }) => theme.mediab.m600`
      text-align: left;
  `};
`;

const FooterImg = styled.img`
  height: ${rem(24)};
  margin-left: ${rem(32)};
`;

const SmallTextP = styled(TextP)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.footerLink};
  line-height: ${rem(17)};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(8)};
  `};

  ${({ theme }) => theme.mediab.m580`
    text-align: center;
  `}
`;

const SmallTextA = styled.a`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.footerLink};
  line-height: ${rem(17)};
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const SmallTextLink = styled(Link)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.footerLink};
  line-height: ${rem(17)};
  margin: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    text-align: center;
    margin-bottom: ${rem(16)};
  `}

  ${({ theme }) => theme.mediab.m600`
    width: 100%;
    text-align: left;
  `}
`;

const Delimeter = styled.span`
  margin: 0 ${rem(12)};
  height: 100%;
  border-right: ${({ theme }) => theme.borders.footer};
  display: inline-block;

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}

  ${({ theme }) => theme.mediab.m580`
    margin: 0 ${rem(8)};
  `}
`;

const ContactItem = styled(FlexRow)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(40)};

  ${({ theme }) => theme.mediab.l925`
    display: none;  
  `}
`;

const ContactTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(16)};
`;

const ContactTitleImg = styled(FooterImg)`
  margin-left: 0;
  margin-right: ${rem(8)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.footerLink};
`;

const ContactTextP = styled(SmallTextA)`
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  margin-left: ${rem(32)};
  margin-top: ${rem(8)};
`;

const ContactLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.footerButton};
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

const ButtonContent = styled(Button)`
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.footerButton};
  box-shadow: 0px 1px 4px #00000014;
  border-radius: 4px;
  transition: filter 0.2s ease;
  padding: ${rem(8)} ${rem(24)};
  font-size: ${rem(16)};
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    filter: brightness(90%);
  }
`;

const ButtonText = styled(ContactTextP)`
  font-size: ${rem(16)};
  font-weight: 500;
  margin-left: ${rem(16)};
  font-size: ${rem(16)};
  font-weight: 500;
  margin-top: 0;
`;

const ButtonImg = styled(ContactTitleImg)`
  margin-right: 0;
`;

const Visa = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
      margin-top: ${rem(16)};
  `};
`;

const ThirdRow = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
      flex-direction: column;
  `};
`;

const NewsletterWrapper = styled(MenuWrapper)`
  width: 100%;
  padding-bottom: ${rem(40)};
  border: 0;
  padding-top: 0;
  flex-direction: column;
  ${({ theme }) => theme.mediab.l925`
    text-align: center;
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
  `};
`;

const NewsletterTitle = styled(Title)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
`;

const LeftFirstRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediab.l925`
    width: 100%;
    border-bottom: ${({ theme }) => theme.borders.footer};
  `};
`;

const StyledNewsletterInput = styled(NewsletterInput)`
  padding-left: ${rem(16)};
  margin-right: ${rem(8)};
`;

const NewsletterSubmit = styled(Button)`
  background-color: ${({ theme }) => theme.colors.footerButton};
  box-shadow: 0px 1px 4px #00000014;
  border-radius: 4px;
  transition: filter 0.2s ease;

  padding: ${rem(8)} ${rem(24)};
  font-size: ${rem(16)};
  font-weight: 500;
  &:hover {
    filter: brightness(90%);
  }
  ${({ theme }) => theme.mediab.l925`
    margin-top: ${rem(8)};
  `};
`;

const NewsletterInputWrapper = styled.div`
  width: ${rem(581)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${rem(24)} 0;

  ${({ theme }) => theme.mediab.l925`
    width: ${rem(460)}; 
    flex-direction: column;
    margin: ${rem(24)} auto;
  `};
  ${({ theme }) => theme.mediab.m600`
    width: 100%;
    margin: ${rem(24)} auto};
  `};
`;

const SmallLinkUnderlined = styled(Link)`
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.font.tertiary};
  color: ${({ theme }) => theme.colors.footerLink};
  text-decoration: underline;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;
  `};
`;

const PaymentsWrapper = styled.div`
  display: flex;
  margin: ${rem(8)} 0;

  ${({ theme }) => theme.mediab.l925`
      order: 1;
      margin-top: ${rem(32)};
  `};
`;

const PaymentIcon = styled(Icon)`
  height: ${rem(30)};
  margin-right: ${rem(10)};
`;

interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  email: string;
  shopEmail: string;
  shopPhone: string;
  socialUrls: any;
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    return (
      <FooterWrapper>
        {this.renderFirstRow()}
        {this.renderSecondRow()}
        {this.renderThirdRow()}
      </FooterWrapper>
    );
  }

  private renderFirstRow = () => {
    const { items, socialUrls }: any = this.props;
    const help = '/images/icons/outline-help.svg';
    const info = '/images/icons/outline-info.svg';
    const message = '/images/icons/message.svg';
    const { phone, email, shopEmail, shopPhone } = this.props;
    if (!items) {
      return null;
    }

    return (
      <FooterRowWrapper>
        {' '}
        <MenuMapWrapper>
          <LeftFirstRow>
            <NewsletterWrapper>
              {/* <NewsletterTitle>
                {__(
                  'Dostávajte najčerstvejšie informácie zo sveta technológií',
                )}
              </NewsletterTitle>
              <NewsletterInputWrapper>
                <StyledNewsletterInput
                  isMobile={true}
                  name="q"
                  type="text"
                  placeholder={__('E-mailová adresa')}
                  value={''}
                  onFocus={1}
                  onChange={1}
                />
                <NewsletterSubmit type="submit" onClick={1}>
                  {__('Odoberať')}
                </NewsletterSubmit>
              </NewsletterInputWrapper>
              <SmallTextP>
                {__('Odoslaním e-mailu súhlasite s podmienkami o ')}{' '}
                <SmallLinkUnderlined
                  to={__('/spodne-menu/ochrana-osobnych-udajov')}
                >
                  {__('ochrane osobných údajov')}
                </SmallLinkUnderlined>
                {'.'}
              </SmallTextP>
              <SmallTextP>
                {__('Zo zasielania sa môžete kedykoľvek odhlásiť.')}
              </SmallTextP> */}
            </NewsletterWrapper>
            <MenuWrapper>
              {items &&
                items.map((item: any) =>
                  item.children.length ? (
                    <FooterMenuCol
                      key={item.id}
                      title={item.name}
                      url={item.url}
                    >
                      {item.children &&
                        item.children.map(child => (
                          <FooterItem
                            key={child.id}
                            url={`/${child.url}`}
                            title={child.name}
                          />
                        ))}
                    </FooterMenuCol>
                  ) : null,
                )}
              <FooterMenuCol key={'4'} title={__('Sledujte nás')} url={''}>
                {prop(socialUrls, 'instagramUrl') ? (
                  <FooterItem
                    key={1}
                    url={prop(socialUrls, 'instagramUrl')}
                    title={'Instagram'}
                    iconSrc={'/images/icons/instagram.svg'}
                    isClassicLink={true}
                  />
                ) : null}
                {prop(socialUrls, 'youtubeUrl') ? (
                  <FooterItem
                    key={2}
                    url={prop(socialUrls, 'youtubeUrl')}
                    title={'YouTube'}
                    iconSrc={'/images/icons/youtube.svg'}
                    isClassicLink={true}
                  />
                ) : null}
                {prop(socialUrls, 'fbUrl') ? (
                  <FooterItem
                    key={3}
                    url={prop(socialUrls, 'fbUrl')}
                    title={'Facebook'}
                    iconSrc={'/images/blog_assets/facebook.svg'}
                    isClassicLink={true}
                  />
                ) : null}
              </FooterMenuCol>
            </MenuWrapper>
          </LeftFirstRow>
          <ContactWrapper>
            <ContactItem>
              <ContactTitle>
                <ContactTitleImg src={help} alt="pomoc" />
                <Title>{__('Podpora')}</Title>
              </ContactTitle>
              <ContactTextP href={`tel:${phone}`}>{phone}</ContactTextP>
              <ContactTextP href={`mailto:${email}`}>{email}</ContactTextP>
            </ContactItem>
            <ContactItem>
              <ContactTitle>
                <ContactTitleImg src={info} alt="info" />
                <Title>{__('Informácie o objednávkach')}</Title>
              </ContactTitle>
              <ContactTextP href={`tel:${shopPhone}`}>{shopPhone}</ContactTextP>
              <ContactTextP href={`mailto:${shopEmail}`}>
                {shopEmail}
              </ContactTextP>
            </ContactItem>
            {/* <ButtonContent>
              <ButtonImg src={message} alt="správa" />
              <ButtonText>{__('Napíšte nám')}</ButtonText>
            </ButtonContent> */}
          </ContactWrapper>
        </MenuMapWrapper>
      </FooterRowWrapper>
    );
  };

  private renderSecondRow = () => {
    const visaSrc = '/images/icons/visa.svg';
    const masterCardSrc = '/images/icons/path_570.svg';

    return (
      <FooterRowWrapper>
        <NeedHelp>
          <SmallTextLink
            to={__r('/ochrana-osobnych-udajov', '/ochrana-osobnych-udajov')}
          >
            {__('Ochrana osobných údajov')}
          </SmallTextLink>
          {/* <Delimeter />
          <SmallTextLink to={__r('/mapa-stranok', '/mapa-stranok')}>
            {__('Mapa stránok')}
          </SmallTextLink> */}
        </NeedHelp>
        {/* <Visa>
          <FooterImg src={visaSrc} alt="visa" />
          <FooterImg src={masterCardSrc} alt="mastercard" />
        </Visa> */}
      </FooterRowWrapper>
    );
  };

  private renderThirdRow = () => {
    return (
      <FooterRowWrapper>
        <ThirdRow>
          <SmallTextP>{__('Copyright © 2021 DSI Slovakia')}</SmallTextP>
          <Delimeter />
          <SmallTextA href="https://www.bart.sk/" target="_blank">
            {__('Vytvoril bart.sk')}
          </SmallTextA>
        </ThirdRow>

        <RightWrapper>{this.renderPaymentIcons()}</RightWrapper>
      </FooterRowWrapper>
    );
  };

  private renderPaymentIcons = () => {
    return (
      <PaymentsWrapper>
        <PaymentIcon src="/images/payment_home/cg-hor.svg" />
        <PaymentIcon src="/images/payment_home/Visa_Brandmark_Blue_RGB_2021.svg" />
        <PaymentIcon src="/images/payment_home/mc_symbol.svg" />
        <PaymentIcon src="/images/payment_home/google-pay.svg" />
        <PaymentIcon src="/images/payment_home/apple-pay.svg" />
      </PaymentsWrapper>
    );
  };
}

export default Footer;
