import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  TextP,
  FlexRowCenterVertical,
  IconImg,
  FlexColCenterVertical,
  DsiSvgIcon,
  DsiIconType,
} from 'eshop-defaults';
import { prop } from '../../utilities';
import { useState } from 'react';

interface Props {
  xmlUrl: string;
  lang: string;
}

function XmlBox({ xmlUrl, lang }: Props) {
  return (
    <ContactWrapper>
      <XmlLink href={xmlUrl}>{__('Váš XML súbor')}</XmlLink>
      <XmlManualWrapper
        target="_blank"
        rel="noreferrer"
        href={
          lang === 'cz'
            ? 'https://www.edsi.sk/api/v1/file?query=media/567758/xml-cz.pdf'
            : 'https://www.edsi.sk/api/v1/file?query=media/567757/xml-sk.pdf'
        }
      >
        <DsiSvgIcon
          icon={DsiIconType.inwork}
          alt={__('Manuál k XML')}
          viewBox={'0 0 24 24'}
          width={24}
          height={24}
          cursor={'pointer'}
          // tslint:disable-next-line:jsx-no-lambda
          fill="##414141"
        />
        <XmlManualText>{__('Manuál k XML')}</XmlManualText>
      </XmlManualWrapper>
    </ContactWrapper>
  );
}

const SitemapWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(328)};
  overflow-y: auto;
  padding: ${rem(16)} ${rem(32)};
  margin-bottom: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.categoryFilterBackground};

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l925`
    white-space: nowrap;
    flex-flow: row;
    margin:0;
    max-width: initial;
    margin: 0 auto;
    width: 100%;
    margin-right: -${rem(16)};
    padding: ${rem(16)};
  `}
`;

const ContactWrapper = styled(SitemapWrapper)`
  ${({ theme }) => theme.mediab.l925`
  flex-flow: column;
  background:white;
  margin-left: 0;
  width:100%;
  max-width:initial;
  `}
`;

const XmlTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${rem(20)};
  font-weight: 500;
  margin-bottom: ${rem(24)};
`;

const XmlLink = styled.a`
  text-decoration: underline;
  width: fit-content;
  word-break: break-word;

  &:hover {
    opacity: 0.7;
  }
`;

const XmlManualWrapper = styled.a`
  display: flex;
  flex-flow: row;
  margin-top: ${rem(24)};
`;

const XmlManualText = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
  margin-left: ${rem(8)};
`;

export default XmlBox;
