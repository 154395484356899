import * as React from 'react';
import { connectSsr } from 'ssr-service';
import NotFound from '../Errors/NotFound';
import API from '../../services/API';
import ProductContainer, { ProductProps } from '../Product/Product';
import styled from 'styled-components';
import Category, { CategoryProps } from '../Category/Category';
import CMS, { CmsProps } from '../CMS/CMS';
import { connect } from 'react-redux';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import TagResults from '../TagResults/TagResults';
import {
  HorizontalCenterLoader,
  LoaderWrapper,
} from '../../components/_helpers/Loader/Loader';
import {
  resolveDomainByHostname,
  resolveLangByHostname,
} from '../../configureTrans';
import { setNotFound } from '../App/actions';
import Redirect from '../Errors/Redirect';

enum UrlType {
  Product,
  NotFound,
  Category,
  CategoryWithSubcategories,
  Sitemap,
  SitemapWithContent,
  Printer,
  Tag,
  FooterArticle,
  Redirect,
}

interface Props {
  urlType: UrlType;
  isPreview: boolean;
  childProps: object;
  loading: boolean;
  isError: boolean;
  token: any;
  lang: string;
}

interface ChildProps {
  urlType: UrlType;
  childProps: ProductProps | CategoryProps | null;
}

class UrlMapper extends React.Component<Props, {}> {
  public static defaultProps = {
    loading: true,
    isError: false,
  };

  public static async getInitialProps(props) {
    let newProps: ChildProps;
    let isPreview: boolean = false;

    const correctLang = props.lang || resolveLangByHostname(props.hostname);
    const pathnameDecoded = decodeURI(
      props.location.pathname.replace('/preview/', ''),
    );

    try {
      // isPreview = UrlMapper.isPreview(props.location.pathname);
      const urlMapData = await API.getUrlMap(
        {
          url: pathnameDecoded,
          domainId: resolveDomainByHostname(props.hostname),
          noTag: '1',
        },
        { xAcceptLanguage: correctLang },
      );

      const { data, type } = urlMapData;

      switch (type) {
        case 'product_gift':
        case 'product': {
          newProps = await UrlMapper.getProductChildProps(
            data.product_id,
            props,
            type === 'product_gift',
          );
          break;
        }
        case 'category': {
          newProps = await UrlMapper.getCategoryChildProps();
          break;
        }
        case 'sitemap': {
          newProps = await UrlMapper.getSitemapWithContentChildProps(
            data.sitemap_id,
            0,
            props.lang,
            true,
            props.dispatch,
          );
          // if (
          //   data &&
          //   data.sitemap_id &&
          //   data.sitemap_id >= 139 &&
          //   data.sitemap_id <= 141
          // ) {
          //   newProps = await UrlMapper.getFooterContentChildProps(
          //     data.sitemap_id,
          //     props,
          //   );
          // } else {
          //   newProps = await UrlMapper.getSitemapChildProps(
          //     data.sitemap_id,
          //     props,
          //   );
          // }
          break;
        }
        case 'sitemap_with_content': {
          newProps = await UrlMapper.getSitemapWithContentChildProps(
            null,
            data.content_id,
            props.lang,
            false,
            props.dispatch,
          );
          break;
        }
        case 'redirect': {
          newProps = await UrlMapper.getRedirectChildProps(urlMapData);
          break;
        }
        default: {
          await props.dispatch(setNotFound(true));
          newProps = UrlMapper.getNotFoundChildProps();
          break;
        }
      }
    } catch (exp) {
      await props.dispatch(setNotFound(true));
      return {
        isError: true,
      };
    }
    return { ...newProps, isPreview };
  }

  private static getProductChildProps = async (productId, props, isGift) => {
    const productProps = {
      product_id: productId,
      ...props,
    };
    return {
      urlType: UrlType.Product,
      childProps: await ProductContainer.getInitialProps(productProps),
      isGift,
    };
  };

  private static getSitemapWithContentChildProps = async (
    sitemapId,
    contentId,
    lang,
    noShare,
    dispatch,
  ) => {
    return {
      urlType: UrlType.SitemapWithContent,
      childProps: await CMS.getInitialProps({
        sitemapId,
        contentId,
        lang,
        noShare,
        dispatch,
      }),
    };
  };

  private static getFooterContentChildProps = async (sitemapId, props) => {
    return {
      urlType: UrlType.FooterArticle,
      childProps: props,
    };
  };

  private static getSitemapChildProps = async (sitemapId, props) => {
    return {
      urlType: UrlType.Sitemap,
      childProps: await Category.getInitialProps(
        {
          sitemapId,
        },
        props,
      ),
    };
  };

  private static getCategoryChildProps = async () => {
    return {
      urlType: UrlType.NotFound,
      childProps: null,
    };
    // currently, there is no difference between CLASSIC
    // AND CLASSIC_WITH_SUBCATEGORIES
    // const categoryData = await API.loadCategory(
    //   categoryProps.category_id || 0,
    //   {},
    // );

    // if (categoryData.type === 'CLASSIC') {
    //   return {
    //     urlType: UrlType.Category,
    //     childProps: await Category.getInitialProps(categoryProps, props),
    //   };
    // } else {
    //   return {
    //     urlType: UrlType.Category,
    //     childProps: await Category.getInitialProps(categoryProps, props),
    //   };
    // }
  };

  private static getNotFoundChildProps = () => {
    return {
      urlType: UrlType.NotFound,
      childProps: null,
    };
  };

  private static getRedirectChildProps = async urlMapData => {
    return {
      urlType: UrlType.Redirect,
      childProps: await Redirect.getInitialProps({
        urlMapData,
      }),
      urlMapData,
    };
  };

  private static getLoaderView = () => {
    return (
      <LoaderWrapper>
        <HorizontalCenterLoader />
      </LoaderWrapper>
    );
  };

  private static getNotFoundView = () => {
    return <NotFound />;
  };

  private getRedirectView = () => {
    return <Redirect {...this.props.childProps} />;
  };

  private static isPreview = (url: string): boolean => {
    return url.match(/\/preview\/.+/) ? true : false;
  };

  public render() {
    const { isError, urlType, loading } = this.props;

    if (isError) {
      return UrlMapper.getNotFoundView();
    }

    if (loading) {
      return UrlMapper.getLoaderView();
    }

    if (
      urlType === UrlType.Category ||
      urlType === UrlType.CategoryWithSubcategories
    ) {
      return this.getCategoryView();
    } else if (urlType === UrlType.Sitemap) {
      return this.getCMSView();
    } else if (urlType === UrlType.FooterArticle) {
      return this.getFooterArticleView();
    } else if (urlType === UrlType.SitemapWithContent) {
      return this.getCMSView();
    } else if (urlType === UrlType.Product) {
      return this.getProductView();
    } else if (urlType === UrlType.Tag) {
      return this.getTagView();
    } else if (urlType === UrlType.Redirect) {
      return this.getRedirectView();
    }
    return UrlMapper.getNotFoundView();
  }

  private getFooterArticleView = () => {
    return <div>footer arricle</div>;
  };

  private getCategoryView = () => {
    const childProps: CategoryProps = this.props.childProps as CategoryProps;
    return <Category {...childProps} loading={this.props.loading} />;
  };

  private getCMSView = () => {
    const childProps: CmsProps = this.props.childProps as CmsProps;

    return (
      <>
        {this.props.isPreview && (
          <UpperBar>
            {__(
              'Toto je náhľad stránky. Ak je stránka v administrácii označená ako “Zobrazená” je tento náhľad verejne dostupný.',
            )}
          </UpperBar>
        )}
        <CMS {...childProps} />
      </>
    );
  };

  private getTagView = () => {
    const childProps = this.props.childProps;
    return <TagResults {...childProps} />;
  };

  private getProductView = () => {
    const childProps: ProductProps = this.props.childProps as ProductProps;
    return <ProductContainer {...childProps} />;
  };
}

const UpperBar = styled.div`
  width: 100%;
  height: ${rem(40)};
  color: white;
  background: red;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  z-index: 400;
`;

const mapStateToProps = state => {
  return {
    defaultTitle: state.general.title,
    token: state.auth.token,
    lang: state.general.lang,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'UrlMapper' })(UrlMapper),
);
