import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinSelect as Select,
  DsiInvoices as Invoices,
  PaginationSimple,
  Loader as DefaultLoader,
  TextP,
  DsiButton,
} from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';

interface Props {
  orders: any[];
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  downloadFile: any;
  currency: string;
  isDownloading: boolean;
  refetchData: any;
  user: any;
}

function MyInvoices({
  currentId,
  orders,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  downloadFile,
  currency,
  isDownloading,
  refetchData,
  user,
}: Props) {
  const { limit, offset, total } = ordersData;
  // orders && Array.isArray(orders)
  //   ? orders.sort((a, b) => a['@_InvCus_Code'] - b['@_InvCus_Code'])
  //   : [];
  // console.log({ orders });

  const loadAllData = () => {
    refetchData({
      limit: prop(orders, 'pagination.total'),
      fromDate: prop(orders, 'activeFilters.filter_from'),
      toDate: prop(orders, 'activeFilters.filter_to'),
      type: prop(orders, 'activeFilters.type'),
      paid: prop(orders, 'activeFilters.paid'),
    });
  };

  React.useEffect(() => {
    if (isDownloading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isDownloading]);

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {`${__('Faktúry a dobropisy')} (${prop(orders, 'pagination.total') ||
          0})`}
      </StyledTitle>
      {options && options.length > 1 && (
        <>
          <Select
            value={currentId}
            options={options}
            onChange={handleSelectChange}
          />
          <br />
        </>
      )}
      <Invoices
        isFetching={isFetchingOrders}
        orders={prop(orders, 'data')}
        downloadFile={downloadFile}
        currency={currency}
        refetchData={refetchData}
        sumPrice={prop(orders, 'price.sumWithoutVat') || 0}
        isB2B={user && user.b2b}
      />
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <PaginationSimple
          totalItems={prop(orders, 'pagination.total')}
          limit={prop(orders, 'pagination.limit')}
          offset={prop(orders, 'pagination.offset')}
          handleOffsetChange={handleOffsetChange}
        />
      )}
      {prop(orders, 'pagination.total') > prop(orders, 'pagination.limit') && (
        <LoadAllButton onClick={loadAllData}>
          {__('Zobraziť všetky')}
        </LoadAllButton>
      )}
      {isDownloading && (
        <LoaderWrapper>
          <DefaultLoader
            style={{
              position: isDownloading ? 'fixed' : 'initial',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 6,
              background: 'rgba(0, 0, 0, 30%)',
            }}
            circleStyle={{ position: 'fixed', top: '53%' }}
            loading={isDownloading}
            dim={true}
          >
            <TextP
              style={{
                position: 'fixed',
                top: '64%',
                width: '100%',
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: 700,
              }}
            >
              <span
                style={{
                  background: 'white',
                  padding: '0.5rem',
                  borderRadius: '4px',
                }}
              >
                {__('Pracujeme na príprave Vašej faktúry')}
              </span>
            </TextP>
          </DefaultLoader>
        </LoaderWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const LoaderWrapper = styled(FlexCol)``;

export const LoadAllButton = styled(DsiButton)`
  margin: 0 auto;
  margin-top: ${rem(24)};
  max-width: ${rem(450)};
`;

export default MyInvoices;
