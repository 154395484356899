import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import {
  FlexCol,
  FlexRow,
  DsiArticleList as ArticleList,
  SitemapList,
  DsiPagination as Pagination,
} from 'eshop-defaults';
import { connectSsr } from 'ssr-service';
import { loadCMSCategoriesTree, loadCMSArticles } from './actions';
import {
  sitemapSelector,
  allArticlesSelector,
  sitemapIsFetchingSelector,
  articlesIsFetchingSelector,
  sitemapByIdSelector,
} from './selectors';
import API, { ThenArg } from '../../services/API';
import { withRouter, WithRouterProps } from 'react-router';
import { push } from 'react-router-redux';
import { prop } from '../../utilities';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { resolveLangByHostname } from '../../configureTrans';

interface Props {
  dispatch: (action: any) => void;
  sitemap: ThenArg<typeof API.loadSitemapTree>['sitemap_tree'];
  isFetching: boolean;
  allArticles: ThenArg<typeof API.loadArticles>;
  isFetchingArticles: boolean;
  sitemapsById: object;
}

class CMSArticles extends React.Component<Props & WithRouterProps> {
  public static async getInitialProps(parentProps) {
    try {
      const { dispatch, location, route } = parentProps;
      const {
        query: { offset },
      } = location;
      try {
        dispatch(
          setBreadCrumbPath(BreadCrumbType.CMS_ARTICLES, {
            name: prop(route, 'props.name') || __('Blog'),
            path: location.pathname,
          }),
        );
      } catch (e) {}
      const correctLang =
        parentProps.lang || resolveLangByHostname(parentProps.hostname);
      // await dispatch(loadCMSCategoriesTree());
      await dispatch(loadCMSArticles(null, 15, offset, correctLang));
      return;
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public render() {
    return (
      <>
        <Wrapper className="container container--wide">
          <MetaTags
            tags={{ title: (this.props as any).route.props.name || __('Blog') }}
            noindex={true}
            nofollow={true}
          />
          {/*{this.renderCategories()}*/}
          {this.renderArticles()}
        </Wrapper>
      </>
    );
  }

  public renderCategories = () => {
    const { sitemap } = this.props;
    const subid = this.props.location.query.subid;
    const remappedSitemap =
      sitemap &&
      sitemap.map(s => ({
        name: s.name || '',
        id: s.id || '',
      }));

    return (
      <SitemapList
        currentId={(subid && subid.toString()) || -1}
        handleCategoryChange={this.handleCategoryChange}
        sitemaps={remappedSitemap}
      />
    );
  };

  public renderArticles = () => {
    const {
      allArticles,
      isFetchingArticles,
      location,
      sitemapsById,
    } = this.props;
    const articles = prop(allArticles, 'articles');
    const total = prop(allArticles, 'total', 0);
    // const subid = location.query.subid ? location.query.subid.toString() : '-1';
    const offset = prop(location, 'query.offset', 0);

    return (
      <ContentWrapper>
        <ArticlesWrapper>
          <Title>{`${(this.props as any).route.props.name}`}</Title>
          <ArticleList
            sitemapsById={sitemapsById}
            articles={articles}
            isFetching={isFetchingArticles}
          />
          <PaginationDesktopWrapper>
            <Pagination
              query={`${location.pathname}${
                location.search ? location.search : '?'
              }`}
              totalItems={total || 0}
              limit={15}
              offset={offset || 0}
              isMobile={false}
            />
          </PaginationDesktopWrapper>
          <PaginationMobileWrapper>
            <Pagination
              query={`${location.pathname}${
                location.search ? location.search : '?'
              }`}
              totalItems={total || 0}
              limit={15}
              offset={offset || 0}
              isMobile={true}
            />
          </PaginationMobileWrapper>
        </ArticlesWrapper>
      </ContentWrapper>
    );
  };

  public handleOffsetChange = (newOffset: number) => {
    const subid = this.props.location.query.subid;
    const searchQuery = subid
      ? `?subid=${subid}&offset=${newOffset}`
      : `?offset=${newOffset}`;
    this.props.dispatch(
      push({
        pathname: this.props.location.pathname,
        search: searchQuery,
      }),
    );
  };

  public handleCategoryChange = async (newId: number | string) => {
    // const offset = this.props.location.query.offset;
    const { location, dispatch } = this.props;
    const searchQuery = `?subid=${newId}`;
    dispatch(
      push({
        pathname: location.pathname,
        search: searchQuery,
      }),
    );
  };

  public getPaginationQuery = () => {
    const { location } = this.props;
    const {
      query: { subid },
    } = location;

    return subid
      ? `${location.pathname}?subid=${subid}`
      : `${location.pathname}?`;
  };
}

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${({ theme }) => theme.mediab.l925`
    flex-flow: column;
    padding: 0 ${rem(24)} ${rem(32)};
  `}
`;

const ContentWrapper = styled(FlexCol)`
  width: 100%;
  justify-content: flex-end;
  margin-bottom: ${rem(80)};
  ${({ theme }) => theme.mediab.l925`
     justify-content: center;
  `}
`;

const ArticlesWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(1048)};
  margin: 0 auto;

  padding: 0 ${rem(16)};
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: ${rem(56)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0;
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

const PaginationDesktopWrapper = styled.div`
  display: block;

  ${({ theme }) => theme.mediab.m580`
      display: none;
    `}
`;

const PaginationMobileWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediab.m580`
      display: block;
    `}
`;

const mapStateToProps = state => {
  return {
    sitemap: sitemapSelector(state),
    isFetching: sitemapIsFetchingSelector(state),
    allArticles: allArticlesSelector(state),
    isFetchingArticles: articlesIsFetchingSelector(state),
    sitemapsById: sitemapByIdSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CMSArticles' })(withRouter(CMSArticles)),
);
