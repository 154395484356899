import { __r } from 'react-i18n';
import {
  langSelector,
  currencySelector,
  hostnameSelector,
  userSelector,
} from '../App/selectors';
import { saveCartCookie, removeCartCookie } from './helpers';
import { fetchCart } from './cartSlice';
import { resolveDomainByHostname } from '../../configureTrans';

export enum cartRoutes {
  INDEX = 'routes:kosik',
}

export const cartIndexRoute = __r('routes:kosik', '/kosik');

export const CART_ROUTES = {
  1: cartIndexRoute,
};

export const createNewCart = async (
  state,
  API,
  dispatch?: any,
  createLocation?: string,
) => {
  const { id } = await API.createCart(
    { domainId: 4, createLocation: createLocation },
    {
      xAcceptLanguage: langSelector(state),
      xCurrency: currencySelector(state),
    },
  );
  removeCartCookie();
  saveCartCookie(id);
  if (dispatch) {
    dispatch(fetchCart(true));
  }

  return id;
};
