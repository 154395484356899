import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  TextP,
  FlexRowCenterVertical,
  formatPrice,
} from 'eshop-defaults';
import { StyledTitle } from './MyDashboard';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import { LoadAllButton } from './MyInvoices';

interface Props {
  userData: any;
  userFinancialData?: any;
  currency: string;
}

function PaymentsBox({ userData, userFinancialData, currency }: Props) {
  const hasToPaySum = prop(userFinancialData, 'financial.debt');
  const hasToPaySumExpired = prop(userFinancialData, 'financial.debt_expired');
  const creditSum = prop(userData, 'outer_payload.@_ComCus_Credit')
    ? prop(userData, 'outer_payload.@_ComCus_Credit')
    : prop(userData, 'parent.outer_payload.@_ComCus_Credit')
    ? prop(userData, 'parent.outer_payload.@_ComCus_Credit')
    : '';
  const freeCreditSum = prop(userFinancialData, 'financial.free_credit');
  const daysToPay = prop(userData, 'outer_payload.@_ComCus_PayTI')
    ? prop(userData, 'outer_payload.@_ComCus_PayTI')
    : prop(userData, 'parent.outer_payload.@_ComCus_PayTI')
    ? prop(userData, 'parent.outer_payload.@_ComCus_PayTI')
    : '';

  return (
    <PaymentsWrapper>
      {hasToPaySum ||
      hasToPaySum === 0 ||
      hasToPaySumExpired ||
      hasToPaySumExpired === 0 ? (
        <PayableWrapper borderBottom={true}>
          {hasToPaySum || hasToPaySum === 0 ? (
            <PayableRow>
              <Payables>{`${__('Záväzky')}`}</Payables>
              <Payables>
                {formatPrice(hasToPaySum || '0', currency, 2, false, ' ')}
              </Payables>
            </PayableRow>
          ) : null}
          {hasToPaySumExpired || hasToPaySumExpired === 0 ? (
            <PayableRow>
              <StyledPayables color={'red'}>{`${__(
                'Záväzky po splatnosti',
              )}`}</StyledPayables>
              <StyledPayables color={'red'}>
                {formatPrice(
                  hasToPaySumExpired || '0',
                  currency,
                  2,
                  false,
                  ' ',
                )}
              </StyledPayables>
            </PayableRow>
          ) : null}
        </PayableWrapper>
      ) : null}
      <PayableWrapper>
        {creditSum ? (
          <PayableRow>
            <Payables>{`${__('Kredit')}`}</Payables>
            <Payables>
              {formatPrice(creditSum * 1000, currency, 2, false, ' ')}
            </Payables>
          </PayableRow>
        ) : null}
        {freeCreditSum || freeCreditSum === 0 ? (
          <PayableRow>
            {/*<SvgIcon*/}
            {/*    icon={IconType.creditCard}*/}
            {/*    marginRight={12}*/}
            {/*    fill={'#1E398D'}*/}
            {/*/>*/}
            <StyledPayables color={'blue'} marginBottom={16}>{`${__(
              'Voľný kredit',
            )}`}</StyledPayables>
            <StyledPayables color={'blue'} marginBottom={16}>
              {formatPrice(freeCreditSum || '0', currency, 2, false, ' ')}
            </StyledPayables>
          </PayableRow>
        ) : null}
        {daysToPay ? (
          <PayableRow>
            <Payables>{`${__('Splatnosť')}`}</Payables>
            <Payables>{`${daysToPay} ${__('dní')}`}</Payables>
          </PayableRow>
        ) : null}
      </PayableWrapper>
    </PaymentsWrapper>
  );
}

const Payables = styled(TextP)`
  margin-bottom: ${rem(16)};
`;

const StyledPayables = styled(Payables)<{
  color?: string;
  marginBottom?: number;
}>`
  font-weight: 500;
  color: ${({ theme, color }) =>
    color === 'red'
      ? theme.colors.dangerColor
      : color === 'blue'
      ? theme.colors.primary
      : theme.colors.textPrimary};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? rem(marginBottom) : 0};
`;

const PayableRow = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const PayableWrapper = styled.div<{ borderBottom?: boolean }>`
  padding: ${rem(16)} 0;
  border-bottom: ${({ borderBottom, theme }) =>
    borderBottom ? theme.borders.primary : 0};
`;

const SitemapWrapper = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(328)};
  overflow-y: auto;
  padding: ${rem(16)} ${rem(32)};
  margin-bottom: ${rem(2)};
  background-color: ${({ theme }) => theme.colors.categoryFilterBackground};

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(328)};
  `};

  ${({ theme }) => theme.mediab.l925`
    white-space: nowrap;
    flex-flow: row;
    margin:0;
    max-width: initial;
    margin: 0 auto;
    width: 100%;
    margin-right: -${rem(16)};
    padding: 0;
    padding: ${rem(16)};
  `}
`;

const PaymentsWrapper = styled(SitemapWrapper)`
  ${({ theme }) => theme.mediab.l925`
  flex-flow: column;
  background:white;
  margin-left: 0;
  width:100%;
  max-width:initial;
  `}
`;

export default PaymentsBox;
